/* eslint-disable no-mixed-operators */
import { useEffect } from 'react';

export function usePersistedContext(context = {}, key = 'state') {
  const persistedContext = localStorage.getItem(key);
  const parsedContext = persistedContext && JSON.parse(persistedContext) || null;
  return parsedContext && Object.keys(parsedContext).length ? JSON.parse(persistedContext) : context;
}

export function usePersistedReducer([state, dispatch], key = 'state') {
  useEffect(() => localStorage.setItem(key, JSON.stringify(state)), [state]); // eslint-disable-line
  return [state, dispatch];
}
