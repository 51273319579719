import React, { useEffect, useState, useContext } from 'react';
import {
  Box, Typography, FormControlLabel, Switch, LinearProgress, Grid,
} from '@material-ui/core';

import Main from '../Main';
import ListContainer from '../ListContainer';
import { get } from '../../helpers/service';
import ProjectRow from './ProjectRow';
import ProjectSearch from './ProjectSearch';
import { Store } from '../../App';
import { I18nContext } from '../../Translations';

const PER_PAGE_LIMIT = 100;

export const Projects = ({ onItemClick, disableItemSwipe = false }) => {
  const { translate } = useContext(I18nContext);
  const { state } = useContext(Store);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expand, setExpand] = useState(false);
  const searchState = useState({
    currentPage: 0,
    isSubProject: false,
    text: '',
  });
  const [searchValues, setSearchValues] = searchState;

  const fetchProjects = (params) => get('api/projektit', params);

  const fetchSubProjects = async (params) => {
    if (params.search.length < 3) {
      return [];
    }
    const result = await get('api/alaprojektit', params);

    const projectsObject = result.reduce((acc, subProject) => {
      const project = acc[subProject.projectId] || {};
      const subProjects = project.SubProjects || [];
      return {
        ...acc,
        [subProject.projectId]: {
          ...subProject.Project,
          SubProjects: [
            ...subProjects,
            subProject,
          ],
        },
      };
    }, {});

    return Object.values(projectsObject);
  };

  const fetch = async () => {
    setLoading(true);

    const params = {
      limit: PER_PAGE_LIMIT,
      page: searchValues.currentPage,
      search: searchValues.text,
    };

    const fetchResults = searchValues.isSubProject ? fetchSubProjects : fetchProjects;
    const result = await fetchResults(params);

    const isPageSizeFull = result.length >= PER_PAGE_LIMIT;
    let isSamePage = false;

    if (projects.length >= 1 && result.length >= 1) {
      isSamePage = result[result.length - 1].id === projects[projects.length - 1].id;
    }

    const hasMoreItems = isPageSizeFull && !isSamePage;

    setHasNextPage(hasMoreItems);
    setProjects(result);
    setFilteredProjects(result);

    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, [JSON.stringify(searchValues)]);

  const hasPermission = () => state.user.permissions.cr_mod_coveringnotes;

  return (
    <div>
      {!hasPermission() && (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          paddingTop: 32,
        }}
      >
        <Typography
          variant="body2"
          style={{
            fontStyle: 'italic',
          }}
        >
          {translate('Ei käyttöoikeutta')}
        </Typography>
      </Grid>
      )}

      {hasPermission() && (
      <>
        <ProjectSearch searchState={searchState} />
        {!disableItemSwipe && (
        <Box
          px={2}
          pt={2}
          pb={1}
        >
          <FormControlLabel
            control={(
              <Switch
                onChange={(ev) => setExpand(ev.target.checked)}
              />
          )}
            label={translate('Näytä alaprojektit avattuna')}
          />
          <Typography
            component="p"
            variant="caption"
            color="textSecondary"
          >
            {translate('Lisää toimintoja pyyhkäisemällä riviä')}
          </Typography>
        </Box>
        )}
        {loading && <LinearProgress />}
        <ListContainer
          dense
          loading={loading}
          spinnerLabel={translate('Haetaan Projekteja')}
          pagination={{
            currentPage: searchValues.currentPage,
            setCurrentPage: (page) => setSearchValues({ ...searchValues, currentPage: page }),
            hasNextPage,
          }}
        >
          {filteredProjects.map((project) => (
            <ProjectRow
              disableSwipe={disableItemSwipe}
              expanded={expand}
              key={project.id}
              handleRowClick={onItemClick}
              project={project}
            />
          ))}
        </ListContainer>
      </>
      )}
    </div>
  );
};

export default () => {
  const { translate } = useContext(I18nContext);
  return (
    <Main
      title={translate('Projektit')}
    >
      <Projects />
    </Main>
  );
};
