import { useState } from 'react';

const initialState = () => ({
  reg: null,
  newContentAvailable: false,
});

const useSw = () => {
  const [sw, setSw] = useState(initialState());

  const listenToNewContentAvailable = () => {
    window.addEventListener('new-content-available', ({ detail: reg }) => {
      setSw((prevValues) => ({
        ...prevValues,
        reg,
        newContentAvailable: true,
      }));
    });
  };

  const {
    reg,
    newContentAvailable,
  } = sw;

  return {
    reg,
    newContentAvailable,
    listenToNewContentAvailable,
  };
};

export default useSw;
