import React, {
  useRef, useEffect, useContext, useState,
} from 'react';
import {
  IconButton, Typography, Grid, TextField, Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Close as CloseIcon,
  Send as SendIcon,
} from '@material-ui/icons';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar/AppBar';
import DialogContext from '../context/DialogContext';
import ActionFab from './Buttons/ActionFab';
import { I18nContext } from '../Translations';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    height: 'calc(100% - 112px)',

    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 128px)',
    },
  },
}));

export default function ImagePreview({ uploadImage, imageUrl = null }) {
  const imgRef = useRef();
  const { translate } = useContext(I18nContext);
  const [disabledSendButton, setDisabledSendButton] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [inputs, setInputValues] = useState({
    name: translate('Kuva_') + moment().format('DD-MM-YYYY-HH-mm-ss'),
    description: '',
  });

  const classes = useStyles();
  const { hideDialog } = useContext(DialogContext);

  const handleInputChange = (name) => (e) => {
    const { value } = e.target;

    setInputValues((prevState) => (
      {
        ...prevState,
        [name]: value,
      }
    ));
  };

  useEffect(() => {
    // Disable send button if name is empty
    setDisabledSendButton(inputs.name.length <= 0);
  }, [inputs.name]);

  useEffect(() => {
    if (imgRef && imgRef.current && imageUrl) {
      imgRef.current.src = imageUrl;
    }
  }, [imgRef]);

  const onFormSubmit = async (e) => {
    e.preventDefault();

    setUploading(true);

    const res = await fetch(imageUrl);
    const blob = await res.blob();

    await uploadImage({
      name: inputs.name,
      description: inputs.description,
      size: blob.size,
      type: blob.type,
      blob,
    });

    hideDialog();
  };

  return (
    <form
      onSubmit={onFormSubmit}
      style={{
        height: '100%',
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={hideDialog}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            style={{ marginLeft: 12 }}
          >
            {translate('Ota kuva')}
          </Typography>
        </Toolbar>
      </AppBar>

      <div
        className={classes.videoContainer}
      >
        <Box
          p={2}
          mb={6}
          style={{
            overflowY: 'scroll',
            textAlign: 'center',
          }}
        >
          <img
            ref={imgRef}
            alt=""
            style={{
              maxHeight: '60vh',
              maxWidth: '100%',
            }}
          />

          <TextField
            label={translate('Nimi')}
            value={inputs.name || ''}
            onChange={handleInputChange('name')}
            margin="normal"
            variant="outlined"
            fullWidth
            InputProps={{
              labelWidth: 34,
            }}
          />

          <TextField
            label={translate('Kuvaus')}
            value={inputs.description || ''}
            onChange={handleInputChange('description')}
            margin="normal"
            variant="outlined"
            fullWidth
            multiline
            InputProps={{
              labelWidth: 54,
            }}
          />
        </Box>
      </div>

      <AppBar
        position="fixed"
        style={{
          top: 'auto',
          bottom: 0,
          left: 0,
          right: 0,
        }}
        color="default"
      >
        <Toolbar>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              paddingLeft: 24,
              paddingRight: 24,
            }}
          >
            <div />
            <ActionFab
              color="primary"
              style={{
                marginTop: -46,
              }}
              type="submit"
              disabled={disabledSendButton}
              loading={uploading}
            >
              <SendIcon />
            </ActionFab>
          </Grid>
        </Toolbar>
      </AppBar>
    </form>
  );
}
