import SwipeableViews from 'react-swipeable-views';
import { Fab, ListItem, ListItemText } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import {
  Timer as TimerIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Store } from '../../App';
import useTimer from '../../actions/timer';
import TimesheetEntryButton from '../Buttons/TimesheetEntryButton';
import { I18nContext } from '../../Translations';

const SubProjectRow = ({
  subProject,
  handleRowClick,
  disableSwipe = false,
  bgColor = 'white',
  title = `${subProject.projectId} » ${subProject.id}`,
}) => {
  const {
    state,
    dispatch,
  } = useContext(Store);
  const timer = useTimer(dispatch);
  const [index, setIndex] = React.useState(0);
  const snackbar = useSnackbar();
  const { translate } = useContext(I18nContext);

  const validator = (callback) => {
    if (!state.user.permissions.cr_se_projectinput) {
      snackbar.showMessage(translate('Ei käyttöoikeutta'));
      return;
    }
    callback();
  };

  const startTimer = () => {
    timer.start({
      prjId: subProject.projectId,
      subPrjId: subProject.id,
      name: subProject.name,
    });
  };

  useEffect(() => {
    if (state.timer.active) {
      setIndex(0);
    }
  }, [state.timer.active]);

  return (
    <SwipeableViews
      index={index}
      disabled={disableSwipe}
      onChangeIndex={(idx) => setIndex(idx)}
      containerStyle={{ justify: 'center', alignItems: 'center' }}
      style={{ background: '#eee' }}
    >
      <ListItem
        divider
        onClick={handleRowClick}
        style={{ background: bgColor, paddingRight: 0, minHeight: 100 }}
      >
        <ListItemText
          primary={title}
          secondary={subProject.name}
        />
      </ListItem>

      <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '12px 16px 12px 16px' }}>
        <div style={{ marginRight: 24, textAlign: 'center' }}>
          <TimesheetEntryButton
            validator={validator}
            timerProps={{
              prjId: subProject.projectId,
              subPrjId: subProject.id,
              name: subProject.name,
            }}
          />
          <div style={{ marginTop: 10, color: '#333', fontSize: 12 }}>{translate('Palkkatapahtuma')}</div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Fab
            disabled={state.timer.active}
            color="primary"
            onClick={() => validator(startTimer)}
            size="medium"
          >
            <TimerIcon />
          </Fab>
          <div style={{ marginTop: 10, color: '#333', fontSize: 12 }}>{translate('Työlaskuri')}</div>
        </div>
      </div>
    </SwipeableViews>
  );
};

export default SubProjectRow;
