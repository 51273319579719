import React from 'react';
import {
  Box, Typography, IconButton, Menu, MenuItem, ListItem, ListItemText, ListItemSecondaryAction, Collapse, Grid, LinearProgress,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Refresh } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import 'moment/locale/fi';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';

import Main from '../Main';
import DialogContext from '../../context/DialogContext';
import TimesheetEntryDialog from '../Dialogs/TimesheetEntryDialog';
import { Store } from '../../App';
import { get } from '../../helpers/service';
import ActionButton from '../Buttons/ActionButton';
import ListContainer from '../ListContainer';
import TimesheetEntryRow from './TimesheetEntryRow';
import TimesheetSearch from './TimesheetSearch';
import TimesheetRangeControl from './TimesheetRangeControl';
import { useTimesheetFiltersContext, TimesheetFiltersContextProvider } from '../../reducers/timesheetFilters';
import OpenUrlWithTokenLinkMenuItem from '../LinkMenu';
import { getStatusColor } from '../../helpers/timesheetHelpers';
import { I18nContext } from '../../Translations';

moment.locale('fi');
let cache = [];

const TimesheetEntryButton = () => {
  const { state } = React.useContext(Store);
  const timer = state.timer || {};
  const hasTimesheetEntry = Object.keys(timer).length > 0;
  const {
    showDialog,
  } = React.useContext(DialogContext);
  const { translate } = React.useContext(I18nContext);

  return (
    <Box px={1} pt={1} pb={1}>
      <ActionButton
        style={{ marginBottom: 10 }}
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        fullWidth
        href="/salarygrid"
      >
        {translate('Uusi tuntikortti')}
      </ActionButton>
      {hasTimesheetEntry && (
        <ActionButton
          variant="contained"
          color="secondary"
          size="large"
          type="submit"
          fullWidth
          onClick={() => showDialog({ component: <TimesheetEntryDialog /> })}
        >
          {translate('Lisää työlaskurilta')} {state.timer.elapsed}<span style={{ textTransform: 'lowercase' }}>h</span>
        </ActionButton>
      )}
    </Box>
  );
};

const Timesheet = () => {
  let i = 0;
  const [state] = useTimesheetFiltersContext();
  const { values, params, options } = state;
  const { groupBy } = options;
  const [isCached, setIsCached] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [timesheetEntries, setTimesheetEntries] = React.useState([]);
  const { translate } = React.useContext(I18nContext);

  const fetch = async (force = false) => {
    const cacheKey = JSON.stringify(params);
    const { entries } = cache.find((hit) => hit.cacheKey === cacheKey) || {};
    setIsCached(false);
    if (!force && entries) {
      setIsCached(true);
      setTimesheetEntries(entries);
      return;
    }

    setLoading(true);
    try {
      let result = [];
      try {
        result = await get('api/palkkatapahtumat', params);
      } catch (e) {
        // console.log(e);
      }
      const newEntries = result.reduce((acc, row) => {
        if (acc.find((r) => r.id === row.id)) {
          return acc;
        }
        return [...acc, row];
      }, []);
      setTimesheetEntries(newEntries);
      cache.push({
        cacheKey,
        entries: newEntries,
      });
      if (cache.length > 20) {
        cache.shift();
      }
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [JSON.stringify(params)]);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const handleMenuButtonClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const getGroupOptions = ({
    eventDate, Project, CoveringNote,
  }) => {
    if (groupBy === 'day') {
      return {
        key: eventDate,
        title: (<b>{moment(eventDate).format('dd D.M.YYYY')}</b>),
      };
    }
    if (groupBy === 'week') {
      const key = moment(eventDate).format('w/YYYY');
      return {
        key,
        title: (<span><b>{translate('Viikko')}</b> {key}</span>),
      };
    }
    if (groupBy === 'month') {
      const key = moment(eventDate).format('MMMM YYYY');
      return {
        key,
        title: (<b>{key}</b>),
      };
    }
    if (groupBy === 'covnote') {
      const { ecomId } = CoveringNote || { ecomId: '-' };
      return {
        key: ecomId,
        title: (<span><b>{translate('Lähete')}</b> {ecomId || 'Numeroimaton'}</span>),
      };
    }
    if (groupBy === 'project') {
      const { id } = Project || { id: '-' };
      return {
        key: id,
        title: (<span><b>{translate('Projekti')}</b> {id}</span>),
      };
    }
    return {
      key: null,
      title: null,
    };
  };

  const [timesheetGroups, setTimesheetGroups] = React.useState([]);
  React.useEffect(() => {
    const groups = {};
    timesheetEntries.forEach((entry) => {
      const { key, title } = getGroupOptions(entry);
      if (!groups[key]) {
        groups[key] = {
          key,
          title,
          total: 0,
          summary: {},
          entries: [],
        };
      }
      const { id, name } = entry.SysUserSalaryType || { id: 0, name: '-' };
      if (!groups[key].summary[id]) {
        groups[key].summary[id] = {
          id,
          name,
          total: 0,
        };
      }
      groups[key].summary[id].total += parseFloat(entry.hours);
      groups[key].total += parseFloat(entry.hours);
      groups[key].entries = [...groups[key].entries, entry];
    });
    const groupList = Object.values(groups).sort((a, b) => (a.key < b.key ? 1 : 0));
    setTimesheetGroups(groupList);
    // eslint-disable-next-line
  }, [timesheetEntries, groupBy]);
  const [open, setOpen] = React.useState(false);

  return (
    <Main
      title={translate('Palkkatapahtumat')}
      titleButton={(
        <>
          <IconButton
            size="small"
            style={{ color: 'white' }}
            aria-controls="simple-menu"
            onClick={handleMenuButtonClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {menuAnchorEl && (
              <OpenUrlWithTokenLinkMenuItem
                label={translate('Avaa selaimessa')}
                webiurl="palkkatapahtumat"
                handleClick={handleMenuClose}
              />
            )}
            <MenuItem
              component="a"
              onClick={() => {
                cache = [];
                fetch();
                handleMenuClose();
              }}
            >
              {translate('Tyhjennä muisti')} ({cache.length})
            </MenuItem>
          </Menu>
        </>
      )}
    >
      <TimesheetEntryButton />
      <TimesheetSearch />
      <TimesheetRangeControl isCached={isCached} handleRefresh={() => fetch(true)} />
      <Box
        px={2}
        pt={2}
        pb={1}
      >

        <Grid
          container
          justify="space-between"

        >
          <Grid item>
            {['k', 'v'].includes(values.bystate[0]) && (
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
              >
                {translate('Poista rivi pyyhkäisemällä vasemmalle')}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {isCached && (
              <IconButton size="small" edge="end" aria-label="refresh" onClick={() => fetch(true)}>
                <Refresh />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
      {loading && <LinearProgress />}

      <ListContainer
        dense
        loading={loading}
        spinnerLabel={translate('Haetaan palkkatapahtumia')}
        pagination={{
          currentPage: 0,
          setCurrentPage: (page) => page,
          hasNextPage: false,
        }}
      >
        {timesheetGroups.map(({ title, summary, entries }) => (
          // eslint-disable-next-line no-plusplus
          <React.Fragment key={(i++)}>
            <Box
              style={{ backgroundColor: getStatusColor(values.bystate) }}
            >
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={<Typography type="body1" style={{ color: '#333' }}>{title}</Typography>}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="more" onClick={() => setOpen(!open)}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{ paddingLeft: 15, color: '#333', paddingBottom: 10 }}>
                  <Grid container>
                    {Object.values(summary).map(({ id, name, total }) => (
                      <Grid container key={id} style={{ paddingRight: 10 }}>
                        <Grid item xs={9} align="right">
                          <Typography
                            component="p"
                            variant="caption"
                            color="textPrimary"
                          >
                            <b>{id} {name}</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={3} align="right">
                          <Typography
                            component="p"
                            variant="caption"
                            color="textPrimary"
                          >
                            {total.toFixed(2).replace('.', ',')}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Collapse>
            </Box>
            <div>
              {entries.map((entry) => (
                <TimesheetEntryRow
                  key={entry.id}
                  handleRefresh={fetch}
                  handleDeleted={fetch}
                  {...entry}
                />
              ))}
            </div>
          </React.Fragment>
        ))}
      </ListContainer>
    </Main>
  );
};

const TimesheetWrapper = () => {
  const [state] = useTimesheetFiltersContext();
  return (
    <>
      {state.options && <Timesheet />}
    </>
  );
};

export default function TimesheetProvider() {
  return (
    <TimesheetFiltersContextProvider>
      <TimesheetWrapper />
    </TimesheetFiltersContextProvider>
  );
}
