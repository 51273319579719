import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch, OutlinedInput, Paper, InputBase, IconButton, Divider, Collapse, Box, Badge,
} from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import FlareIcon from '@material-ui/icons/Flare';
import { FilterList as FilterListIcon, Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import { get } from '../../helpers/service';
import { Store } from '../../App';
import { I18nContext } from '../../Translations';

let timeout = 0;
const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const CovnoteSearch = ({ fetchCovnotes }) => {
  const classes = useStyles();
  const [openFilters, setOpenFilters] = useState(false);
  const [covnoteStates, setCovnoteStates] = useState([]);
  const { state, dispatch } = useContext(Store);
  const qp = state.covnoteFilters || {};
  const { translate } = useContext(I18nContext);

  const updateFilterParams = (newParams) => {
    const payload = {
      ...qp,
      ...newParams,
    };

    dispatch({
      type: 'UPDATE_COVNOTE_FILTERS',
      payload,
    });

    fetchCovnotes(payload);
  };

  const [values, setValues] = useState({
    textSearch: qp['search[value]'] || '',
    filterStatus: qp['filter[statusid]'] || '',
    sort: `${qp['sort[column]'] || 'covDate'},${qp['sort[dir]'] || 'asc'}`,
    filterIncludeNonCovnotes: qp.includenonwebicovnotes === 1,
    filterOwnOnly: qp['filter[ownonly]'] === 1,
  });

  const updateSearchParams = (name) => (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleFilterStatusChange = async (ev) => {
    const { value } = ev.target;

    updateFilterParams({
      'filter[statusid]': value,
    });

    updateSearchParams('filterStatus')(value);
  };

  const handleFilterSortChange = async (ev) => {
    const { value } = ev.target;
    const [column, dir] = value.split(',');

    updateFilterParams({
      'sort[column]': column,
      'sort[dir]': dir,
    });

    updateSearchParams('sort')(value);
  };

  const handleTextSearch = (value) => {
    updateSearchParams('textSearch')(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      updateFilterParams({
        'search[value]': value,
      });
    }, 350);
  };

  const handleSwitchChange = (name) => (ev) => {
    const {
      value,
      checked,
    } = ev.target;

    updateFilterParams({
      [value]: checked ? 1 : 0,
    });

    updateSearchParams(name)(checked);
  };

  const fetchCovnoteStates = async () => {
    const result = await get('api/lahetteet/tilat');
    result.sort((a, b) => a.sortId - b.sortId);
    setCovnoteStates(result);
  };

  useEffect(() => {
    fetchCovnoteStates();
  }, []);

  const isFiltered = () => qp['filter[statusid]'] || qp.filterOwnOnly === 1;

  return (
    <Box px={1} pt={1} pb={1}>
      <Paper>
        <div className={classes.filterContainer}>
          <InputBase
            value={values.textSearch}
            className={classes.searchInput}
            placeholder={translate('Hae lähetteitä')}
            onChange={(e) => handleTextSearch(e.target.value)}
          />
          <IconButton
            onClick={() => {
              if (qp['search[value]']) {
                updateFilterParams({
                  'search[value]': '',
                });
                updateSearchParams('textSearch')('');
              }
            }}
          >
            {qp['search[value]'] ? (<CloseIcon />) : (<SearchIcon />)}
          </IconButton>
          <Divider
            className={classes.divider}
            orientation="vertical"
          />
          <IconButton color="primary" onClick={() => setOpenFilters(!openFilters)}>
            {isFiltered() && (<Badge color="secondary" badgeContent="" />)}
            <FilterListIcon />
          </IconButton>
        </div>

        <Collapse
          in={openFilters}
          timeout="auto"
          unmountOnExit
        >
          <Divider />

          <Box
            p={2}
          >
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
            >
              <InputLabel
                htmlFor="filter-status"
                shrink
              >
                {translate('Tila')}
              </InputLabel>
              <Select
                value={values.filterStatus}
                onChange={handleFilterStatusChange}
                inputProps={{
                  id: 'filter-status',
                }}
                displayEmpty
                input={(
                  <OutlinedInput
                    notched
                    labelWidth={28}
                  />
                )}
              >
                <MenuItem value="">{translate('Kaikki')}</MenuItem>

                {covnoteStates.map((s) => (
                  <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              style={{
                marginTop: 16,
              }}
            >
              <InputLabel
                htmlFor="sort-list"
              >
                {translate('Järjestä')}
              </InputLabel>
              <Select
                value={values.sort}
                onChange={handleFilterSortChange}
                inputProps={{
                  id: 'sort-list',
                }}
                input={(
                  <OutlinedInput
                    notched
                    labelWidth={60}
                  />
                )}
              >
                <MenuItem value="covDate,asc"><FlareIcon style={{ height: 12 }} /> {`${translate('Lähetepvm ')}↑`}</MenuItem>
                <MenuItem value="covDate,desc"><FlareIcon style={{ height: 12 }} /> {`${translate('Lähetepvm ')}↓`}</MenuItem>
                <MenuItem value="wantedDate,asc"><TimerIcon style={{ height: 12 }} /> {translate('Toimituspvm ↑')}</MenuItem>
                <MenuItem value="ecomId,asc"> {`${translate('Numero ')}↑`}</MenuItem>
                <MenuItem value="ecomId,desc"> {`${translate('Numero ')}↓`}</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              margin="dense"
            >
              <FormControlLabel
                control={(
                  <Switch
                    checked={values.filterIncludeNonCovnotes}
                    onChange={(e) => handleSwitchChange('filterIncludeNonCovnotes')(e)}
                    value="includenonwebicovnotes"
                  />
                )}
                label={translate('Näytä hakulähetteet')}
              />
              <FormControlLabel
                control={(
                  <Switch
                    checked={values.filterOwnOnly}
                    onChange={(e) => handleSwitchChange('filterOwnOnly')(e)}
                    value="filter[ownonly]"
                  />
                )}
                label={translate('Vain omat')}
              />
            </FormControl>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

export default React.memo(CovnoteSearch);
