import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BrowserBarcodeReader } from '@zxing/library';
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  makeStyles, Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { cameraSupport } from '../helpers/camera';
import BottomDrawerContext from '../context/BottomDrawerContext';
import CameraSelectMenuItems from './CameraSelect';
import { isSafari } from '../helpers/isSafari';
import { I18nContext } from '../Translations';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    position: 'fixed',
    width: '100%',
    zIndex: 999,
  },
  dialogContent: {
    padding: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  switch: {
    position: 'absolute',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const EanReader = ({ close, onSuccess }) => {
  const { translate } = useContext(I18nContext);
  const videoRef = useRef();
  const canvasRef = useRef();
  const initialized = useRef(false);
  const classes = useStyles();
  const [animationFrameId, setAnimationFrameId] = useState(null);
  const codeReader = new BrowserBarcodeReader(1000);
  codeReader.timeBetweenDecodingAttempts = 1000;
  const [cameraFailure, setCameraFailure] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    showBottomDrawer,
  } = useContext(BottomDrawerContext);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const getVideoElement = () => videoRef.current;
  const getVideoStream = () => {
    const video = getVideoElement();
    if (!video) return null;
    return video.srcObject;
  };
  const getCanvas = () => canvasRef.current;
  const getCanvasStream = () => getCanvas().captureStream();

  const updateCanvas = () => {
    const videoStream = getVideoStream();
    const canvas = getCanvas();

    if (!videoStream || !canvas) return;
    const {
      width: sWidth,
      height: sHeight,
    } = videoStream
      .getVideoTracks()[0]
      .getSettings();

    const ctx = canvas.getContext('2d');

    const {
      width: canvasWidth,
    } = canvas;

    const video = getVideoElement();
    const {
      clientWidth: dWidth,
    } = video;

    const {
      clientHeight: dHeight,
    } = getCanvas();

    const sX = 0;
    const sY = ((sHeight / 2) - (dHeight / 2));

    const dX = (canvasWidth - dWidth) / 2;
    const dY = 0;

    /**
     * sx - source image top left X
     * sy - source image top left Y
     * sWidth - source image width
     * sHeight - source image height
     *
     * dx - top left X
     * dy - top left Y
     * dWidth - destination width
     * dHeight - destination height
     *
     * void ctx.drawImage(image, dx, dy, dWidth, dHeight);
     * void ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
     */
    ctx.drawImage(video, sX, sY, sWidth, sHeight, dX, dY, dWidth, sHeight);

    requestForAnimation(); // eslint-disable-line
  };

  const requestForAnimation = () => {
    const newAnimationFrameId = window.requestAnimationFrame(updateCanvas);
    setAnimationFrameId(newAnimationFrameId);
  };

  const cancelAnimation = () => {
    window.cancelAnimationFrame(animationFrameId);
  };

  const setCanvas = () => {
    const canvas = getCanvas();

    if (!canvas) return;

    const {
      clientWidth: canvasClientWidth,
      clientHeight: canvasClientHeight,
    } = canvas;

    canvas.width = canvasClientWidth;
    canvas.height = canvasClientHeight;
  };

  const getCameraStream = (constraints) => navigator.mediaDevices.getUserMedia(constraints);

  const setVideoStream = (stream) => {
    videoRef.current.srcObject = stream;
  };

  const resetVideoStream = () => {
    const video = getVideoElement();
    if (!video) return;
    video.srcObject = null;
  };

  const setCameraStream = async (device) => {
    const camerasToTry = [];
    // As a first choice, try to use the exact device id, if given
    if (device && device.deviceId) {
      camerasToTry.push({
        description: `${translate('Viimeksi valittu kamera')} ${device.label}`,
        constraints: { video: { deviceId: { exact: device.deviceId } } },
      });
    }
    // On some devices, none of the cameras is marked as "environment" facing, so this may fail.
    camerasToTry.push({ description: translate('Mikä tahansa takakamera'), constraints: { video: { facingMode: 'environment' } } });
    // As a last resort, try to get any video device
    camerasToTry.push({ description: translate('Mikä tahansa kamera'), constraints: { video: { } } });

    let errors = '';
    let tryCount = 0;
    let stream = null;
    while (!stream && camerasToTry.length) {
      const camera = camerasToTry.shift();
      try {
        tryCount += 1;
        // eslint-disable-next-line no-await-in-loop
        stream = await getCameraStream(camera.constraints);

        setVideoStream(stream);
      } catch ({ name, message }) {
        if (name === 'NotFoundError') {
          errors += ` (${tryCount}) ${camera.description}: ${translate('Kameraa ei löytynyt')}: ${name} ${message}`;
        } else if (name === 'NotAllowedError') {
          errors += ` (${tryCount}) ${camera.description}: ${translate('Kameran käyttö estetty')}: ${name} ${message}`;
        } else {
          errors += ` (${tryCount}) ${camera.description}: ${translate('Kameran alustamisessa tapahtui virhe')}: ${name} ${message}`;
        }
      }
    }

    if (!stream) {
      setCameraFailure(translate('Mikään kamera ei toimi. Yritettiin seuraavia: ') + errors);
    }
  };

  const setVideo = () => {
    const video = getVideoElement();

    if (!video) return;

    video.onplaying = () => {
      updateCanvas();
    };
  };

  const play = () => {
    const video = getVideoElement();
    if (!video) return;
    video.play();
  };

  const stopTracks = (stream) => {
    if (!stream) return;

    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });
  };

  const stopStream = () => {
    stopTracks(getVideoStream());
    resetVideoStream();
  };

  const stop = () => {
    cancelAnimation();
    stopStream();
    codeReader.reset();
  };

  const stopAndClose = () => {
    stop();
    close();
  };

  const startDecoding = () => {
    const stream = !isSafari() ? getCanvasStream() : getVideoStream();

    codeReader.decodeFromStream(stream, undefined, (result) => {
      setIsSuccess(!!result);
      if (!result) return;

      const barcode = result.getText();

      showBottomDrawer({
        onConfirm: () => {
          onSuccess(barcode);
          stop();
        },
        confirmLabel: translate('Hyväksy ') + barcode,
        DrawerProps: {
          ModalProps: {
            BackdropProps: {
              invisible: true,
            },
          },
        },
      });
    });
  };

  const startCanvasStream = async () => {
    const device = { deviceId: localStorage.getItem('eanCameraDeviceId'), label: localStorage.getItem('eanCameraLabel') };
    await setCameraStream(device);

    setVideo();
    setCanvas();
    play();
    startDecoding();
  };

  const startVideoStream = async () => {
    const device = { deviceId: localStorage.getItem('eanCameraDeviceId'), label: localStorage.getItem('eanCameraLabel') };
    await setCameraStream(device);

    play();
    startDecoding();
  };

  const startStream = () => {
    if (!isSafari()) {
      startCanvasStream();
    } else {
      startVideoStream();
    }
  };

  useEffect(() => {
    if ((!isSafari() || getCanvas()) && getVideoElement()) {
      startStream();

      initialized.current = true;

      return stopAndClose;
    }

    return undefined;
  }, [canvasRef, videoRef]);

  const isIOSNative = window.cordova
    && window.cordova.plugins
    && window.cordova.plugins.barcodeScanner
    && window.cordova.plugins.barcodeScanner.scan;

  useEffect(() => {
    /**
     * iOS app defaults to Cordova's barcode scanner
     * since webview does not allow camera usage.
     */
    if (
      isIOSNative
    ) {
      const success = async ({ text: barcode, cancelled }) => {
        if (cancelled) {
          close();
          return;
        }

        await onSuccess(barcode);
      };

      const onFailure = (e) => {
        alert(translate('Viivakoodin luku epäonnistui') + e); // eslint-disable-line
      };

      window.cordova.plugins.barcodeScanner.scan(success, onFailure);
    }
  }, []); // eslint-disable-line

  if (isIOSNative) return <></>;

  let component;

  if (cameraSupport() && !cameraFailure) {
    component = (
      <DialogContent
        className={classes.dialogContent}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={videoRef}
          playsInline
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />

        <div
          style={{
            width: '100%',
            height: '12px',
            zIndex: 10,
            opacity: 0.2,
            backgroundColor: isSuccess ? 'green' : 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              opacity: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </DialogContent>
    );
  } else {
    component = (
      <DialogContent
        className={classes.dialogContent}
      >
        <Typography
          variant="subtitle1"
          style={{
            color: 'white',
            maxWidth: '300px',
            textAlign: 'center',
          }}
        >
          { cameraFailure || translate('Selaimesi ei tue kameratoimintoa') }
        </Typography>
      </DialogContent>
    );
  }

  const handleMenuButtonClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleSetDevice = (device) => {
    stop();
    localStorage.setItem('eanCameraDeviceId', device.deviceId);
    localStorage.setItem('eanCameraLabel', device.label);
    startStream();
  };

  return (
    <div
      className={classes.root}
    >
      <DialogTitle
        className={classes.dialogTitle}
        disableTypography
        style={{ flex: 4 }}
      >
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          <Box>
            <IconButton
              color="inherit"
              style={{
                padding: 4,
                marginRight: 8,
              }}
              onClick={stopAndClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box flexGrow={2}>
            <Typography component="h2" variant="h6">
              {translate('Lue viivakoodi')}
            </Typography>
          </Box>

          <Box>
            <IconButton
              size="small"
              style={{ color: 'white' }}
              aria-controls="simple-menu"
              onClick={handleMenuButtonClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <CameraSelectMenuItems
                onClose={handleMenuClose}
                onSetDevice={handleSetDevice}
                mediaDevicesReady={Boolean(menuAnchorEl)}
              />
            </Menu>
          </Box>
        </Box>
      </DialogTitle>

      { component }

    </div>
  );
};

export default EanReader;
