import { Base64 } from 'js-base64';
import axios from 'axios';
import queryString from 'query-string';

export const AUTH_HEADERS = 'authHeaders';

const createHeaders = (sysCmrId, sysUser, password, webiServer) => {
  const basicAuth = Base64.encode(`${sysUser}:${password}`);

  return {
    'X-EcomWebi-Server': webiServer,
    'X-EcomWebi-SysCustomerId': sysCmrId,
    'X-EcomWebi-ApiSysUser': sysUser,
    Authorization: `Basic ${basicAuth}`,
  };
};
export const getAuthHeaders = () => JSON.parse(localStorage.getItem(AUTH_HEADERS));
export const setAuthHeaders = (headers) => localStorage.setItem(AUTH_HEADERS, JSON.stringify(headers));
const removeAuthHeaders = () => localStorage.removeItem(AUTH_HEADERS);

const req = async (endpoint, params = { method: 'GET' }) => {
  params.headers = { // eslint-disable-line
    ...(getAuthHeaders() || {}),
    ...(params.headers || {}),
  };

  let BASE_URL = localStorage.getItem('BASE_URL');
  if (!BASE_URL) {
    if (process.env.NODE_ENV === 'development') {
      BASE_URL = `${document.location.origin.replace(/:\d+/, '')}:5050/`;
    } else {
      BASE_URL = 'https://europe-west1-ecomwebi.cloudfunctions.net/ecomwebi-pwa-proxy/';
    }
  }
  const url = BASE_URL + endpoint;

  try {
    const response = await axios({
      url,
      ...params,
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error('Jotakin meni pieleen, ota tarvittaessa yhteyttä asiakaspalveluun');
    }
  }
};

export const get = async (endpoint, queryParams = {}) => {
  /*
  const uriParams = Object.entries(queryParams)
    .map((entry) => entry.map(encodeURIComponent).join('='))
    .join('&');
  */
  const uriParams = queryString.stringify(queryParams, { arrayFormat: 'bracket' });
  let e = endpoint;
  if (uriParams) e += `?${uriParams}`;

  return req(e);
};

export const post = async (endpoint, params = {}) => req(endpoint, {
  method: 'POST',
  data: params,
});

export const put = async (endpoint, params = {}) => req(endpoint, {
  method: 'PUT',
  data: params,
});

export const deletee = async (endpoint) => req(endpoint, {
  method: 'DELETE',
});

export const uploadFile = async (url, file) => {
  const headers = {
    'Content-Type': file.type,
  };

  try {
    const response = await axios({
      url,
      method: 'PUT',
      data: file,
      headers,
    });

    return response.data;
  } catch (error) {
    throw new Error('Jotakin meni pieleen, ota tarvittaessa yhteyttä asiakaspalveluun');
  }
};

export const login = async (sysCmrId, sysUser, password, webiServer) => {
  removeAuthHeaders();

  try {
    const headers = createHeaders(sysCmrId, sysUser, password, webiServer);
    const response = await req('api/login', {
      headers,
    });

    setAuthHeaders(headers);

    return response;
  } catch (err) {
    //
  }

  return false;
};
