import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'material-ui-snackbar-provider';
import {
  Icon,
  Typography, IconButton, Toolbar, Tabs, Tab, AppBar, makeStyles,
} from '@material-ui/core';
import {
  Link, Route, Switch, Prompt,
} from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { get, put, post } from '../../helpers/service';
import CovnoteDetails from './CovnoteTabs/CovnoteDetails';
import CovnoteRows from './CovnoteTabs/CovnoteRows';
import CovnoteFiles from './CovnoteTabs/CovnoteFiles';
import CovnoteMisc from './CovnoteTabs/CovnoteMisc';
import Container from '../Container';
import { Store } from '../../App';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  contentContainer: {
    height: '100%',
    paddingTop: '102px',
    paddingBottom: 64,
  },
}));

/**
 * Leaving this kinda dummy component
 * in case it's developed in the future.
 */
const TabPanel = (props) => {
  const {
    loading,
    children,
  } = props;

  const { translate } = useContext(I18nContext);

  return (
    <>
      <Container
        spinnerProps={{
          loading,
          label: translate('Haetaan lähetettä'),
          containerProps: {
            style: {
              marginTop: 48,
            },
          },
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default function Covnote({ match, history, location }) {
  const { state } = useContext(Store);
  const classes = useStyles();
  const snackbar = useSnackbar();
  const paths = ['tiedot', 'rivit', 'tiedostot'];
  const locPathname = location.pathname.endsWith('rivit/uusi') ? 'rivit' : location.pathname;
  const [tabIndex, setTabIndex] = React.useState(paths.findIndex((path) => locPathname.endsWith(path)));
  const [covnote, setCovnote] = useState({});
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);

  const [hasUnsavedChangesInDetails, setHasUnsavedChangesInDetails] = useState(false);

  const handleTabChange = (event, newTabIndex) => {
    if (newTabIndex !== tabIndex) {
      setTabIndex(newTabIndex);
    }
  };

  const handleLeavingDetailsCancelled = () => {
    setTabIndex(0);
  };


  const handleAddRowDialogClose = () => {
    if (match.params.ecomId) {
      history.replace(`/covnotes/${match.params.ecomId}/rivit`);
    } else {
      history.replace(`/covnotes`);
    }
  };

  const fetchCovnote = async () => {
    if (match.params.ecomId !== 'uusi') {
      setLoading(true);

      const result = await get(`api/lahetteet/${match.params.ecomId}`);
      setCovnote(result);

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCovnote();
  }, []); // eslint-disable-line

  const handleSave = async (payload) => {
    const {
      id,
      ecomId,
    } = match.params;

    try {
      if (ecomId === 'uusi') { // Create
        const result = await post('api/lahetteet', payload);

        setCovnote(result);

        const itemId = result.ecomId ? result.ecomId : `;${result.id}`;

        history.replace(`/covnotes/${itemId}/tiedot`);
      } else { // Update
        const result = await put(`api/lahetteet/${ecomId || id}`, payload);

        setCovnote(result);
      }

      snackbar.showMessage(translate('Lähete tallennettu'));
    } catch (e) {
      snackbar.showMessage(translate('Tallennus epäonnistui'));
    }
  };

  const disableFilesTab = () =>
    match.params.ecomId.startsWith(';') // eslint-disable-line
    || match.params.ecomId.startsWith('uusi')
    || !state.user.permissions.cr_mod_files;

  const referrer = location.state ? location.state.referrer : '/covnotes';

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
      >
        <Toolbar
          variant="dense"
          style={{ paddingTop: 6 }}
          className={classes.toolbar}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => history.push(referrer)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" style={{ marginLeft: 12 }}>
            {translate('Lähete')}
          </Typography>
        </Toolbar>

        <Route
          path="/"
          render={() => (
            <>
              <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
              >
                <Tab
                  label={translate('Tiedot')}
                  component={Link}
                  to={{
                    pathname: 'tiedot',
                    state: {
                      referrer,
                    },
                  }}
                />
                <Tab
                  label={translate('Rivit')}
                  component={Link}
                  to={{
                    pathname: 'rivit',
                    state: {
                      referrer,
                    },
                  }}
                  disabled={(match.params.ecomId === 'uusi')}
                />
                <Tab
                  label={translate('Tiedostot')}
                  component={Link}
                  to={{
                    pathname: 'tiedostot',
                    state: {
                      referrer,
                    },
                  }}
                  disabled={disableFilesTab()}
                />
                <Tab
                  label={translate('Muut')}
                  component={Link}
                  to={{
                    pathname: 'muut',
                    state: {
                      referrer,
                    },
                  }}
                  disabled={(match.params.ecomId === 'uusi')}
                />
              </Tabs>
            </>
          )}
        />
      </AppBar>

      <div
        className={classes.contentContainer}
      >
        <Switch>
          <Route
            path={`/covnotes/${match.params.ecomId}/tiedot`}
            render={() => (
              <TabPanel value={tabIndex} index={0} loading={loading}>
                <CovnoteDetails
                  covnote={covnote}
                  onSave={handleSave}
                  onSetHasUnsavedChanges={setHasUnsavedChangesInDetails}
                  onLeavingCancelled={handleLeavingDetailsCancelled}
                />
              </TabPanel>
            )}
          />
          <Route
            path={`/covnotes/${match.params.ecomId}/rivit`}
            render={() => (
              <TabPanel value={tabIndex} index={1} loading={loading}>
                <CovnoteRows
                  covnote={covnote}
                  openAddDialog={covnote.id && location.pathname.endsWith('uusi')}
                  onAddDialogClose={handleAddRowDialogClose}
                />
              </TabPanel>
            )}
          />
          <Route
            path={`/covnotes/${match.params.ecomId}/tiedostot`}
            render={() => (
              <TabPanel value={tabIndex} index={2} loading={loading}>
                <CovnoteFiles covnote={covnote} />
              </TabPanel>
            )}
          />
          <Route
            path={`/covnotes/${match.params.ecomId}/muut`}
            render={() => (
              <TabPanel value={tabIndex} index={3} loading={loading}>
                <CovnoteMisc covnote={covnote} />
              </TabPanel>
            )}
          />
        </Switch>
      </div>
    </>
  );
}
