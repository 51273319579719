import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React from 'react';
import { Projects } from './Projects/Projects';
import Main from './Main';

const SalaryGridAddEventProject = ({
  projectEntries, setProjectEntries, handleCloseDialog, salaryTypes,
}) => (
  <div style={{
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
  }}
  >
    <Main
      title="Valitse projekti"
      titleButton={(
        <>
          <IconButton
            size="small"
            style={{ color: 'white' }}
            aria-controls="simple-menu"
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </>
        )}
    >
      <Projects
        disableItemSwipe
        onItemClick={(props) => {
          const { project, subProject } = props;
          const key = project ? project.id : `${subProject.projectId}:${subProject.id}`;
          if (projectEntries.find((entry) => entry.key === key)) {
            // eslint-disable-next-line no-alert
            return alert('Projekti on jo valittu tuntikortille');
          }

          let name;

          if (project) {
            name = ` ${project.id} ${project.name}`;
            setProjectEntries([
              ...projectEntries, {
                name,
                prjId: project.id,
                key,
                salaryEvents: salaryTypes.reduce((acc, salaryType) => ({ ...acc, [salaryType.salaryTypeId]: null }), {}),
              },
            ]);
            return handleCloseDialog();
          }
          name = ` ${subProject.projectId} ${props.prjName} » ${subProject.id} ${subProject.name}`;
          setProjectEntries([
            ...projectEntries, {
              name,
              key,
              prjId: subProject.projectId,
              subPrjId: subProject.id,
              salaryEvents: salaryTypes.reduce((acc, salaryType) => ({ ...acc, [salaryType.salaryTypeId]: null }), {}),
            },
          ]);
          return handleCloseDialog();
        }}
      />
    </Main>
  </div>
);
export default SalaryGridAddEventProject;
