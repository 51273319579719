import React from 'react';
import moment from 'moment';
import { usePersistedContext, usePersistedReducer } from '../usePersist';

const timesheetFiltersReducer = (state = {}, { type, payload }) => {
  let newState = state;
  if (type === 'SET_TIMESHEET_VALUES') {
    const newParams = Object.entries(payload)
      .reduce((acc, [k, v]) => ({
        ...acc,
        [`filter[${k}]`]: v,
      }), {});

    newState = {
      ...state,
      values: {
        ...state.values,
        ...payload,
      },
      params: {
        ...state.params,
        ...newParams,
      },
    };
  }
  if (type === 'SET_TIMESHEET_OPTIONS') {
    newState = {
      ...state,
      options: {
        ...state.options,
        ...payload,
      },
    };
  }
  if (type === 'INIT_TIMESHEET') {
    newState = {
      ...state,
      ...payload,
    };
  }
  return newState;
};

export const initialState = {
  values: {
    bystate: ['k'],
    startdate: moment().format('YYYY-MM-DD'),
    enddate: moment().format('YYYY-MM-DD'),
  },
  params: {
    'filter[bystate]': ['k'],
    'filter[ownonly]': true,
    'filter[startdate]': moment().format('YYYY-MM-DD'),
    'filter[enddate]': moment().format('YYYY-MM-DD'),
  },
  options: {
    range: 'days',
    groupBy: 'day',
  },
};

const TimesheetFiltersContext = React.createContext(initialState);
export const useTimesheetFiltersContext = () => React.useContext(TimesheetFiltersContext);

export const TimesheetFiltersContextProvider = ({ children }) => {
  const store = usePersistedContext(React.useContext(TimesheetFiltersContext), 'tsf');
  const [state, dispatch] = usePersistedReducer(React.useReducer(timesheetFiltersReducer, store), 'tsf');
  return (
    <TimesheetFiltersContext.Provider value={[state, dispatch]}>
      {children}
    </TimesheetFiltersContext.Provider>
  );
};
