import React from 'react';
import {
  Box, Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    background: theme.palette.primary.main,
    position: 'fixed',
    width: '100%',
    zIndex: 999,
  },
  headerColor: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  spacer: {
    paddingTop: 60,
  },
}));

const Main = ({
  title,
  titleButton,
  children,
}) => {
  const classes = useStyles();

  return (
    <>
      <Paper
        square
        elevation={2}
        className={classes.headerContainer}
      >
        <Box px={3} pt={2} pb={1}>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{
              position: 'relative',
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              className={classes.headerColor}
              onClick={() => window.scrollTo(0, 0)}
            >
              { title }
            </Typography>

            { titleButton }
          </Grid>
        </Box>
      </Paper>

      <div className={classes.spacer} />

      { children }
    </>
  );
};

export default Main;
