import 'webrtc-adapter'; // Polyfills WebRTC related stuff if needed

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nContextProvider } from './Translations';

let swRegistered = false;

const registerSw = () => {
  if (swRegistered) return; // Register only once

  serviceWorker.register({
    onUpdate: (reg) => {
      const event = new CustomEvent('new-content-available', {
        detail: reg,
      });

      window.dispatchEvent(event);
    },
  });

  swRegistered = true;
};

ReactDOM.render(<I18nContextProvider><App /></I18nContextProvider>, document.getElementById('root'), registerSw);

if (module.hot) {
  module.hot.accept();
}
