import React from 'react';
import { CircularProgress, Fab, withStyles } from '@material-ui/core';

const styles = {
  rootContainer: {
    display: 'flex',
    position: 'relative',
  },
  spinnerContainer: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {},
  hidden: {
    opacity: 0,
  },
};

const SpinnerAdornment = withStyles(styles)((props) => (
  <div
    className={props.classes.spinnerContainer}
  >
    <CircularProgress
      className={props.classes.spinner}
      size={20}
    />
  </div>
));

const ActionFab = withStyles(styles)((props) => {
  const {
    children,
    loading,
    disabled,
    classes,
    ...rest
  } = props;

  return (
    <div
      className={classes.rootContainer}
    >
      <Fab
        disabled={loading || disabled}
        {...rest}
      >
        { !loading && children }
        { loading && <SpinnerAdornment {...rest} /> }
      </Fab>
    </div>
  );
});

export default ActionFab;
