import React, { useEffect, useContext, useState } from 'react';
import {
  Box, ListItem, ListItemText, Grid, Typography, Fab, makeStyles, Button, CircularProgress,
  IconButton, Divider, Collapse, FormControl, InputLabel, Select, MenuItem, OutlinedInput,
  Paper,
} from '@material-ui/core';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SwipeableViews from 'react-swipeable-views';
import {
  FilterList as FilterListIcon, DeleteOutline as DeleteOutlineIcon, Add as AddIcon,
  Search as SearchIcon, Close as CloseIcon,
} from '@material-ui/icons';
import DialogContext from '../../../context/DialogContext';
import CovnoteRowDialog from '../../Dialogs/CovnoteRowDialog';
import CovnoteRow from './CovnoteRow';
import {
  get, post, put, deletee,
} from '../../../helpers/service';
import Container from '../../Container';
import { I18nContext } from '../../../Translations';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchInput: {
    flex: 1,
  },
  destroyContainer: {
    background: theme.palette.error.main,
    height: 80,
  },
  destroyButton: {
    color: theme.palette.common.white,
  },
}));

// This is an empty array [], but with an added object property 'loaded': false
const emptyNonLoadedArray = Object.defineProperty([], 'loaded', {enumerable: false, configurable: false, writable: true, value: false});

export default function CovnoteRows({ covnote, openAddDialog, onAddDialogClose }) {
  const covnoteId = covnote.ecomId || covnote.id ? `;${covnote.id}` : null;
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [openFilters, setOpenFilters] = useState(false);
  const [covnoteRows, setCovnoteRows] = useState(emptyNonLoadedArray);

  const [storedSortColumn, storedSortDir] = (localStorage.getItem('covnoteRowSortParams') || 'id,asc').split(',');
  const knownSortColumns = ['id'];
  const [[sortColumn, sortDir], setSortParams] = useState([storedSortColumn in knownSortColumns ? storedSortColumn : 'id', storedSortDir]);

  const [searchText, setSearchText] = useState('');
  const [prdLineFilter, setPrdLineFilter] = useState([]);

  const [prdLines, setPrdLines] = useState(emptyNonLoadedArray);
  const [loading, setLoading] = useState(false);
  const [destroying, setDestroying] = useState({});
  const {
    dialog,
    showDialog,
    hideDialog,
  } = useContext(DialogContext);

  const hasPrdLineFilter = () => !!(prdLineFilter && (prdLineFilter.length) && (prdLineFilter.length > 0));

  const hasFilters = () => !!searchText || hasPrdLineFilter();

  const { translate } = useContext(I18nContext);

  const fetchCovnoteRows = async () => {
    const response = await get(`api/lahetteet/${covnoteId}/rivit`);
    let res = Object.values(response);
    res.loaded = true;
    setCovnoteRows(res);
  };

  const fetchPrdLines = async () => {
    let res = await get('api/tuotteet/tuotealat');
    res.loaded = true;
    setPrdLines(res);
  };

  useEffect(() => {
    if (covnoteId) {
      setLoading(true);

      Promise.all([
        fetchCovnoteRows(),
        fetchPrdLines(),
      ]).then(() => {
        setLoading(false);
      });
    }
  }, [covnoteId]);

  const handleDialogClose = () => {
    if (onAddDialogClose) {
      onAddDialogClose();
    }
  };

  const handleSave = async (covnoteRow, results) => {
    try {
      if (covnoteRow.id) { // Update
        const res = await put(`api/lahetteet/${covnoteId}/rivit/${covnoteRow.id}`, covnoteRow);

        const foundIndex = covnoteRows.findIndex((d) => res.id === d.id);

        if (foundIndex < 0) return; // Not found

        setCovnoteRows((rows) => rows.map((row, index) => (foundIndex === index ? res : row)));
        snackbar.showMessage(translate('Rivi tallennettu'));
      } else { // Create
        const res = await post(`api/lahetteet/${covnoteId}/rivit`, covnoteRow);
        setCovnoteRows((rows) => [...rows, res]);
        if (results) {
          // eslint-disable-next-line no-param-reassign
          results.addedCovnoteRow = res;
        }
        snackbar.showMessage(translate('Rivi lisätty'));
      }
    } catch (e) {
      snackbar.showMessage(translate('Tallennus epäonnistui'));
    }

    // hideDialog();
  };

  const handleDestroy = async (covnoteRow) => {
    setDestroying(covnoteRow);

    try {
      await deletee(`api/lahetteet/${covnoteId}/rivit/${covnoteRow.id}`);

      setCovnoteRows((rows) => rows.filter((row) => row.id !== covnoteRow.id));

      // hideDialog();

      snackbar.showMessage(translate('Rivi poistettu'));
    } catch (e) {
      snackbar.showMessage(translate('Rivin poistaminen epäonnistui'));
    }

    setDestroying({});
  };

  const handleFilterSortChange = async (ev) => {
    const { value } = ev.target;
    const [column, dir] = value.split(',');
    localStorage.setItem('covnoteRowSortParams', value);
    setSortParams([column, dir]);
  };

  const handleTextSearch = (value) => {
    setSearchText(value);
  };

  const totalPrice = covnoteRows.reduce((acc, { amount, unitPrice }) => acc + (amount * unitPrice), 0);

  const getCovnoteRowDialogTitleText = () => `${translate('Läh.')} ${covnote ? covnote.ecomId : ''}`;

  useEffect(() => {
    if (covnoteId && openAddDialog && !dialog.show && !loading && covnoteRows.loaded && prdLines.loaded) {
      showDialog({
        component: (
          <CovnoteRowDialog
            onSave={handleSave}
            prdLines={prdLines}
            onAddedRowDeleted={handleDestroy}
            titleText={getCovnoteRowDialogTitleText()}
          />
        ),
      }, handleDialogClose);
    }
  });

  return (
    <Container
      spinnerProps={{
        loading,
        label: translate('Haetaan rivejä'),
        style: {
          marginTop: 48,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        px={2}
        pt={2}
        pb={1}
      >
        <Box flexGrow={1}>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            {translate('Poista pyyhkäisemällä riviä vasemmalle ja vahvista')}
          </Typography>
        </Box>

        <Box px={1} display="flex">
          <Divider className={classes.divider} orientation="vertical" />
        </Box>

        <IconButton
          color="primary"
          size="small"
          style={{ background: hasFilters() ? '#f5f555' : undefined }}
          onClick={() => setOpenFilters(!openFilters)}
        >
          <FilterListIcon />
        </IconButton>

      </Box>

      <Box px={1}>
        <Collapse
          in={openFilters}
          timeout="auto"
          unmountOnExit
        >
          <Divider />

          <Paper>
            <Box px={2} pb={1} pt={1}>
              <FormControl
                variant="outlined"
                fullWidth
                margin="dense"
                style={{
                  marginTop: 2,
                }}
              >
                <InputLabel
                  htmlFor="sort-list"
                  shrink
                >
                  {translate('Järjestä')}
                </InputLabel>
                <Select
                  onChange={handleFilterSortChange}
                  value={[sortColumn, sortDir].join(',')}
                  inputProps={{
                    id: 'sort-list',
                  }}
                  input={(
                    <OutlinedInput
                      notched
                      labelWidth={60}
                    />
                  )}
                >
                  <MenuItem value="id,asc"> {`${translate('Rivinumero ')}↓`}</MenuItem>
                  <MenuItem value="id,desc"> {`${translate('Rivinumero ')}↑`}</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box px={2} display="flex">
              <Box flexGrow={1}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  fontSize="small"
                  fullWidth
                >
                  <InputLabel
                    id="label-filter-prd-line"
                    htmlFor="filter-prd-line"
                    shrink
                  >
                    {translate('Tuoteala (suodatus)')}
                  </InputLabel>
                  <Select
                    displayEmpty
                    renderValue={(value) => (!value ? '' : value)}
                    value={prdLineFilter.join(', ')}
                    onChange={(e) => setPrdLineFilter([e.target.value.toUpperCase()])}
                    inputProps={{
                      id: 'filter-prd-line',
                    }}
                    input={(
                      <OutlinedInput
                        notched
                        label="label-filter-prd-line"
                        inputProps={{
                          style: { fontSize: 'small' },
                        }}
                      />
                    )}
                  >
                    { prdLines.map((prdLine) => (
                      <MenuItem
                        dense
                        key={prdLine.id}
                        value={prdLine.id}
                      >
                        { prdLine.name }
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    if (prdLineFilter.length) {
                      setPrdLineFilter([]);
                    }
                  }}
                >
                  { !!hasPrdLineFilter() && <CloseIcon /> }
                </IconButton>
              </Box>
            </Box>

            <Box px={2} pt={1} pb={1} display="flex">
              <Box flexGrow={1}>
                <FormControl className={classes.filterContainer} variant="outlined" margin="dense" px={12} pb={1}>
                  <InputLabel
                    htmlFor="filter-text"
                    shrink
                    id="filter-text-label"
                  >
                    {translate('Tuotenimi (suodatus)')}
                  </InputLabel>

                  <OutlinedInput
                    notched
                    value={searchText}
                    labelWidth={160}
                    id="filter-text"
                    autoComplete="off"
                    placeholder={translate('Tuotenimi...')}
                    onChange={(e) => handleTextSearch(e.target.value)}
                  />
                </FormControl>
              </Box>

              <Box>
                <IconButton
                  onClick={() => {
                    if (searchText) {
                      setSearchText('');
                    }
                  }}
                >
                  { searchText && <CloseIcon /> }
                </IconButton>
              </Box>
            </Box>
          </Paper>
        </Collapse>
      </Box>

      {totalPrice > 0 && (
        <Box
          style={{ backgroundColor: '#fcf7db' }}
        >
          <ListItem>
            <ListItemText
              disableTypography
              primary={(
                <Typography type="body1" style={{ color: '#333' }}>
                  {translate('Läheterivit yhteensä')} {totalPrice.toFixed(2)} &euro;
                </Typography>
              )}
            />
          </ListItem>
        </Box>
      )}

      <Box
        style={{
          height: '100%',
        }}
      >
        {covnoteRows.sort((a, b) => ((sortDir === 'desc') ? (b[sortColumn] - a[sortColumn]) : (a[sortColumn] - b[sortColumn]))).map((row, index) => (
          ((!searchText) || ((row.name) && row.name.toLowerCase().includes(searchText.toLowerCase())))
          && ((!prdLineFilter.length) || ((row.prdLine) && prdLineFilter.includes(row.prdLine.toUpperCase())))
          && ( // cannot use filter(), because the code below uses covnoteRows[index]
            <CovnoteRow
              rowId={row.id}
              covnoteRow={covnoteRows[index]}
              prdLines={prdLines}
              destroying={destroying}
              onSave={handleSave}
              onDestroy={handleDestroy}
              onClick={() => showDialog({
                component: (
                  <CovnoteRowDialog
                    onSave={handleSave}
                    covnoteRow={covnoteRows[index]}
                    prdLines={prdLines}
                    titleText={getCovnoteRowDialogTitleText()}
                  />
                ),
              })}
            />
          )))}
        <div style={{ height: 100 }} />
        <Fab
          color="primary"
          className={classes.fab}
          onClick={() => showDialog({
            component: (
              <CovnoteRowDialog
                onSave={handleSave}
                prdLines={prdLines}
                onAddedRowDeleted={handleDestroy}
                titleText={getCovnoteRowDialogTitleText()}
              />
            ),
          })}
        >
          <AddIcon />
        </Fab>
      </Box>
    </Container>
  );
}
