import React from 'react';
import Spinner from './Spinner';

const Container = ({ spinnerProps, ...rest }) => {
  const {
    loading,
  } = spinnerProps;

  const {
    children,
  } = rest;

  return (
    <>
      <Spinner
        {...spinnerProps}
      />

      <div
        style={{
          display: loading ? 'none' : 'block',
          height: '100%',
        }}
      >
        { children }
      </div>
    </>
  );
};

export default Container;
