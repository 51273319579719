import React, { useContext, useEffect, useState } from 'react';
import {
  DialogContent, makeStyles,
  DialogTitle, TextField, Typography, Box, IconButton, Grid,
  List, ListItem, ListItemText, Button, ButtonGroup,
} from '@material-ui/core';
import { useSnackbar } from 'material-ui-snackbar-provider';
import moment from 'moment';
import { Close as CloseIcon, ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { Store } from '../../App';
import SalaryTypesList from '../SalaryTypesList';
import { post, put } from '../../helpers/service';
import DialogContext from '../../context/DialogContext';
import ActionButton from '../Buttons/ActionButton';
import useTimer from '../../actions/timer';
import TimesheetEntryRow from '../Timesheet/TimesheetEntryRow';
import { getTimesheetEntryStatusDescription } from '../../helpers/timesheetHelpers';
import NumTextField from '../NumTextField';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));


const TimesheetEntryDialog = ({ timerProps = {} }) => {
  const { state, dispatch } = useContext(Store);
  const timer = useTimer(dispatch);
  const [addedTimesheetEntries, setAddedTimesheetEntries] = React.useState([]);
  const { translate } = useContext(I18nContext);

  const getHours = (elapsed = '') => {
    const [h, m] = (`${elapsed}`).split(':').map(Number);
    const time = h + (m / 60);
    return time.toFixed(2);
  };

  const hasTimerProps = () => Object.keys(timerProps).length > 0;

  const getInitialState = () => {
    const initialState = {
      elapsed: '',
      covEcomId: null,
      covId: null,
      prjId: null,
      salaryTypeId: null,
      subPrjId: null,
      memo: '',
      publicMemo: '',
      eventDate: moment().format('YYYY-MM-DD'),
      eventStart: '',
      eventEndDate: moment().format('YYYY-MM-DD'),
      eventEnd: '',
      isReady: true,
      hours: getHours(),
    };

    if (hasTimerProps()) { // Read from props
      return {
        ...initialState,
        ...timerProps,
      };
    }

    // Read from timer that is set in localStorage
    return {
      ...initialState,
      ...state.timer,
    };
  };

  const [timeEntry, setTimeEntry] = useState(getInitialState());
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [showSaveDraftButton, setShowSaveDraftButton] = useState(false);
  const isProjectEntry = () => !!timeEntry.prjId;
  const isSubProjectEntry = () => !!timeEntry.subPrjId;
  const isCovnoteEntry = () => !!timeEntry.covEcomId;

  const {
    hideDialog,
  } = useContext(DialogContext);

  const timeElapsed = () => {
    const startDateTime = `${timeEntry.eventDate}T${timeEntry.eventStart}`;
    const endDateTime = `${timeEntry.eventEndDate}T${timeEntry.eventEnd}`;
    const startTime = moment(startDateTime).format('x');
    const endTime = moment(endDateTime).format('x');
    if (endTime < startTime) {
      return '0:00';
    }
    const elapsedInMinutes = ~~((endTime - startTime) / (60 * 1000));
    const h = ~~(elapsedInMinutes / 60);
    const m = elapsedInMinutes % 60;
    const mm = String(m).padStart(2, '0');
    const elapsed = `${h}:${mm}`;
    return elapsed;
  };

  useEffect(() => {
    if (!hasTimerProps()) {
      timer.stop();
    }
  }, []);

  useEffect(() => {
    if (!hasTimerProps()) {
      const {
        started,
        finished,
        elapsed,
      } = state.timer;

      setTimeEntry((prevTimeEntry) => ({
        ...prevTimeEntry,
        eventDate: moment(started).format('YYYY-MM-DD'),
        eventStart: moment(started).format('HH:mm'),
        eventEndDate: moment(finished).format('YYYY-MM-DD'),
        eventEnd: moment(finished).format('HH:mm'),
        hours: getHours(elapsed),
      }));
    }
  }, [state.timer.started, state.timer.finished]);

  const confirmTimerReset = () => {
    // eslint-disable-next-line
    if (!hasTimerProps() && confirm(translate('Haluatko sammuttaa laskurin ja nollata kertyneen työajan'))) {
      timer.reset();
      snackbar.showMessage(translate('Työlaskuri sammutettu ja kertynyt työaika nollattu'));
    }
  };

  const onCancel = (ev) => {
    ev.preventDefault();
    confirmTimerReset();
    hideDialog();
  };

  useEffect(() => {
    const total = state.salaryTypes.default.length + state.salaryTypes.normal.length;
    if (total === 0) {
      // eslint-disable-next-line
      alert(translate('Palkkatapahtumaa ei voi lisätä, koska henkilökohtaisia palkkalajeja ei ole siirretty Ecom Webiin'));
      confirmTimerReset();
      hideDialog();
    }
  }, []);

  const saveTimeEntry = async (changeTimeEntryProps) => {
    setSaving(true);

    const newTimeEntry = { ...timeEntry, ...changeTimeEntryProps };

    try {
      if (newTimeEntry.id) {
        await put(`api/palkkatapahtumat/${newTimeEntry.id}`, newTimeEntry);
        setTimeEntry(newTimeEntry);
      } else {
        const addedEntry = await post('api/palkkatapahtumat', newTimeEntry);
        setAddedTimesheetEntries([
          ...addedTimesheetEntries,
          addedEntry,
        ]);
      }

      if (!hasTimerProps()) {
        timer.reset();
      }

      if (newTimeEntry.isReady) {
        snackbar.showMessage(translate('Palkkatapahtuma tallennettu ja merkitty valmiiksi hyväksyntään'));
      } else {
        snackbar.showMessage(translate('Palkkatapahtuma tallennettu keskeneräisenä'));
      }
    } catch (err) {
      if (err.error) {
        snackbar.showMessage(err.error);
      } else if (err.errors.length > 0 && err.errors[0].message) {
        snackbar.showMessage(err.errors[0].message);
      } else {
        snackbar.showMessage(translate('Jotakin meni vikaan'));
      }
    }

    setSaving(false);
  };

  const saveTimeEntryAsReady = async (e) => {
    e.preventDefault();
    saveTimeEntry({ isReady: 1 });
  };

  const saveTimeEntryAsDraft = async (e) => {
    e.preventDefault();
    saveTimeEntry({ isReady: 0 });
  };


  const handleOnChange = (name, isCheckbox = false) => (e) => {
    let { value } = e.target;

    if (isCheckbox) {
      value = e.target.checked ? 1 : 0;
    }

    setTimeEntry((prevTimeEntry) => ({
      ...prevTimeEntry,
      [name]: value,
    }));
  };

  const getCaption = () => {
    if (isSubProjectEntry()) {
      return {
        label: translate('Projektinumero / Alaprojektinumero'),
        id: `${timeEntry.prjId} / ${timeEntry.subPrjId} ${timeEntry.name}`,
      };
    }

    if (isProjectEntry()) {
      return {
        label: translate('Projektinumero'),
        id: `${timeEntry.prjId} ${timeEntry.name}`,
      };
    }

    if (isCovnoteEntry()) {
      return {
        label: translate('Lähetenumero'),
        id: `${timeEntry.covEcomId} ${timeEntry.name || ''}`,
      };
    }

    return {
      label: translate('Numeroimaton'),
      id: null,
    };
  };

  const handleError = ({ msg }) => {
    snackbar.showMessage(msg);
    setActionButtonDisabled(true);
  };

  // eslint-disable-next-line no-unused-vars
  const handleEntryDeleted = (force, id) => setAddedTimesheetEntries(addedTimesheetEntries.filter((entry) => entry.id !== id));

  return (
    <form>
      <DialogTitle
        className={classes.dialogTitle}
      >
        <IconButton
          color="inherit"
          style={{
            padding: 4,
            marginRight: 8,
          }}
          onClick={hideDialog}
        >
          <CloseIcon />
        </IconButton>

        {translate('Palkkatapahtuma')}
      </DialogTitle>

      <DialogContent
        style={{
          padding: 16,
        }}
      >

        <div>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Typography
                component="h2"
                variant="caption"
                style={{
                  color: '#757575',
                  fontweight: 'bold',
                }}
              >
                {getCaption().label}
              </Typography>

              <Typography component="p" variant="body1">
                {getCaption().id}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                component="h2"
                variant="caption"
                style={{
                  color: '#757575',
                  fontweight: 'bold',
                }}
              >
                {translate('Tila')}
              </Typography>
              {getTimesheetEntryStatusDescription(timeEntry.id, timeEntry.isReady, timeEntry.isApproved)}
            </Grid>
          </Grid>
        </div>

        <SalaryTypesList
          salaryTypeId={timeEntry.salaryTypeId}
          handleChange={handleOnChange('salaryTypeId')}
          onError={handleError}
        />

        <Box mt={2} mb={1}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={translate('Aloituspäivä')}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={timeEntry.eventDate || ''}
                onChange={handleOnChange('eventDate')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={translate('Aloitusaika')}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={timeEntry.eventStart ? timeEntry.eventStart.padStart(5, 0) : ''}
                onChange={handleOnChange('eventStart')}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} mb={1}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={translate('Lopetuspäivä')}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={timeEntry.eventEndDate || ''}
                onChange={handleOnChange('eventEndDate')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={translate('Lopetusaika')}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={timeEntry.eventEnd ? timeEntry.eventEnd.padStart(5, 0) : ''}
                onChange={handleOnChange('eventEnd')}
              />
            </Grid>
          </Grid>
        </Box>

        {timeElapsed() !== '0:00' && (
          <ActionButton
            variant="outlined"
            color="primary"
            onClick={() => setTimeEntry({
              ...timeEntry,
              hours: getHours(timeElapsed()),
            })}
          >
            {translate('Laske aikamäärä')} {timeElapsed()}<span style={{ textTransform: 'lowercase' }}>h</span>
          </ActionButton>
        )}

        <NumTextField
          label={translate('Määrä')}
          margin="normal"
          variant="outlined"
          fullWidth
          inputProps={{
            step: 'any',
          }}
          value={timeEntry.hours}
          onChange={handleOnChange('hours')}
        />

        <TextField
          variant="outlined"
          label={translate('Muistio (julkinen)')}
          margin="normal"
          fullWidth
          multiline
          defaultValue={timeEntry.publicMemo || ''}
          onChange={handleOnChange('publicMemo')}
        />

        <TextField
          variant="outlined"
          label={translate('Muistio (sisäinen)')}
          margin="normal"
          fullWidth
          multiline
          defaultValue={timeEntry.memo || ''}
          onChange={handleOnChange('memo')}
        />

        <Box
          mt={2}
        >

          {showSaveDraftButton && (
            <div>
              <Button
                style={{ marginBottom: 10 }}
                fullWidth
                variant="outlined"
                color="primary"
                size="large"
                type="submit"
                disabled={actionButtonDisabled || saving}
                onClick={saveTimeEntryAsDraft}
              >
                {timeEntry.id ? translate('Tallenna') : translate('Lisää')} {translate('keskeneräisenä')}
              </Button>
            </div>
          )}

          <ButtonGroup fullWidth>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={actionButtonDisabled || saving}
              onClick={saveTimeEntryAsReady}
            >
              {timeEntry.id ? translate('Tallenna') : translate('Lisää')} {translate('valmiina')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ width: 55 }}
              disabled={actionButtonDisabled || saving}
              aria-controls={showSaveDraftButton ? 'split-button-menu' : undefined}
              aria-expanded={showSaveDraftButton ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={() => setShowSaveDraftButton((v) => !v)}
            >
              {showSaveDraftButton ? <ArrowDropDown /> : <ArrowDropUp />}
            </Button>
          </ButtonGroup>
          {!hasTimerProps() && (
            <ActionButton
              variant="outlined"
              color="primary"
              style={{ marginTop: 10 }}
              type="submit"
              fullWidth
              size="large"
              onClick={onCancel}
            >
              {translate('Peruuta')}
            </ActionButton>
          )}
        </Box>

        {(addedTimesheetEntries.length > 0) && (
          <ListItem>
            <ListItemText
              disableTypography
              primary={<Typography type="body1" style={{ color: '#333' }}>{translate('Lisätyt')} {addedTimesheetEntries.length} {translate('kpl')}</Typography>}
            />
          </ListItem>
        )}
        <List>
          {addedTimesheetEntries.map((entry) => (
            <TimesheetEntryRow
              key={entry.id}
              handleDeleted={handleEntryDeleted}
              {...entry}
            />
          ))}
        </List>

      </DialogContent>
    </form>
  );
};

export default TimesheetEntryDialog;
