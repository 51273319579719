import { useState } from 'react';

const initialState = () => ({
  installPrompt: null,
  appInstalled: false,
  installing: false,
});

const useAths = () => {
  const [aths, setAths] = useState(initialState());

  const listenToBeforeInstallPrompt = () => {
    // If the app is already installed, do nothing
    if ((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true) {
      setAths((prevValues) => ({
        ...prevValues,
        appInstalled: true,
      }));

      return;
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      setAths((prevValues) => ({
        ...prevValues,
        installPrompt: e,
      }));
    });
  };

  const installApp = async () => {
    setAths((prevValues) => ({
      ...prevValues,
      installing: true,
    }));

    window.addEventListener('appinstalled', () => {
      setAths((prevValues) => ({
        ...prevValues,
        appInstalled: true,
        installPrompt: null,
        installing: false,
      }));
    });

    const {
      installPrompt,
    } = aths;

    installPrompt.prompt();

    installPrompt
      .userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome !== 'accepted') {
          setAths((prevValues) => ({
            ...prevValues,
            installing: false,
            installPrompt: null,
          }));
        } else {
          setAths((prevValues) => ({
            ...prevValues,
            installPrompt: null,
          }));
        }
      });
  };

  const {
    installPrompt,
    appInstalled,
    installing,
  } = aths;

  return {
    listenToBeforeInstallPrompt,
    installApp,
    installPrompt,
    appInstalled,
    installing,
  };
};

export default useAths;
