import React, { useState } from 'react';
import {
  Box, Checkbox, CircularProgress, Collapse, Divider, FormControl, FormControlLabel, TextField, Typography,
  Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Paper, Select, Dialog, Slide, Button,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Add as AddIcon } from '@material-ui/icons';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { get } from '../helpers/service';
import ActionButton from './Buttons/ActionButton';
import EanReader from './EanReader';
import NumTextField from './NumTextField';
import { I18nContext } from '../Translations';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SearchBoxMemo = ({
  searchParams,
  prdLines,
  handleSearch,
}) => {
  const [searching, setSearching] = React.useState(false);
  const [changedSearchParams, setSearchParams] = React.useState(searchParams);
  const { translate } = React.useContext(I18nContext);
  const handleInputChange = (name, isCheckbox = false) => (e) => {
    let value;

    if (isCheckbox) {
      value = e.target.checked;
    } else {
      ({ value } = e.target);
    }

    setSearchParams((prevState) => (
      {
        ...prevState,
        [name]: value,
      }
    ));
  };

  const handleSubmit = async () => {
    const params = {
      Active: changedSearchParams.active ? '1' : '0',
      PrdLine: changedSearchParams.prdLine,
      PrdId: changedSearchParams.prdId,
      Name: changedSearchParams.name,
      'options[limit]': 100,
    };
    setSearching(true);
    await handleSearch(params);
    setSearching(false);
  };

  return (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        margin="dense"
      >
        <InputLabel
          htmlFor="prd-line-search-input"
        >
          {translate('Tuoteala')}
        </InputLabel>
        <Select
          value={changedSearchParams.prdLine}
          labelWidth={64}
          onChange={handleInputChange('prdLine')}
          inputProps={{
            id: 'prd-line-search-input',
          }}
        >
          { prdLines.map((prdLine) => (
            <MenuItem
              dense
              key={prdLine.id}
              value={prdLine.id}
            >
              { prdLine.name }
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label={translate('Nimi')}
        margin="dense"
        variant="outlined"
        fullWidth
        value={changedSearchParams.name || ''}
        onChange={handleInputChange('name')}
      />

      <TextField
        label={translate('Tuotenumero')}
        margin="dense"
        variant="outlined"
        fullWidth
        value={changedSearchParams.prdId || ''}
        onChange={handleInputChange('prdId')}
      />

      <Grid
        container
        justify="space-between"
        style={{
          marginTop: 12,
        }}
      >
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label={translate('Vain aktiiviset')}
          onChange={handleInputChange('active', true)}
        />

        <ActionButton
          loading={searching}
          variant="contained"
          size="small"
          color="primary"
          style={{
            padding: '0 16px',
          }}
          onClick={handleSubmit}
        >
          {translate('Hae')}
        </ActionButton>
      </Grid>
    </>
  );
};
const SearchBox = React.memo(SearchBoxMemo, () => true);
const Item = ({
  product,
  mode,
  handleProductSelect,
  handleProductAdd,
}) => {
  const [progress, setProgress] = React.useState(false);
  const [amount, setAmount] = React.useState(1);
  const inputRef = React.useRef();
  const { translate } = React.useContext(I18nContext);

  const handleAdd = async () => {
    setProgress(true);
    await handleProductAdd(amount);
    setProgress(false);
  };

  return (
    <ListItem
      button
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: '1px solid #ddd',
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={mode === 'add' ? 8 : 12}>
          <ListItemText
            onClick={handleProductSelect}
            primary={<div><strong>{product.Id}</strong> {product.Name1}</div>}
            secondary={product.Name2}
          />
        </Grid>
        {mode === 'add' && (
          <Grid item xs={12} sm={4}>
            <Box display="flex">
              <NumTextField
                inputRef={inputRef}
                label={translate('Määrä')}
                margin="dense"
                variant="outlined"
                fullWidth
                onFocus={() => setAmount(0)}
                onChange={({ target: { value } }) => setAmount(value)}
                value={amount || ''}
                inputProps={{
                  step: 'any',
                }}
              />
              <Button onClick={handleAdd} disabled={progress}>
                <AddIcon />
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

const ProductSearch = ({
  prdLines,
  inputParams,
  mode,
  onProductSelect,
  onProductAdd,
}) => {
  const initialSearchParams = () => ({
    prdLine: inputParams.prdLine,
    name: '',
    prdId: '',
    active: false,
  });

  const [searching, setSearching] = React.useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [searchParams, setSearchParams] = useState(initialSearchParams());
  const [showBarCodeReader, setShowBarCodeReader] = useState(false);
  const snackbar = useSnackbar();
  const { translate } = React.useContext(I18nContext);

  const handleSearch = async (params) => {
    setSearching(true);
    const r = await get('tuotteet/suorahaku', params);
    setSearchResults(r);
    setSearching(false);
  };

  const handleProductSelect = (product) => {
    setSearchParams(initialSearchParams());
    setOpenSearchBox(false);
    setSearchResults([]);

    onProductSelect(product);
  };

  const handleFoundEanCode = async (__barcode__) => {
    setShowBarCodeReader(false);

    const barcode = __barcode__.toString();
    const sortedPrdsLines = prdLines.sort((a, b) => a.length < b.length ? 1 : -1); // eslint-disable-line

    let params = {
      'options[limit]': 100,
    };

    try {
      const PrdLine = sortedPrdsLines.find((pl) => barcode.startsWith(pl.id));
      const PrdId = barcode.substr(PrdLine.length);

      params = {
        ...params,
        PrdLine,
        PrdId,
      };
    } catch (e) {
      params = {
        ...params,
        PrdId: barcode,
      };
    }

    try {
      const r = await get('tuotteet/suorahaku', params);

      if (r.length > 0) {
        handleProductSelect(r[0]);
      } else {
        snackbar.showMessage(translate('Viivakoodilla ei löytynyt tuotteita'));
      }
    } catch (e) {
      snackbar.showMessage(translate('Jotakin meni vikaan'));
    }
  };

  return (
    <Grid
      container
    >
      <Paper
        elevation={6}
        style={{
          flex: 1,
          zIndex: 1,
        }}
      >
        <List
          dense
        >
          <ListItem
            button
            onClick={() => setOpenSearchBox(!openSearchBox)}
          >
            <ListItemText
              primary={translate('Hae tuotteita')}
            />
            { openSearchBox ? <ExpandLess /> : <ExpandMore /> }
          </ListItem>
          <Collapse
            in={openSearchBox}
            timeout="auto"
            unmountOnExit
          >
            <Box
              px={2}
              pb={2}
            >
              <SearchBox
                searchParams={searchParams}
                prdLines={prdLines}
                handleSearch={handleSearch}
              />
              <Divider
                style={{
                  margin: '16px 0',
                }}
              />

              <div style={{ maxHeight: 400, overflow: 'auto' }}>
                <List dense>
                  { searchResults.map((product) => (
                    <Item
                      key={product.Id}
                      mode={mode}
                      product={product}
                      handleProductSelect={() => handleProductSelect(product)}
                      handleProductAdd={(amount) => onProductAdd(product, amount)}
                    />
                  )) }
                </List>

                { searchResults.length <= 0 && (
                  <Typography
                    component="p"
                    variant="caption"
                    align="center"
                    style={{
                      fontStyle: 'italic',
                      marginBottom: 0,
                    }}
                  >
                    {translate('Ei hakutuloksia')}
                  </Typography>
                )}
              </div>

            </Box>
          </Collapse>
        </List>
      </Paper>

      {!openSearchBox && (
        <IconButton
          onClick={() => setShowBarCodeReader(true)}
          style={{
            padding: 0,
            marginLeft: 24,
            position: openSearchBox ? 'absolute' : 'relative',
          }}
        >
          <img
            src="/images/barcode.png"
            alt=""
            style={{
              maxWidth: 48,
              display: searching ? 'none' : 'block',
            }}
          />

          <CircularProgress
            style={{
              display: searching ? 'block' : 'none',
            }}
            size={24}
          />
        </IconButton>
      )}

      <Dialog
        open={showBarCodeReader}
        fullScreen
        TransitionComponent={Transition}
      >
        <EanReader
          close={() => setShowBarCodeReader(false)}
          onSuccess={handleFoundEanCode}
        />
      </Dialog>
    </Grid>
  );
};

export default ProductSearch;
