import React from 'react';
import {
  withStyles, Button, CircularProgress,
} from '@material-ui/core';

const styles = {
  rootContainer: {
    display: 'flex',
    position: 'relative',
  },
  spinnerContainer: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {},
  hidden: {
    opacity: 0,
  },
};

const SpinnerAdornment = withStyles(styles)(({ loading, classes }) => (
  <div
    className={classes.spinnerContainer}
    style={{
      opacity: loading ? 1 : 0,
    }}
  >
    <CircularProgress
      className={classes.spinner}
      size={20}
    />
  </div>
));

const ActionButton = withStyles(styles)((props) => {
  const {
    children,
    loading,
    disabled,
    classes,
    style,
    ...rest
  } = props;

  return (
    <div
      className={classes.rootContainer}
    >
      <Button
        disabled={loading || disabled}
        style={style}
        {...rest}
      >
        <span
          style={{
            opacity: loading ? 0 : 1,
          }}
        >
          { children }
        </span>

        <SpinnerAdornment
          loading={loading}
        />
      </Button>
    </div>
  );
});

export default ActionButton;
