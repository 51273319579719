import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl, Paper, IconButton, Divider, Collapse, Box, InputLabel, Select, OutlinedInput, MenuItem, TextField,
} from '@material-ui/core';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import moment from 'moment';
import { useTimesheetFiltersContext } from '../../reducers/timesheetFilters';
import { getStatusColor } from '../../helpers/timesheetHelpers';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const df = (m) => m.format('YYYY-MM-DD');

export default function TimesheetSearch() {
  const [state, dispatch] = useTimesheetFiltersContext();
  const { values, options } = state;
  const { range, groupBy } = options;

  const classes = useStyles();
  const [openFilters, setOpenFilters] = useState(false);
  const { translate } = React.useContext(I18nContext);

  const parseStartDate = (date) => df(moment(date));
  const parseEndDate = (date) => df(moment(date).add(...range.split(',')).subtract(1, 'days'));
  const [startDate, setStartDate] = useState(parseStartDate(values.startdate));

  React.useEffect(() => {
    const startdate = parseStartDate(startDate);
    dispatch({
      type: 'SET_TIMESHEET_VALUES',
      payload: {
        startdate,
        enddate: parseEndDate(startDate),
      },
    });
    setStartDate(startdate);
  }, [range, startDate]);

  return (
    <Box px={1} pt={1} pb={1}>
      <Paper>
        <div className={classes.filterContainer}>
          <FormControl
            variant="outlined"
            fullWidth
            margin="dense"
            style={{ marginLeft: 8, marginRight: 4 }}
          >
            <InputLabel
              htmlFor="filter-status"
              shrink
            >
              {translate('Tila')}
            </InputLabel>
            <Select
              displayEmpty
              style={{ backgroundColor: getStatusColor(values.bystate) }}
              value={values.bystate && values.bystate.join(',')}
              onChange={(ev) => dispatch({
                type: 'SET_TIMESHEET_VALUES',
                payload: {
                  bystate: ev.target.value.split(','),
                },
              })}
              inputProps={{
                id: 'filter-status',
              }}
              input={(
                <OutlinedInput
                  notched
                  labelWidth={28}
                />
              )}
            >
              <MenuItem value="k">{translate('Kesken')}</MenuItem>
              <MenuItem value="v">{translate('Valmis')}</MenuItem>
              <MenuItem value="h">{translate('Hyväksytty')}</MenuItem>
              <MenuItem value="s">{translate('Siirretty')}</MenuItem>
              <MenuItem value="k,v,h,s">{translate('Kaikki')}</MenuItem>
            </Select>
          </FormControl>
          <Divider
            className={classes.divider}
            orientation="vertical"
          />
          <IconButton color="primary" onClick={() => setOpenFilters(!openFilters)}>
            <FilterListIcon />
          </IconButton>
        </div>

        <Collapse
          in={openFilters}
          timeout="auto"
          unmountOnExit
        >
          <Divider />

          <Box
            p={1}
          >
            <FormControl
              variant="outlined"
              margin="dense"
              style={{ marginRight: 5 }}
            >
              <InputLabel
                htmlFor="filter-range"
                shrink
              >
                {translate('Jakso')}
              </InputLabel>
              <Select
                displayEmpty
                value={range}
                onChange={(ev) => dispatch({
                  type: 'SET_TIMESHEET_OPTIONS',
                  payload: {
                    range: ev.target.value,
                  },
                })}
                inputProps={{
                  id: 'filter-range',
                }}
                input={(
                  <OutlinedInput
                    notched
                    labelWidth={45}
                  />
                )}
              >
                <MenuItem value="1,days">{translate('Päivä')}</MenuItem>
                <MenuItem value="1,weeks">{translate('Viikko')}</MenuItem>
                <MenuItem value="2,weeks">{translate('2 viikkoa')}</MenuItem>
                <MenuItem value="1,months">{translate('Kuukausi')}</MenuItem>
              </Select>
            </FormControl>

            <TextField
              margin="dense"
              variant="outlined"
              label="Alkupäivä"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.startdate}
              onChange={(ev) => setStartDate(parseStartDate(ev.target.value))}
            />

          </Box>
          <Box p={1}>
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <InputLabel
                htmlFor="filter-group"
                shrink
              >
                {translate('Yhteenveto')}
              </InputLabel>
              <Select
                displayEmpty
                value={groupBy}
                onChange={(ev) => dispatch({
                  type: 'SET_TIMESHEET_OPTIONS',
                  payload: {
                    groupBy: ev.target.value,
                  },
                })}
                inputProps={{
                  id: 'filter-group',
                }}
                input={(
                  <OutlinedInput
                    notched
                    labelWidth={85}
                  />
                )}
              >
                <MenuItem value="day">{translate('Päivä')}</MenuItem>
                <MenuItem value="week">{translate('Viikko')}</MenuItem>
                <MenuItem value="month">{translate('Kuukausi')}</MenuItem>
                <MenuItem value="covnote">{translate('Lähete')}</MenuItem>
                <MenuItem value="project">{translate('Projekti')}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
}
