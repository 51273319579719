import React, { useContext, useEffect } from 'react';
import {
  Fab, ListItem, ListItemText, Grid, IconButton,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import TimerIcon from '@material-ui/icons/Timer';
import SwipeableViews from 'react-swipeable-views';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SubProjectRow from './SubProjectRow';
import { Store } from '../../App';
import useTimer from '../../actions/timer';
import TimesheetEntryButton from '../Buttons/TimesheetEntryButton';
import { I18nContext } from '../../Translations';


const ProjectRow = ({
  project,
  title,
  handleClick,
  bgColor = 'white',
  isExpandable = false,
  isExpanded = false,
  disableSwipe = false,
  handleRowClick,
}) => {
  const { state, dispatch } = useContext(Store);
  const timer = useTimer(dispatch);
  const snackbar = useSnackbar();
  const [index, setIndex] = React.useState(0);
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    if (state.timer.active) {
      setIndex(0);
    }
  }, [state.timer.active]);


  const validator = (callback) => {
    if (!state.user.permissions.cr_se_projectinput) {
      snackbar.showMessage(translate('Ei käyttöoikeutta'));
      return;
    }
    callback();
  };

  const startTimer = () => {
    const props = {
      name: project.name,
      prjId: project.id,
    };
    if (project.projectId) {
      props.prjId = project.projectId;
      props.subPrjId = project.id;
    }
    timer.start(props);
  };

  return (
    <SwipeableViews
      index={index}
      disabled={disableSwipe}
      onChangeIndex={(idx) => setIndex(idx)}
      containerStyle={{ justify: 'center', alignItems: 'center' }}
      style={{ background: '#CEE9F2' }}
    >
      <ListItem
        divider
        onClick={handleRowClick}
        style={{ background: bgColor, minHeight: 100 }}
      >
        <Grid
          container
          direction="column"
        >
          <ListItemText
            primary={title}
            secondary={project.name}
          />
        </Grid>
        {!!isExpandable && (
          <IconButton
            color="primary"
            onClick={(ev) => {
              ev.stopPropagation();
              handleClick();
            }}
          >
            { isExpanded ? <ExpandLess /> : <ExpandMore /> }
          </IconButton>
        )}
      </ListItem>

      <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '12px 16px 12px 16px' }}>
        <div style={{ marginRight: 24, textAlign: 'center' }}>
          <TimesheetEntryButton
            validator={validator}
            timerProps={{
              prjId: project.id,
              name: project.name,
            }}
          />
          <div style={{ marginTop: 10, color: '#333', fontSize: 12 }}>{translate('Palkkatapahtuma')}</div>
        </div>
        <div style={{ textAlign: 'center' }}>

          <Fab
            disabled={state.timer.active}
            color="primary"
            onClick={() => validator(startTimer)}
            size="medium"
          >
            <TimerIcon />
          </Fab>
          <div style={{ marginTop: 10, color: '#333', fontSize: 12 }}>{translate('Työlaskuri')}</div>
        </div>
      </div>
    </SwipeableViews>
  );
};

export default ({
  handleRowClick, project, expanded, disableSwipe,
}) => {
  const [expand, setExpand] = React.useState(expanded);
  React.useEffect(() => {
    setExpand(expanded);
  }, [expanded]);

  return (
    <div style={{ marginBottom: 10 }} className="MuiPaper-elevation1">
      <ProjectRow
        project={project}
        title={`${project.id}`}
        disableSwipe={disableSwipe}
        isExpandable={project.SubProjects.length}
        isExpanded={expand}
        handleClick={() => setExpand(!expand)}
        handleRowClick={() => handleRowClick({ prjId: project.id, project })}
      />
      {expand && project.SubProjects && project.SubProjects.map((subProject) => (
        <SubProjectRow
          key={subProject.id}
          disableSwipe={disableSwipe}
          subProject={subProject}
          title={`${subProject.projectId} » ${subProject.id}`}
          bgColor="#f5f5f5"
          handleRowClick={() => handleRowClick({
            prjName: project.name, prjId: subProject.projectId, subPrjId: subProject.id, subProject,
          })}
          subPrj={subProject.id}
        />
      ))}
    </div>
  );
};
