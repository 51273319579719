const userReducer = (state, { type, payload }) => {
  if (type === 'LOGIN') {
    return payload;
  }
  if (type === 'LOGOUT') {
    return null;
  }
  return state;
};

const covnoteFiltersReducer = (state, { type, payload }) => (type === 'UPDATE_COVNOTE_FILTERS' ? payload : state);

const timerReducer = (state = {}, { type, payload }) => {
  if (type === 'INCR_TIMER') {
    const elapsedInMinutes = ~~((Date.now() - state.started) / (60 * 1000));
    const h = ~~(elapsedInMinutes / 60);
    const m = elapsedInMinutes % 60;
    const mm = String(m).padStart(2, '0');
    return {
      ...state,
      elapsed: `${h}:${mm}`,
    };
  }

  if (type === 'START_TIMER') {
    const context = ['prjId', 'subPrjId', 'covId', 'covEcomId', 'name'].reduce((acc, key) => ({ ...acc, [key]: payload[key] || null }), {});
    return {
      ...state,
      ...context,
      elapsed: '0:00',
      started: Date.now(),
      finished: null,
      active: true,
    };
  }

  if (type === 'STOP_TIMER') {
    return {
      ...state,
      finished: Date.now(),
      active: false,
    };
  }

  if (type === 'RESET_TIMER') {
    return {};
  }

  return state;
};

const locationReducer = (state = {}, { type }) => {
  if (type === 'TRACK_LOCATION') {
    return {
      ...state,
      tracking: true,
    };
  }

  if (type === 'STOP_LOCATION_TRACKING') {
    return {
      ...state,
      tracking: false,
    };
  }

  return state;
};

const salaryTypeReducer = (state = {}, { type, payload }) => {
  if (type === 'SET_SALARYTYPES') {
    return {
      ...state,
      default: payload.default || payload.normal,
      normal: payload.default ? payload.normal : [],
    };
  }
  return state;
};

export default (state, action) => {
  const newState = {
    ...state,
    user: userReducer(state.user, action),
    salaryTypes: salaryTypeReducer(state.salaryTypes, action),
    covnoteFilters: covnoteFiltersReducer(state.covnoteFilters, action),
    timer: timerReducer(state.timer, action),
    location: locationReducer(state.location, action),
  };
  return newState;
};
