import React, {
  useState, useEffect, useContext,
} from 'react';
import {
  Fab, Typography, Grid, Box, IconButton, Menu, MenuItem, LinearProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { get } from '../../helpers/service';
import _CovnoteSearch from './CovnoteSearch';
import CovnoteItem from './CovnoteItem';
import Main from '../Main';
import ListContainer from '../ListContainer';
import { Store } from '../../App';
import OpenUrlWithTokenLinkMenuItem from '../LinkMenu';
import { I18nContext } from '../../Translations';

const CovnoteSearch = React.memo(_CovnoteSearch, () => true);
const PER_PAGE_LIMIT = 20;

export const Covnotes = ({ onItemClick, disableItemSwipe = false }) => {
  const { state } = useContext(Store);
  const [covnotes, setCovnotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState({});
  const { translate } = useContext(I18nContext);

  const fetchCovnotes = async (newQueryParams = {}) => {
    setLoading(true);

    const params = {
      limit: PER_PAGE_LIMIT,
      ...queryParams,
      ...newQueryParams,
      page: currentPage,
    };

    const resultWithoutAddedKeys = await get('api/lahetteet', params);
    // "key" attributes are (apparently) used by the salary grid, in order to compare rows with projects, subprojects and covnotes "polymorphically"
    const result = resultWithoutAddedKeys.map((covnote) => ({ ...covnote, key: `L${covnote.id}` }));

    const isPageSizeFull = result.length >= PER_PAGE_LIMIT;
    let isSamePage = false;

    if (covnotes.length >= 1 && result.length >= 1) {
      isSamePage = result[result.length - 1].id === covnotes[covnotes.length - 1].id;
    }

    const hasMoreItems = isPageSizeFull && !isSamePage;

    setHasNextPage(hasMoreItems);
    setCovnotes([
      ...(currentPage > 1 ? covnotes : []),
      ...result,
    ]);
    setQueryParams(params);

    setLoading(false);
  };

  useEffect(() => {
    fetchCovnotes(state.covnoteFilters);
  }, [currentPage]);

  const hasPermission = () => state.user.permissions.cr_mod_coveringnotes;

  return (
    <div>
      {!hasPermission() && (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            paddingTop: 32,
          }}
        >
          <Typography
            variant="body2"
            style={{
              fontStyle: 'italic',
            }}
          >
            {translate('Ei käyttöoikeutta')}
          </Typography>
        </Grid>
      )}

      {hasPermission() && (
        <>
          <CovnoteSearch fetchCovnotes={fetchCovnotes} />
          {!disableItemSwipe && (
            <Box
              px={1}
              pt={1}
              pb={1}
            >
              <Grid
                container
                justify="space-between"
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                  >
                    {translate('Lisää toimintoja pyyhkäisemällä riviä')}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" edge="end" aria-label="refresh" onClick={() => fetchCovnotes()}>
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          )}
          {loading && <LinearProgress />}
          <ListContainer
            loading={loading}
            spinnerLabel={translate('Haetaan lähetteitä')}
            pagination={{
              currentPage,
              setCurrentPage,
              hasNextPage,
            }}
          >
            {covnotes.map((covnote) => (
              <CovnoteItem
                disableSwipe={disableItemSwipe}
                key={covnote.id}
                onClick={onItemClick}
                covnote={covnote}
              />
            ))}
          </ListContainer>
        </>
      )}
    </div>
  );
};

export default () => {
  const { state } = useContext(Store);
  const snackbar = useSnackbar();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const { translate } = useContext(I18nContext);

  const handleMenuButtonClick = (event) => setMenuAnchorEl(event.currentTarget);

  const handleMenuClose = () => setMenuAnchorEl(null);

  return (
    <Main
      title={translate('Lähetteet')}
      titleButton={(
        <>
          <IconButton
            size="small"
            style={{ color: 'white' }}
            aria-controls="simple-menu"
            onClick={handleMenuButtonClick}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {menuAnchorEl && (
              <>
                <OpenUrlWithTokenLinkMenuItem
                  label={translate('Avaa selaimessa')}
                  webiurl="lahetteet"
                  handleClick={handleMenuClose}
                />
                <MenuItem
                  component={Link}
                  to="/calendar"
                >
                  {translate('Kalenteri (beta)')}
                </MenuItem>
              </>
            )}
          </Menu>
        </>
      )}
    >
      <Covnotes />
      <Fab
        color="primary"
        component={Link}
        to="/covnotes/uusi/tiedot"
        style={{
          position: 'fixed',
          bottom: 72,
          right: 16,
        }}
        onClick={(e) => {
          if (!state.user.permissions.cr_covnote_create) {
            e.preventDefault();
            snackbar.showMessage(translate('Ei käyttöoikeutta'));
          }
        }}
      >
        <AddIcon />
      </Fab>
    </Main>
  );
};
