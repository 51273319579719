import React from 'react';
import {
  Box, Typography, Button,
} from '@material-ui/core';
import SeveriIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { I18nContext } from '../../../Translations';

const useStyles = makeStyles((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function CovnoteMisc({ covnote }) {
  const classes = useStyles();
  const { translate } = React.useContext(I18nContext);

  const cleanString = (str) => {
    if (!str) return '';
    return String(str).trim();
  };

  const severiValueMap = {
    kohteennimi: cleanString(covnote.location),
    tyonumero: cleanString(covnote.ecomId),
    // 'kohteenyksilointi':
    // 'katuosoite':
    // 'postinumero':
    // 'postitoimipaikka':
    yhteyshenkilonimi: covnote.OwnerPerson ? cleanString(covnote.OwnerPerson.name) : '',
    yhteyshenkilosahkoposti: covnote.OwnerPerson ? cleanString(covnote.OwnerPerson.email) : '',
    yhteyshenkilopuhelin: covnote.OwnerPerson ? cleanString(covnote.OwnerPerson.phone1) : '',
    tilaajanimi: covnote.Customer ? cleanString(covnote.Customer.name) : '',
    tilaajakatuosoite: covnote.Customer ? cleanString(`${cleanString(covnote.Customer.street1)} ${cleanString(covnote.Customer.street2)}`) : '',
    tilaajapostinumero: covnote.Customer ? cleanString(covnote.Customer.zip) : '',
    tilaajapostitoimipaikka: covnote.Customer ? cleanString(covnote.Customer.city) : '',
    tilaajayhteyshenkilo: covnote.Customer ? cleanString(covnote.Customer.contactPerson) : '',
    tilaajasahkoposti: covnote.Customer ? cleanString(covnote.Customer.email) : '',
    tilaajapuhelin: covnote.Customer ? cleanString(covnote.Customer.phone1) : '',
  };
  const severiUriParams = queryString.stringify(severiValueMap, { arrayFormat: 'bracket' });

  const doceanValueMap = {
    customer_name: covnote.Customer ? cleanString(covnote.Customer.name) : '',
    customer_address: covnote.Customer ? cleanString(`${cleanString(covnote.Customer.street1)} ${cleanString(covnote.Customer.street2)}`) : '',
    customer_zip: covnote.Customer ? cleanString(covnote.Customer.zip) : '',
    customer_city: covnote.Customer ? cleanString(covnote.Customer.city) : '',
    customer_phone: covnote.Customer ? cleanString(covnote.Customer.phone1) : '',
    customer_email: covnote.Customer ? cleanString(covnote.Customer.email) : '',
    site_name: cleanString(covnote.location),
    site_work_number: cleanString(covnote.ecomId),
    site_address: covnote.Customer ? cleanString(`${cleanString(covnote.Customer.street1)} ${cleanString(covnote.Customer.street2)}`) : '',
    site_zip: covnote.Customer ? cleanString(covnote.Customer.zip) : '',
    site_city: covnote.Customer ? cleanString(covnote.Customer.city) : '',
    site_description: cleanString(covnote.description),
  };
  const doceanUriParams = queryString.stringify(doceanValueMap, { arrayFormat: 'bracket' });

  return (
    <Box p={2}>
      <Typography
        component="h2"
        variant="caption"
      >
        <img src="/images/docean-logo.png" alt="Docean Oy" />
      </Typography>

      <Typography
        component="p"
        variant="body1"
        style={{
          color: '#757575',
        }}
      >
        {translate('Täytä kohteen tarkastuspöytäkirja Doceanissa.')}
      </Typography>

      <Button
        href={`https://docean.fi/ecom?${doceanUriParams}`}
        target="_blank"
        rel="noopener"
      >
        <SeveriIcon className={classes.leftIcon} />{translate('Luo Docean-tarkastuslomake')}
      </Button>

      <hr />

      <Typography
        component="h2"
        variant="caption"
      >
        <img src="/images/sahkoinfo_logo_netti_rgb.png" alt="Sähköinfo Oy" />
      </Typography>

      <Typography
        component="p"
        variant="body1"
        style={{
          color: '#757575',
        }}
      >
        {translate('Täytä kohteen sähkötarkastuspöytäkirja Sähköinfon Severi-palvelussa.')}
      </Typography>

      <Button
        href={`https://severi.sahkoinfo.fi/item/5946?${severiUriParams}`}
        target="_blank"
        rel="noopener"
      >
        <SeveriIcon className={classes.leftIcon} />{translate('Käyttöönottotarkastuspöytäkirja')}
      </Button>
      <br />
      <Button
        href={`https://severi.sahkoinfo.fi/item/5977?${severiUriParams}`}
        target="_blank"
        rel="noopener"
      >
        <SeveriIcon className={classes.leftIcon} />{translate('Käyttöönottotarkastuspöytäkirja ryhmäjohtotason sähköasennuksille')}
      </Button>
    </Box>
  );
}
