import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField,
} from '@material-ui/core';
import { I18nContext } from '../Translations';

const SalaryGridAddEventSalaryType = ({
  open, salaryTypes, salaryTypesNormal, handleCloseDialog, setAdditionalSalaryTypes,
}) => {
  const [values, setValues] = useState(false);

  const handleChange = (ev, newValues) => {
    const sortedValues = newValues.map((item) => ({ salaryTypeId: item.id, salaryTypeName: item.name }));
    setValues(sortedValues);
  };

  const handleAddTypes = () => {
    setAdditionalSalaryTypes((prevState) => [...prevState, ...values]);
    handleCloseDialog();
  };

  const { translate } = React.useContext(I18nContext);

  return (
    <Dialog open={open} onClose={handleCloseDialog} disableBackdropClick>
      <DialogTitle>{translate('Lisää palkkalaji')}</DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          freeSolo
          options={salaryTypesNormal.filter((normalType) => !salaryTypes.find((defaultType) => defaultType.salaryTypeId === normalType.id))}
          getOptionLabel={(option) => `${option.id} : ${option.name}`}
          onChange={handleChange}
          style={{ width: 230 }}
          renderInput={(params) => <TextField {...params} label={translate('Palkkalaji')} />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          {translate('Sulje')}
        </Button>
        <Button onClick={handleAddTypes} color="primary">
          {translate('Lisää')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SalaryGridAddEventSalaryType;
