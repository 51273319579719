import { get, getAuthHeaders } from './service';

const getWebiUrlWithToken = async (base) => {
  const { token } = await get('gettoken');

  const authHeaders = getAuthHeaders();
  const host = authHeaders['X-EcomWebi-Server'] || 'www.ecomwebi.fi';
  const url = `https://${host}`;

  return (`${url}/${base}?token=${token}`);
};

export {
  getWebiUrlWithToken, // eslint-disable-line
};
