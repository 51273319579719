let timerInterval;
let prev;
const getMinutes = () => (new Date()).getMinutes();
const Timer = (dispatch) => {
  const init = () => {
    clearInterval(timerInterval);
    timerInterval = setInterval(() => {
      const current = getMinutes();
      if (current !== prev) {
        dispatch({ type: 'INCR_TIMER' });
        prev = current;
      }
    }, 1000);
  };
  return {
    init,
    start: (obj) => {
      init();
      dispatch({ type: 'START_TIMER', payload: obj });
    },
    stop: () => {
      clearInterval(timerInterval);
      dispatch({ type: 'STOP_TIMER' });
    },
    reset: () => {
      clearInterval(timerInterval);
      dispatch({ type: 'RESET_TIMER' });
    },
  };
};
export default Timer;
