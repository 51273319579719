import React, { useState, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SalaryGridInit from './SalaryGridInit';
import SalaryGridAddEvent from './SalaryGridAddEvent';
import SalaryGridEditEvent from './SalaryGridEditEvent';
import { get, post } from '../helpers/service';
import { Store } from '../App';
import { useSalaryGridFiltersContext, SalaryGridFiltersContextProvider } from '../reducers/SalaryGridFilters';
import { I18nContext } from '../Translations';

const useStyles = makeStyles(() => ({
  stickyTable: {
    position: 'relative',
    borderCollapse: 'collapse',
  },

  theadThFirst: {
    left: 0,
    fontSize: '12px',
  },

  tHeadTh: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },

  tHeadLastTh: {
    position: 'sticky',
    top: 0,
    right: 0,
    zIndex: 2,
    backgroundColor: 'white',
  },

  tHeadDiv: {
    fontSize: '10px',
    padding: '4px',
  },

  tBodyTh: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
  },

  tBodyDiv: {
    fontSize: '10px',
    padding: '4px',
  },

  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tBodyLastTrTh: {
    position: 'sticky',
    left: 0,
    bottom: 0,
    paddingBottom: 10,
    zIndex: 2,
    minWidth: 100,
  },

  tBodyLastTrTd: {
    position: 'sticky',
    bottom: 0,
    // zIndex: 2,
  },

  tBodyLastTrTdDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
  },
  odd: {
    backgroundColor: '#f0f4f7',
  },
  even: {
    backgroundColor: '#FFFFFF',
  },
  dateInput: {
    fontSize: '12px',
    maxWidth: '110px',
  },
  saveButton: {
    marginTop: 10,
    position: 'sticky',
    left: 0,
    bottom: 0,
  },
}));

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...rest
  } = props;

  return (
    <div
      hidden={value !== index}
      {...rest}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const SalaryGrid = () => {
  const [wantedDate, setWantedDate] = React.useState('');
  const { translate } = React.useContext(I18nContext);

  const tab = 0;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [rowIdsToBeSaved, setRowIdsToBeSaved] = useState([]);
  const [salaryEntries, setSalaryEntries] = useState([]);
  const [covNoteEntries, setCovNoteEntries] = useState([]);
  const [projectEntries, setProjectEntries] = useState([]);
  const [additionalSalaryTypes, setAdditionalSalaryTypes] = useState([]);
  const [openGrid, setOpenGrid] = useState(true);
  const snackbar = useSnackbar();
  const { state } = useContext(Store);
  const [salaryGridFilters, dispatchSalaryGridFilters] = useSalaryGridFiltersContext();
  const { salaryGridAddParams, salaryGridEditParams } = salaryGridFilters;

  const fetch = async () => {
    setLoading(true);
    try {
      let result = [];
      try {
        result = await get('api/palkkatapahtumat', salaryGridAddParams);
      } catch (e) {
        // console.log(e);
      }
      const newEntries = result.reduce((acc, row) => {
        if (acc.find((r) => r.id === row.id)) {
          return acc;
        }
        return [...acc, row];
      }, []);

      setSalaryEntries(newEntries);
    } finally {
      setLoading(false);
    }
  };

  const salaryTypes = [...state.salaryTypes.default.map((item) => ({ salaryTypeId: item.id, salaryTypeName: item.name })), ...additionalSalaryTypes];

  const fetchCovNotes = async () => {
    const result = await get('api/lahetteet', salaryGridEditParams);
    const newCovNotes = result.map((item) => ({
      ...item,
      key: `L${item.id}`,
      salaryEvents: salaryTypes.reduce((acc, salaryType) => ({
        ...acc,
        [salaryType.salaryTypeId]: null,
      }), []),
    }), {});
    setCovNoteEntries(newCovNotes);
  };

  // eslint-disable-next-line max-len
  const validEntries = [...covNoteEntries, ...projectEntries].reduce((acc, entry) => acc + Object.values(entry.salaryEvents).filter((event) => +event).length, 0);

  const handleEntriesBatchUpdate = async () => {
    const allPromises = [];
    // eslint-disable-next-line no-alert
    if (window.confirm(translate('Tallennetaan') + validEntries + translate('uutta palkkatapahtumaa valmiiksi'))) {
      rowIdsToBeSaved.forEach((rowKey) => {
        const entry = [...covNoteEntries, ...projectEntries].find((noteEntry) => noteEntry.key === rowKey);

        let target = {};
        if (entry.CovStatus) {
          target = {
            covId: entry.id,
          };
        }
        if (entry.prjId) {
          target = {
            prjId: entry.prjId,
          };
        }
        if (entry.subPrjId) {
          target = {
            prjId: entry.prjId,
            subPrjId: entry.subPrjId,
          };
        }

        if (Object.keys(entry.salaryEvents).length) {
          Object.entries(entry.salaryEvents).forEach(([key, value]) => {
            if (+value) {
              allPromises.push(post('api/palkkatapahtumat', {
                ...target,
                salaryTypeId: key,
                hours: value,
                isReady: 1,
                eventDate: wantedDate,
                memo: translate('Syötetty Webi-appin tuntikortilta'),
              }));
            }
          });
        }
      });
      return Promise.all(allPromises);
    }
    return false;
  };

  const handleBatchUpdate = async () => {
    try {
      if (tab === 0) {
        const ok = await handleEntriesBatchUpdate();
        if (!ok) {
          return false;
        }
        localStorage.setItem('tsf',
          // eslint-disable-next-line max-len
          `{"values":{"bystate":["v"],"startdate":"${wantedDate}","enddate":"${wantedDate}"},"params":{"filter[bystate]":["v"],"filter[ownonly]":true,"filter[startdate]":"${wantedDate}","filter[enddate]":"${wantedDate}"},"options":{"range":"1,days","groupBy":"day"}}`);
        setCovNoteEntries([]);
        setProjectEntries([]);
      }
      setRowIdsToBeSaved([]);
      snackbar.showMessage('Tallennus onnistui');
      // eslint-disable-next-line no-return-assign
      setTimeout(() => window.location.href = '/timesheet', 1000);
    } catch (error) {
      return snackbar.showMessage(translate('Tallennus epäonnistui'));
    } finally {
      setLoading(false);
    }
    return true;
  };

  React.useEffect(() => {
    if (tab === 1) {
      fetch();
    }
    if (tab === 0 && !openGrid) {
      fetchCovNotes();
    }
    // eslint-disable-next-line
  }, [salaryGridFilters]);

  return (
    <div style={{ backgroundColor: 'white' }}>
      {/* Ä
      <AppBar
        position="static"
      >
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={handleTabChange}
        >
          <Tab label="Lisää" />
          <Tab label="Muokkaa" />
        </Tabs>
      </AppBar>
      */}

      <TabPanel
        value={tab}
        index={0}
        style={{
          overflowX: 'auto',
          maxHeight: 'calc(100vh - 150px)',
        }}
      >
        {openGrid && (
          <SalaryGridInit {...{
            setOpenGrid, setWantedDate,
          }}
          />
        )}
        {!openGrid && (
          <SalaryGridAddEvent
            salaryTypesNormal={state.salaryTypes.normal}
            {...{
              classes,
              covNoteEntries,
              projectEntries,
              salaryTypes,
              salaryGridEditParams,
              wantedDate,
              // dispatchSalaryGridFilters,
              setCovNoteEntries,
              setProjectEntries,
              rowIdsToBeSaved,
              setRowIdsToBeSaved,
              setAdditionalSalaryTypes,
            }}
          />
        )}
      </TabPanel>
      <TabPanel
        value={tab}
        index={1}
        style={{
          overflowX: 'auto',
          maxHeight: 'calc(100vh - 150px)',
        }}
      >

        <SalaryGridEditEvent
          {...{
            classes,
            salaryEntries,
            salaryTypes,
            salaryGridAddParams,
            dispatchSalaryGridFilters,
            setSalaryEntries,
            rowIdsToBeSaved,
            setRowIdsToBeSaved,
          }}
        />
      </TabPanel>

      {loading && <LinearProgress />}
      {!openGrid && (
        <ButtonGroup fullWidth className={classes.saveButton}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={!validEntries}
            onClick={handleBatchUpdate}
          >
            {translate('Tallenna')}
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (window.confirm(translate('Perutaanko tuntikortti'))) {
                setCovNoteEntries([]);
                setProjectEntries([]);
                setOpenGrid(true);
              }
            }}
          >
            {translate('Peruuta')}
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};


export default function SalaryGridProvider() {
  return (
    <SalaryGridFiltersContextProvider>
      <SalaryGrid />
    </SalaryGridFiltersContextProvider>
  );
}
