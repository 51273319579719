import React, {
  useEffect, useContext,
} from 'react';
import {
  ListItem, Fab,
} from '@material-ui/core';

import TimerIcon from '@material-ui/icons/Timer';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import useTimer from '../../actions/timer';
import { Store } from '../../App';
import TimesheetEntryButton from '../Buttons/TimesheetEntryButton';

// api/asetukset/whoami
export default function CovnoteItem({ covnote, children }) {
  const covnoteId = covnote.ecomId ? covnote.ecomId : `;${covnote.id}`;
  const { state, dispatch } = useContext(Store);
  const timer = useTimer(dispatch);

  const [index, setIndex] = React.useState(0);
  const startTimer = () => {
    timer.start({
      covId: covnote.id,
      covEcomId: covnote.ecomId,
      name: covnote.location,
    });
  };

  useEffect(() => {
    if (state.timer.active) {
      setIndex(0);
    }
  }, [state.timer.active]);

  return (
    <SwipeableViews
      index={index}
      onChangeIndex={(idx) => setIndex(idx)}
      containerStyle={{ justify: 'center', alignItems: 'center' }}
    >
      <ListItem
        disabled={!covnote.isWebiLocked}
        divider
        key={covnote.resId}
        button
        style={{ padding: 0 }}
        component={Link}
        to={{
          pathname: `/covnotes/${covnoteId}/tiedot`,
          state: {
            referrer: '/calendar',
          },
        }}
      >
        {children}
      </ListItem>

      <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '7px 4px 7px 17px' }}>
        <div style={{ transition: 'scale(0.9)', marginRight: 24, textAlign: 'center' }}>
          <TimesheetEntryButton
            size="small"
            timerProps={{
              covId: covnote.id,
              covEcomId: covnote.ecomId,
              name: covnote.location,
            }}
          />
        </div>

        <div style={{ transition: 'scale(0.9)', textAlign: 'center' }}>
          <Fab
            size="small"
            disabled={state.timer.active}
            color="primary"
            onClick={startTimer}
          >
            <TimerIcon />
          </Fab>
        </div>
      </div>
    </SwipeableViews>
  );
}
