import React, { useContext, useState, useRef } from 'react';
import {
  DialogContent, DialogTitle, FormControl, InputLabel,
  List, ListItem, ListItemText, Typography,
  InputAdornment, TextField, makeStyles, IconButton, Select, MenuItem, Box,
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import DialogContext from '../../context/DialogContext';
import ProductSearch from '../ProductSearch';
import ActionButton from '../Buttons/ActionButton';
import NumTextField from '../NumTextField';
import { I18nContext } from '../../Translations';

import CovnoteRow from '../Covnotes/CovnoteTabs/CovnoteRow';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    position: 'fixed',
    width: '100%',
    zIndex: 999,
  },
  dialogContent: {
    paddingTop: 64,
    height: '100%',
  },
  actions: {
    background: theme.palette.grey['100'],
  },
}));

const CovnoteRowDialog = ({
  titleText,
  onSave,
  covnoteRow = null,
  prdLines = [],
  onAddedRowDeleted,
}) => {
  const amountRef = useRef();
  const { hideDialog } = useContext(DialogContext);
  const classes = useStyles();
  const [saving, setSaving] = useState(false);

  const [destroying, setDestroying] = useState({});
  const [addedCovnoteRows, setAddedCovnoteRows] = React.useState([]);

  const { translate } = useContext(I18nContext);

  const defaultPrdLine = prdLines.find((p) => p.isDefault);
  const [inputs, setInputValues] = useState({
    prdId: '',
    prdLine: defaultPrdLine ? defaultPrdLine.id : '',
    name: '',
    amount: null,
    ...covnoteRow,
  });

  const handleInputChange = (name) => (e) => {
    const { value } = e.target;

    setInputValues((prevState) => (
      {
        ...prevState,
        [name]: value,
      }
    ));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setSaving(true);

    const results = {};

    await onSave(inputs, results);

    if (results.addedCovnoteRow) {
      setAddedCovnoteRows((rows) => [...rows, results.addedCovnoteRow]);
    }

    setSaving(false);
  };

  const handleProductSelect = (product) => {
    const {
      Id: prdId,
      CmrPrdLine: prdLine,
      Name1,
      Name2,
      Unit: unit,
    } = product;

    setInputValues((prevState) => (
      {
        ...prevState,
        prdId,
        prdLine,
        name: `${Name1} ${Name2}`,
        amount: null,
        unit,
      }
    ));

    amountRef.current.focus();
  };
  const handleProductAdd = async (product, amount) => {
    setSaving(true);
    const results = {};
    await onSave({
      prdId: product.Id,
      prdLine: product.CmrPrdLine,
      name: `${product.Name1} ${product.Name2}`,
      unit: product.Unit,
      amount,
    }, results);

    if (results.addedCovnoteRow) {
      setAddedCovnoteRows((rows) => [...rows, results.addedCovnoteRow]);
    }

    setSaving(false);
  };

  return (
    <form
      onSubmit={onSubmit}
      style={{
        height: '100%',
      }}
    >
      <DialogTitle
        className={classes.dialogTitle}
      >

        <Box display="flex">

          <IconButton
            color="inherit"
            style={{
              padding: 4,
              marginRight: 8,
            }}
            onClick={hideDialog}
          >
            <CloseIcon />
          </IconButton>

          { covnoteRow ? translate('Muokkaa riviä') : translate('Lisää rivi') }

          <Box flexGrow={1}>
            { titleText && (<Typography align="right">{titleText}</Typography>)}
          </Box>

        </Box>
      </DialogTitle>

      <DialogContent
        className={classes.dialogContent}
      >
        <Box
          pt={2}
          pb={4}
        >
          <ProductSearch
            prdLines={prdLines}
            inputParams={inputs}
            mode={covnoteRow ? 'edit' : 'add'}
            onProductSelect={handleProductSelect}
            onProductAdd={handleProductAdd}
          />
        </Box>

        <FormControl
          variant="outlined"
          fullWidth
        >
          <InputLabel
            htmlFor="prd-line-input"
          >
            {translate('Tuoteala')}
          </InputLabel>
          <Select
            value={inputs.prdLine}
            labelWidth={64}
            onChange={handleInputChange('prdLine')}
            inputProps={{
              id: 'prd-line-input',
            }}
          >
            { prdLines.map((prdLine) => (
              <MenuItem
                key={prdLine.id}
                value={prdLine.id}
              >
                { prdLine.name }
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          label={translate('Tuotenumero')}
          value={inputs.prdId || ''}
          onChange={handleInputChange('prdId')}
          margin="normal"
          fullWidth
          style={{
            flex: 1,
          }}
        />

        <TextField
          label={translate('Nimi')}
          value={inputs.name || ''}
          onChange={handleInputChange('name')}
          margin="normal"
          variant="outlined"
          fullWidth
          multiline
        />

        <NumTextField
          inputRef={amountRef}
          label={translate('Määrä')}
          value={inputs.amount || ''}
          onChange={handleInputChange('amount')}
          margin="normal"
          variant="outlined"
          fullWidth
          inputProps={{
            step: 'any',
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            endAdornment: <InputAdornment position="end">{inputs.unit || ''}</InputAdornment>,
          }}
        />

        <Box
          mt={2}
        >
          <ActionButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            size="large"
            loading={saving}
          >
            {translate('Tallenna')}
          </ActionButton>
        </Box>

        {(addedCovnoteRows.length > 0) && (
          <ListItem>
            <ListItemText
              disableTypography
              primary={<Typography type="body1" style={{ color: '#333' }}>{translate('Lisätyt')} {addedCovnoteRows.length} {translate('kpl')}</Typography>}
            />
          </ListItem>
        )}
        <List>
          {addedCovnoteRows.map((entry) => (
            <CovnoteRow
              rowId={entry.id}
              covnoteRow={entry}
              prdLines={prdLines}
              destroying={destroying}
              onSave={async (covRow, results) => { await onSave(covRow, results); }}
              onClick={() => {}}
              onDestroy={async (deletedCovnoteRow) => {
                setDestroying(deletedCovnoteRow);
                setAddedCovnoteRows((rows) => rows.filter((row) => row.id !== deletedCovnoteRow.id));
                await onAddedRowDeleted(deletedCovnoteRow);
                setDestroying({});
              }}
            />
          ))}
        </List>

      </DialogContent>
    </form>
  );
};

export default CovnoteRowDialog;
