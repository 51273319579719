import React from 'react';
import { TextField } from '@material-ui/core';

const NumTextField = (props) => {
  const [inputType, setInputType] = React.useState('number');
  React.useEffect(() => {
    const type = localStorage.getItem('numberType') || 'number';
    setInputType(type);
  }, []);

  return (
    <TextField
      type={inputType}
      {...props}
    />
  );
};

export default NumTextField;
