import React from 'react';
import {
  makeStyles, ListItem, Grid, Typography, Box, Button, ListItemSecondaryAction, IconButton, Collapse,
} from '@material-ui/core';
import { useSnackbar } from 'material-ui-snackbar-provider';
import {
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';
import { deletee } from '../../helpers/service';
import TimesheetEntryDialog from '../Dialogs/TimesheetEntryDialog';
import DialogContext from '../../context/DialogContext';
import { getTimesheetEntryStatusDescription } from '../../helpers/timesheetHelpers';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  destroyContainer: {
    background: theme.palette.error.main,
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'flex-end',
    width: '100%',
  },
  destroyButton: {
    color: theme.palette.common.white,
  },
}));

// api/asetukset/whoami
export default function TimesheetEntryRow(props) {
  const {
    id,
    covEcomId,
    CoveringNote,
    Project,
    SubProject,
    SysUserSalaryType,
    hours,
    eventDate,
    eventEndDate,
    eventStart,
    eventEnd,
    isReady,
    isApproved,
    moved,
    memo,
    publicMemo,
    handleDeleted,
    handleRefresh,
  } = props;
  const classes = useStyles();
  const [index, setIndex] = React.useState(0);
  const isEditable = isApproved === 0 && !moved;
  const snackbar = useSnackbar();
  const {
    dialog,
    showDialog,
  } = React.useContext(DialogContext);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { translate } = React.useContext(I18nContext);
  React.useEffect(() => {
    if (openDialog && !dialog.show && handleRefresh) {
      handleRefresh(true);
    }
  }, [openDialog && dialog.show]);
  const [open, setOpen] = React.useState(false);

  const CoveringNoteComponent = () => (
    <>
      {(CoveringNote || covEcomId) && (
        <>
          <Grid item xs={4}>
            <Typography
              component="p"
              variant="caption"
              color="textSecondary"
            >
              {translate('Lähete')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              component="p"
              variant="caption"
              color="textPrimary"
            >
              <b>{((CoveringNote && CoveringNote.ecomId) || covEcomId) || translate('Numeroimaton')} </b>
              {(CoveringNote && CoveringNote.location)}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
  const ProjectComponent = () => (
    <>
      {Project && (
        <>
          <Grid item xs={4}>
            <Typography
              component="p"
              variant="caption"
              color="textSecondary"
            >
              {translate('Projekti')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              component="div"
              variant="caption"
              color="textPrimary"
            >
              <div>
                <b>{Project.id}</b> {Project.name}
              </div>
              {SubProject && (
                <div>
                  <b>» {SubProject.id}</b> {SubProject.name}
                </div>
              )}
            </Typography>
          </Grid>
        </>
      )}
    </>
  );

  const FormattedDateTime = () => {
    const startDateTime = eventDate + (eventStart ? `T${eventStart.padStart(5, 0)}` : '');
    const endDateTime = (eventEndDate || eventDate) + (eventEnd ? `T${eventEnd.padStart(5, 0)}` : '');
    const startFormat = eventStart ? 'dd D.M.YYYY H:mm' : 'dd D.M.YYYY';
    const endFormat = (eventEndDate && eventDate !== eventEndDate) ? 'dd D.M. H:mm' : 'H:mm';

    return (
      <div>
        {!eventEndDate || eventEndDate === eventDate
          ? (
            <div>
              <span>{moment(startDateTime).format(startFormat)}</span>
              {eventEnd && (
                <span> - {moment(endDateTime).format(endFormat)}</span>
              )}
            </div>
          ) : (
            <div>
              <div>{moment(startDateTime).format(startFormat)} - </div>
              <div>{moment(endDateTime).format(endFormat)}</div>
            </div>
          )}
      </div>
    );
  };

  return (
    <SwipeableViews
      index={index}
      disabled={!isEditable}
      onChangeIndex={(idx) => setIndex(idx)}
      containerStyle={{
        justify: 'center',
        alignItems: 'center',
        width: '100%',
      }}
      className={classes.destroyContainer}
    >
      <div>
        <ListItem
          divider
          key={id}
          style={{
            minHeight: 100,
            background: 'white',
          }}
          button
          onClick={() => {
            if (!isEditable) {
              snackbar.showMessage(translate('Palkkatapahtuma ei ole muokattavissa'));
              return;
            }
            let name = CoveringNote && CoveringNote.location;
            if (!name) {
              if (SubProject) {
                name = SubProject.name;
              } else if (Project) {
                name = Project.name;
              } else {
                name = '';
              }
            }
            setOpenDialog(true);
            showDialog({
              component: <TimesheetEntryDialog timerProps={{ ...props, name }} />,
            });
          }}
        >
          <Grid container direction="row">
            <CoveringNoteComponent />
            <ProjectComponent />
            {SysUserSalaryType && (
              <>
                <Grid item xs={4}>
                  <Typography
                    component="p"
                    variant="caption"
                    color="textSecondary"
                  >
                    {translate('Palkkalaji')}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    component="p"
                    variant="caption"
                    color="textPrimary"
                  >
                    <b>{SysUserSalaryType.id} </b>
                    {SysUserSalaryType.name}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <Typography
                component="p"
                variant="caption"
                color="textSecondary"
              >
                {translate('Päivämäärä')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                component="div"
                variant="caption"
                color="textPrimary"
              >
                <FormattedDateTime />
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                component="p"
                variant="caption"
                color="textSecondary"
              >
                {translate('Määrä')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                component="p"
                variant="caption"
                color="textPrimary"
              >
                {hours.replace('.', ',')}
              </Typography>
            </Grid>
            <Grid item xs={4} align="right">
              {getTimesheetEntryStatusDescription(id, isReady, isApproved)}
            </Grid>

            <Collapse in={open} timeout="auto" unmountOnExit>
              <>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    variant="caption"
                    color="textSecondary"
                  >
                    {translate('Sisäinen muistio')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="pre"
                    variant="caption"
                    color="textPrimary"
                  >
                    {memo}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    variant="caption"
                    color="textSecondary"
                  >
                    {translate('Julkinen muistio')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    component="pre"
                    variant="caption"
                    color="textPrimary"
                  >
                    {publicMemo}
                  </Typography>
                </Grid>
              </>
            </Collapse>
          </Grid>

          <ListItemSecondaryAction style={{ top: 30 }}>
            <IconButton edge="end" aria-label="more" onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>

        </ListItem>
      </div>
      <Box
        px={2}
        className={classes.destroyContainer}
      >
        <Grid
          container
          direction="row-reverse"
        >
          <Button
            variant="outlined"
            color="inherit"
            className={classes.destroyButton}
            disabled={loading}
            onClick={async () => {
              try {
                setLoading(true);
                await deletee(`api/palkkatapahtumat/${id}`);
                handleDeleted(true, id);
                snackbar.showMessage('Rivi poistettu');
              } catch (e) {
                setLoading(false);
                snackbar.showMessage(translate('Rivin poistaminen epäonnistui'));
              }
            }}
          >
            {translate('Poista')}
            <DeleteOutlineIcon
              color="inherit"
              fontSize="small"
              style={{
                marginLeft: 6,
              }}
            />
          </Button>
        </Grid>
      </Box>
    </SwipeableViews>
  );
}
