import React, { useContext, useState } from 'react';
import {
  Box, Dialog, DialogContent, DialogTitle, makeStyles, Slide, Typography,
} from '@material-ui/core';
import ActionButton from '../Buttons/ActionButton';
import SwContext from '../../context/SwContext';
import { Store } from '../../App';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const UpdateAvailableDialog = () => {
  // eslint-disable-next-line no-unused-vars
  const { dispatch } = React.useContext(Store);

  const {
    reg,
    newContentAvailable,
  } = useContext(SwContext);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);

  const handleClick = async () => {
    setLoading(true);

    if (reg.waiting && 'postMessage' in reg.waiting) {
      reg.waiting.postMessage('skip-waiting');
    }

    // Allow new sw to be installed
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  if (!newContentAvailable) return null;

  return (
    <Dialog
      open
      TransitionComponent={Transition}
    >
      <DialogTitle
        className={classes.dialogTitle}
      >
        {translate('Uusi päivitys saatavilla')}
      </DialogTitle>

      <DialogContent
        style={{
          paddingTop: 24,
        }}
      >
        <Typography
          component="p"
          variant="body1"
        >
          {translate('Ecom Webistä on uusi päivitys saatavilla')}
        </Typography>

        <Typography
          component="p"
          variant="body2"
          color="textSecondary"
          style={{
            marginTop: 16,
          }}
        >
          {translate('Voit jatkaa Ecom Webin käyttöä päivittämällä sivun alla olevasta painikkeesta')}
        </Typography>

        <Box
          my={4}
        >
          <ActionButton
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={handleClick}
            loading={loading}
          >
            {translate('Päivitä sivu')}
          </ActionButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateAvailableDialog;
