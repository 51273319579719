import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import {
  FormatPaint as FormatPaintIcon,
  Assignment as AssignmentIcon,
  AccessTime as TimesheetIcon,
  Menu as MenuIcon,
  MyLocation as MyLocationIcon,
} from '@material-ui/icons';
import { Store } from '../App';
import { I18nContext } from '../Translations';

const useStyles = makeStyles((theme) => ({
  bottomNavContainer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 999,
  },
  bottomNav: {
    background: theme.palette.primary.main,
  },
  bottomNavAction: {
    color: theme.palette.common.white,
    opacity: 0.5,

    '&$bottomNavActionSelected': {
      opacity: 1,
      color: theme.palette.common.white,
      borderBottom: `4px solid ${theme.palette.common.white}`,
    },
  },
  bottomNavActionSelected: {},
  bottomNavActionDisabled: {
    opacity: 0.25,
  },
}));

const pathnames = ['/covnotes', '/projects', '/timesheet', '/settings', '/salarygrid'];

const BottomNav = ({ location }) => {
  const initialNavIndex = () => {
    const i = pathnames.findIndex((p) => p === location.pathname);
    return i < 0 ? 0 : i;
  };

  const { translate } = useContext(I18nContext);
  const classes = useStyles();
  const [navigationIndex, setNavigationIndex] = useState(initialNavIndex());
  const { state } = useContext(Store);
  const timer = state.timer || {};
  const {
    elapsed: timerElapsed = null,
  } = timer;

  const {
    tracking = false,
  } = state.location || {};

  const customClasses = {
    root: classes.bottomNavAction,
    selected: classes.bottomNavActionSelected,
  };

  const navActions = [
    {
      label: translate('Lähetteet'),
      classes: customClasses,
      icon: <AssignmentIcon />,
      to: pathnames[0],
      component: Link,
    },
    {
      label: translate('Projektit'),
      classes: customClasses,
      icon: <FormatPaintIcon />,
      to: pathnames[1],
      component: Link,
    },
    {
      label: (
        <Badge
          color="secondary"
          badgeContent={timerElapsed}
        >
          {translate('Palkkatap.')}
        </Badge>
      ),
      classes: customClasses,
      style: timerElapsed ? { opacity: 1 } : {},
      icon: <TimesheetIcon />,
      to: pathnames[2],
      component: Link,
    },
    {
      label: (
        <>
          {!tracking && translate('Asetukset')}

          {tracking && (
            <Badge
              color="secondary"
              badgeContent={<MyLocationIcon style={{ fontSize: 16 }} />}
            >
              {translate('Asetukset')}
            </Badge>
          )}
        </>
      ),
      classes: customClasses,
      icon: <MenuIcon />,
      to: pathnames[3],
      component: Link,
    },
  ];

  const isMainPage = pathnames.findIndex((p) => p === location.pathname) >= 0;
  if (!isMainPage) return null;

  return (
    <Paper
      square
      elevation={12}
      className={classes.bottomNavContainer}
    >
      <BottomNavigation
        value={navigationIndex}
        onChange={(event, newValue) => {
          setNavigationIndex(newValue);
        }}
        showLabels
        className={classes.bottomNav}
      >
        {navActions.map((navAction, i) => (
          <BottomNavigationAction
            key={navAction.to || i}
            label={navAction.label}
            classes={navAction.classes}
            icon={navAction.icon}
            component={navAction.component}
            to={navAction.to || null}
            onClick={navAction.onClick}
            disabled={navAction.disabled}
            style={navAction.style}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default withRouter(BottomNav);
