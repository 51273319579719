import React from 'react';
import { OpenInNew } from '@material-ui/icons';
import {
  MenuItem, Box,
} from '@material-ui/core';
import { getWebiUrlWithToken } from '../helpers/authToken';
import { I18nContext } from '../Translations';

const OpenUrlWithTokenLinkMenuItem = ({ label, webiurl, handleClick }) => {
  const [webiUrlWithToken, setWebiUrlWithToken] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { translate } = React.useContext(I18nContext);

  const initToken = async () => {
    setLoading(true);
    const url = await getWebiUrlWithToken(webiurl);
    setWebiUrlWithToken(url);
    setLoading(false);
  };

  return (!webiUrlWithToken
    ? (
      <MenuItem
        disabled={loading}
        target="_blank"
        onClick={initToken}
      >
        {loading ? translate('Odota') : label}
      </MenuItem>
    )
    : (
      <MenuItem
        component="a"
        href={webiUrlWithToken}
        target="_blank"
        onClick={handleClick}
      >
        <Box mr={2}>{translate('Siirry sivulle')}</Box>
        <OpenInNew />
      </MenuItem>
    )
  );
};

export default OpenUrlWithTokenLinkMenuItem;
