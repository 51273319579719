import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BottomDrawerContext from '../context/BottomDrawerContext';
import { I18nContext } from '../Translations';

const useStyles = makeStyles((theme) => ({
  accept: {
    color: theme.palette.primary.main,
  },
  reject: {
    color: theme.palette.error.main,
  },
  actions: {
    textAlign: 'center',
  },
}));

export default function BottomDrawer() {
  const { translate } = useContext(I18nContext);
  const classes = useStyles();
  const {
    bottomDrawer,
    hideBottomDrawer,
  } = useContext(BottomDrawerContext);

  const {
    show,
    onConfirm,
    onCancel,
    confirmLabel,
    DrawerProps,
  } = bottomDrawer;

  const confirm = () => {
    hideBottomDrawer();
    onConfirm();
  };

  const cancel = () => {
    hideBottomDrawer();
    onCancel();
  };

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={show}
        onClose={hideBottomDrawer}
        {...DrawerProps}
      >
        <List>
          <ListItem
            button
            onClick={confirm}
          >
            <ListItemText
              primary={confirmLabel}
              className={clsx(classes.accept, classes.actions)}
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            onClick={cancel}
          >
            <ListItemText
              primary={translate('Kumoa')}
              className={clsx(classes.reject, classes.actions)}
            />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
