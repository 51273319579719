import React, { useContext, useEffect, useState } from 'react';
import {
  TextField, Typography, Box, Grid, Paper, IconButton,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Store } from '../../App';
import { login, get } from '../../helpers/service';
import logo from '../../ecom-nega.svg';
import ActionButton from '../Buttons/ActionButton';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 260,
    width: '100%',
    background: theme.palette.primary.main,
    zIndex: -1,
  },
  container: {
    height: '100%',
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 460,
    margin: '0 auto',
    padding: theme.spacing(6, 2, 2, 2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '100%',
    maxHeight: 32,
  },
  title: {
    color: 'white',
    padding: theme.spacing(1, 0, 4, 0),
  },
}));

const Login = ({ history }) => {
  const snackbar = useSnackbar();
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const [showWebiServer, setShowWebiServer] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translate } = useContext(I18nContext);

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    setLoading(true);

    const {
      webiServer, sysCustomerId, sysUser, password,
    } = ev.target;
    const webiServerValue = webiServer && webiServer.value;
    const sysCustomerIdValue = sysCustomerId.value;
    const sysUserValue = sysUser.value;
    const passwordValue = password.value;
    const userData = await login(sysCustomerIdValue, sysUserValue, passwordValue, webiServerValue);
    if (userData) {
      dispatch({ type: 'LOGIN', payload: userData });
    } else {
      snackbar.showMessage('Kirjautuminen epäonnistui');
    }

    setLoading(false);
  };

  const fetchSalaryTypes = async () => {
    const result = await get('api/palkkatapahtumat/palkkalajit');

    const filtered = result.reduce((acc, salaryType) => {
      if (salaryType.isDefault) {
        acc.default.push(salaryType);
      } else {
        acc.normal.push(salaryType);
      }

      return acc;
    }, { default: [], normal: [] });

    dispatch({
      type: 'SET_SALARYTYPES',
      payload: filtered,
    });
  };

  const init = async () => {
    const result = await get('api/login');
    if (result && result.status === 'OK') {
      fetchSalaryTypes();
      history.push('/covnotes');
    } else {
      dispatch({ type: 'LOGOUT' });
    }
  };

  useEffect(() => {
    if (state.user && Object.keys(state.user).length > 0) {
      init();
    }
  }, [state.user]);

  return (
    <>
      <div className={classes.background} />
      <IconButton onClick={() => setShowWebiServer(!showWebiServer)} style={{ color: 'white' }}><MoreVertIcon /></IconButton>

      <div
        className={classes.content}
      >
        <img
          className={classes.logo}
          alt="Ecom Webi Logo"
          src={logo}
        />

        <Typography
          component="h1"
          variant="subtitle1"
          align="center"
          className={classes.title}
        >
          {translate('Kirjaudu sisään')}
        </Typography>

        <Grid
          container
          justify="center"
          alignItems="center"
        >
          <Paper
            elevation={8}
          >
            <form className={classes.form} onSubmit={handleSubmit}>
              <Box
                py={1}
                px={3}
              >
                {showWebiServer && (
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={translate('Ympäristö')}
                    name="webiServer"
                    defaultValue="www.ecomwebi.fi"
                    required
                  />
                )}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={translate('Asiakasnumero')}
                  name="sysCustomerId"
                  required
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={translate('Käyttäjätunnus')}
                  name="sysUser"
                  required
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={translate('Salasana')}
                  type="password"
                  autoComplete="current-password"
                />
                <ActionButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                  loading={loading}
                >
                  {translate('Kirjaudu')}
                </ActionButton>
              </Box>
            </form>
          </Paper>
        </Grid>
      </div>
    </>
  );
};

export default Login;
