import React, {
  useReducer, createContext, useContext, useEffect,
} from 'react';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import ErrorBoundary from 'react-error-boundary';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { StateInspector } from 'reinspect';
import Login from './Components/Login/Login';
import globalReducer from './reducers/global';
import useTimer from './actions/timer';
import { usePersistedContext, usePersistedReducer } from './usePersist';
import { DialogProvider } from './context/DialogContext';
import BaseDialog from './Components/Dialogs/BaseDialog';
import useDialog from './hooks/useDialog';
import BottomNav from './Components/BottomNav';
// eslint-disable-next-line import/no-named-as-default
import Covnotes from './Components/Covnotes/Covnotes';
// eslint-disable-next-line import/no-named-as-default
import Projects from './Components/Projects/Projects';
import Covnote from './Components/Covnotes/Covnote';
import useAths from './hooks/useAths';
import { AthsProvider } from './context/AthsContext';
import { BottomDrawerProvider } from './context/BottomDrawerContext';
import Settings from './Components/Settings';
import UpdateAvailableDialog from './Components/Dialogs/UpdateAvailableDialog';
import useSw from './hooks/useSw';
import useBottomDrawer from './hooks/useBottomDrawer';
import { SwProvider } from './context/SwContext';
import Timesheet from './Components/Timesheet/Timesheet';
import useDisableBackButton from './hooks/useDisableBackButton';
import Calendar from './Components/Calendar/Calendar';
import BottomDrawer from './Components/BottomDrawer';
import SalaryGrid from './Components/SalaryGrid';

export const Store = createContext({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#27274F',
    },
    secondary: {
      main: '#CEE9F2',
    },
  },
});

const AuthRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(Store);

  const isLoggedIn = () => state.user && Object.keys(state.user).length > 0;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn()) {
          return <Redirect to={{ pathname: '/' }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default function App() {
  const globalStore = usePersistedContext(useContext(Store), 'state');
  const [state, dispatch] = usePersistedReducer(useReducer(globalReducer, globalStore), 'state');

  const timer = useTimer(dispatch);
  const aths = useAths();
  const sw = useSw();
  const bottomDrawer = useBottomDrawer();
  useDisableBackButton();

  useEffect(() => {
    aths.listenToBeforeInstallPrompt();
    sw.listenToNewContentAvailable();
  }, []);

  useEffect(() => {
    state.timer && state.timer.active && timer.init(); // eslint-disable-line
  }, []); // eslint-disable-line

  const ErrorFallback = ({ error }) => (<h1>{error.toString()}</h1>);
  return (
    <StateInspector name="App">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider
              SnackbarProps={{
                style: {
                  top: 100,
                },
                autoHideDuration: 2000,
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                ContentProps: {
                  style: {
                    background: '#CEE9F2',
                    color: '#333',
                  },
                },
              }}
            >
              <Store.Provider value={{ state, dispatch }}>
                <DialogProvider value={useDialog()}>
                  <AthsProvider value={aths}>
                    <SwProvider value={sw}>
                      <BottomDrawerProvider value={bottomDrawer}>
                        <CssBaseline />

                        <BottomNav />

                        <Switch>
                          <Route exact path="/" component={Login} />
                          <AuthRoute exact path="/calendar" component={Calendar} />
                          <AuthRoute exact path="/covnotes" component={Covnotes} />
                          <AuthRoute exact path="/projects" component={Projects} />
                          <AuthRoute exact path="/timesheet" component={Timesheet} />
                          <AuthRoute path="/covnotes/:ecomId" component={Covnote} />
                          <AuthRoute path="/settings" component={Settings} />
                          <AuthRoute path="/salarygrid" component={SalaryGrid} />
                          <AuthRoute path="/test" component={BottomDrawer} />
                        </Switch>

                        <BaseDialog />
                        <UpdateAvailableDialog />
                        <BottomDrawer />

                      </BottomDrawerProvider>
                    </SwProvider>
                  </AthsProvider>
                </DialogProvider>
              </Store.Provider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </StateInspector>
  );
}
