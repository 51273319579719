import React from 'react';
import {
  Box, CircularProgress, Grid, Typography,
} from '@material-ui/core';

const Spinner = ({
  loading,
  label,
  size = 42,
  color = 'primary',
  containerProps,
  labelProps,
  ...rest
}) => {
  if (!loading) return null;

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      {...containerProps}
    >
      <CircularProgress
        size={size}
        color={color}
        {...rest}
      />

      { label && (
        <Box
          mt={3}
          {...labelProps}
        >
          <Typography
            variant="body2"
            color="textSecondary"
          >
            { label }
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default Spinner;
