import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React from 'react';
import { Covnotes } from './Covnotes/Covnotes';
import Main from './Main';

const SalaryGridAddEventCovNote = ({
  covNoteEntries, setCovNoteEntries, handleCloseDialog, salaryTypes,
}) => (
  <div style={{
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
  }}
  >
    <Main
      title="Valitse lähete"
      titleButton={(
        <>
          <IconButton
            size="small"
            style={{ color: 'white' }}
            aria-controls="simple-menu"
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </>
        )}
    >
      <Covnotes
        disableItemSwipe
        onItemClick={(covnote) => {
          if (covNoteEntries.find((entry) => entry.key === covnote.key)) {
            // eslint-disable-next-line no-alert
            return alert('Lähete on jo valittu tuntikortille');
          }
          setCovNoteEntries([
            ...covNoteEntries,
            {
              ...covnote,
              key: `L${covnote.id}`,
              salaryEvents: salaryTypes.reduce((acc, salaryType) => ({ ...acc, [salaryType.salaryTypeId]: null }), {}),
            },
          ]);
          return handleCloseDialog();
        }}
      />
    </Main>
  </div>
);
export default SalaryGridAddEventCovNote;
