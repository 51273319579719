import React, { useContext, useState } from 'react';
import {
  DialogContent, DialogTitle,
  TextField, makeStyles, IconButton, Box, Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import MaskedInput from 'react-text-mask';
import DialogContext from '../../context/DialogContext';
import ActionButton from '../Buttons/ActionButton';
import NumTextField from '../NumTextField';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const MaskedTextField = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
      placeholder="1234567-8"
      placeholderChar={'\u2000'}
    />
  );
};

const CreateCustomerDialog = ({
  onConfirm,
}) => {
  const { hideDialog } = useContext(DialogContext);
  const classes = useStyles();
  const { translate } = useContext(I18nContext);

  const [saving, setSaving] = useState(false);
  const [inputs, setInputValues] = useState({
    name: '',
    contactPerson: '',
    email: '',
    street1: '',
    zip: '',
    city: '',
    phone1: '',
    billingAddressName1: '',
    billingAddressName2: '',
    billingAddressName3: '',
    billingAddressStreet: '',
    billingAddressZip: '',
    billingAddressCity: '',
  });

  const handleInputChange = (name) => (e) => {
    const { value } = e.target;

    setInputValues((prevState) => (
      {
        ...prevState,
        [name]: value,
      }
    ));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setSaving(true);
    await onConfirm(inputs);

    hideDialog();
  };

  return (
    <form
      onSubmit={onSubmit}
    >
      <DialogTitle
        className={classes.dialogTitle}
      >
        <IconButton
          color="inherit"
          style={{
            marginLeft: -4,
            padding: 4,
            marginRight: 8,
          }}
          onClick={hideDialog}
        >
          <CloseIcon />
        </IconButton>

        {translate('Luo uusi asiakas')}
      </DialogTitle>

      <DialogContent
        style={{
          paddingTop: 24,
        }}
      >
        <Typography
          component="h2"
          variant="h6"
        >
          {translate('Asiakkaan tiedot')}
        </Typography>

        <TextField
          variant="outlined"
          label={translate('Nimi')}
          value={inputs.name || ''}
          onChange={handleInputChange('name')}
          margin="normal"
          fullWidth
          required
        />

        <TextField
          variant="outlined"
          label={translate('Y-tunnus')}
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: MaskedTextField,
            value: inputs.companyId || '',
            onChange: handleInputChange('companyId'),
          }}
        />

        <TextField
          variant="outlined"
          label={translate('Yhteyshenkilö')}
          value={inputs.contactPerson || ''}
          onChange={handleInputChange('contactPerson')}
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          label={translate('Sähköposti')}
          value={inputs.email || ''}
          onChange={handleInputChange('email')}
          margin="normal"
          fullWidth
          type="email"
        />

        <TextField
          variant="outlined"
          label={translate('Katuosoite')}
          value={inputs.street1 || ''}
          onChange={handleInputChange('street1')}
          margin="normal"
          fullWidth
        />

        <NumTextField
          variant="outlined"
          label={translate('Postinumero')}
          value={inputs.zip || ''}
          onChange={handleInputChange('zip')}
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          label={translate('Kaupunki')}
          value={inputs.city || ''}
          onChange={handleInputChange('city')}
          margin="normal"
          fullWidth
        />

        <NumTextField
          variant="outlined"
          label={translate('Puhelinnumero')}
          value={inputs.phone1 || ''}
          onChange={handleInputChange('phone1')}
          margin="normal"
          fullWidth
        />

        <Box
          mt={5}
        >
          <Typography
            component="h2"
            variant="h6"
          >
            {translate('Laskutustiedot')}
          </Typography>
        </Box>

        <TextField
          variant="outlined"
          label={translate('Nimi (rivi 1)')}
          value={inputs.billingAddressName1 || ''}
          onChange={handleInputChange('billingAddressName1')}
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          label={translate('Nimi (rivi 2)')}
          value={inputs.billingAddressName2 || ''}
          onChange={handleInputChange('billingAddressName2')}
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          label={translate('Nimi (rivi 3)')}
          value={inputs.billingAddressName3 || ''}
          onChange={handleInputChange('billingAddressName3')}
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          label={translate('Katuosoite')}
          value={inputs.billingAddressStreet || ''}
          onChange={handleInputChange('billingAddressStreet')}
          margin="normal"
          fullWidth
        />

        <NumTextField
          variant="outlined"
          label={translate('Postinumero')}
          value={inputs.billingAddressZip || ''}
          onChange={handleInputChange('billingAddressZip')}
          margin="normal"
          fullWidth
        />

        <TextField
          variant="outlined"
          label={translate('Postitoimipaikka')}
          value={inputs.billingAddressCity || ''}
          onChange={handleInputChange('billingAddressCity')}
          margin="normal"
          fullWidth
        />

        <Box
          my={4}
        >
          <ActionButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            size="large"
            loading={saving}
          >
            {translate('Tallenna')}
          </ActionButton>
        </Box>
      </DialogContent>
    </form>
  );
};

export default CreateCustomerDialog;
