import React from 'react';
import { makeStyles, Badge } from '@material-ui/core';
import { I18nContext } from '../../Translations';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    //    borderBottom: '1px solid #eee',
    display: 'flex',
    fontSize: '.875em',
    padding: '.714286em 1em',
    cursor: 'pointer',
    width: '100%',
  },
  eventTime: {
    width: '3em',
    fontSize: '.714286em',
    lineHeight: '1.6',
    minHeight: '3.2em',
    opacity: '.7',
  },
  eventColor: {
    width: '1.5px',
    margin: '-0.285714em 0.714286em',
    //    backgroundColor: theme.palette.secondary.main,
  },
  eventTxt: {
  },
}));

// api/asetukset/whoami
export default function CalendarItem(props) {
  const item = props;
  const classes = useStyles();
  const { translate } = React.useContext(I18nContext);
  return (
    <div
      role="button"
      className={classes.root}
    >
      <Badge color="primary" variant={item.assigned && 'dot'}>
        <div className={classes.eventTime}>
          {item.startTime && (
            <>
              {item.startTime}
              {item.endTime && (
                <><br /> {item.endTime}</>
              )}
            </>
          )}
        </div>
      </Badge>
      <div
        style={{ backgroundColor: item.color }}
        className={classes.eventColor}
      />
      <div className={classes.eventTxt}>
        <b>{item.location || translate('(ei kohdetta)')}</b><br />
        {item.mark || translate('(ei merkkiä)')}<br />
      </div>
    </div>
  );
}
