import React, { useContext, useEffect, useState } from 'react';
import {
  TextField, MenuItem, Grid, Box, Paper, Typography, IconButton,
} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import MapIcon from '@material-ui/icons/Map';
import { Prompt } from 'react-router-dom';

import { get, post } from '../../../helpers/service';
import TextFieldAutoComplete from '../../TextFieldAutoComplete';
import usePrevious from '../../../hooks/usePrevious';
import DialogContext from '../../../context/DialogContext';
import CreateCustomerDialog from '../../Dialogs/CreateCustomerDialog';
import ActionButton from '../../Buttons/ActionButton';
import { Store } from '../../../App';
import NumTextField from '../../NumTextField';
import { I18nContext } from '../../../Translations';

const lowerCaseFirstLetters = (arr) => {
  const result = arr.reduce((acc, item) => {
    const processedItem = {};

    /**
     * API returns object keys with uppercase first letter. On the other hand,
     * covnote's Project and SubProject objects have lowercase first letter. This fix here
     * makes the first letters lowercase, which makes it easier to handle values elsewhere.
     */
    Object.keys(item).forEach((key) => {
      const lowercaseKey = key.replace(/^\w/, (c) => c.toLowerCase());
      processedItem[lowercaseKey] = item[key];
    });

    acc.push(processedItem);

    return acc;
  }, []);

  return result;
};

export default function CovnoteDetails({ covnote, onSave, onSetHasUnsavedChanges, onLeavingCancelled }) {
  const {
    showDialog,
  } = useContext(DialogContext);
  const { state } = useContext(Store);
  const { translate } = useContext(I18nContext);

  /* ======= STATES ======= */
  const [covnoteStates, setCovnoteStates] = useState([]);
  const [projects, setProjects] = useState([]);
  const [subProjects, setSubProjects] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [persons, setPersons] = useState([]);
  const [saving, setSaving] = useState(false);
  const [inputs, setInputValues] = useState({
    ecomId: '',
    location: '',
    mark: '',
    state: '',
    wantedDate: '',
    wantedTime: '',
    wantedTimeFormatted: null,
    description: '',
    privateMemo: '',
    Project: null,
    SubProject: null,
    Customer: null,
    CovStatus: {
      name: '',
    },
    OwnerPerson: null,
    SupervisorPerson: null,
    ...covnote,
  });

  const hasUnsavedChanges = (Object.entries(covnote).some(([key, value]) => value !== inputs[key]));
  if (onSetHasUnsavedChanges) {
    onSetHasUnsavedChanges(hasUnsavedChanges);
  }

  const prevProject = usePrevious(inputs.Project);
  /* ======= STATES END ======= */

  /* ======= HANDLERS ======= */
  const handleInputChange = (name) => (e) => {
    const { value } = e.target;

    setInputValues((prevState) => (
      {
        ...prevState,
        [name]: value,
      }
    ));
  };

  // eslint-disable-next-line no-unused-vars
  const handleNestedInputChange = (key, name) => (e) => {
    const { value } = e.target;

    setInputValues((prevState) => (
      {
        ...prevState,
        [name]: value,
      }
    ));
  };

  const handleProjectChange = (selectedOption, { action }) => {
    const payload = {
      target: {
        value: null,
      },
    };

    if (action === 'clear') {
      handleInputChange('Project')(payload);

      return;
    }

    const {
      id: projectId,
    } = selectedOption;

    const project = projects.find((p) => p.id === projectId);
    payload.target.value = project;

    handleInputChange('Project')(payload);
    handleInputChange('projectId')({ target: { value: project.id } });
  };

  const changePerson = (objectFieldName, idFieldName, selectedOption, { action }) => {
    const payload = {
      target: {
        value: null,
      },
    };

    if (action === 'clear') {
      handleInputChange(objectFieldName)(payload);
      handleInputChange(idFieldName)({ target: { value: null } });

      return;
    }

    const {
      id: personId,
    } = selectedOption;

    const person = persons.find((p) => p.id === personId);
    payload.target.value = person;

    handleInputChange(objectFieldName)(payload);
    handleInputChange(idFieldName)({ target: { value: person.id } });
  };

  const handleOwnerPersonChange = (selectedOption, { action }) => {
    changePerson('OwnerPerson', 'ownerPersonId', selectedOption, { action });
  };

  const handleSupervisorPersonChange = (selectedOption, { action }) => {
    changePerson('SupervisorPerson', 'supervisorPersonId', selectedOption, { action });
  };

  const handleCustomerChange = (selectedOption, { action }) => {
    const payload = {
      target: {
        value: null,
      },
    };

    if (action === 'clear') {
      handleInputChange('Customer')(payload);

      return;
    }

    if (action === 'create-option') {
      const handleCustomerSave = async (params) => {
        const createdCustomer = await post('api/asiakkaat', params);

        payload.target.value = createdCustomer;

        setCustomers((prevCustomers) => [...prevCustomers, createdCustomer]);

        handleInputChange('Customer')(payload);
        handleInputChange('customerId')({ target: { value: createdCustomer.id } });
      };

      showDialog({
        component: (
          <CreateCustomerDialog
            onConfirm={handleCustomerSave}
          />
        ),
      });

      return;
    }

    const {
      id: customerId,
    } = selectedOption;

    const customer = customers.find((c) => c.id === customerId);
    payload.target.value = customer;

    handleInputChange('Customer')(payload);
    handleInputChange('customerId')({ target: { value: customer.id } });
  };
  /* ======= HANDLERS END ======= */

  /* ======= FETCH ======= */
  const fetchCovnoteStates = async () => {
    const result = await get('api/lahetteet/tilat');
    if (result.sort) {
      result.sort((a, b) => a.sortId - b.sortId);
      setCovnoteStates(result);
    }
  };

  const fetchProjects = async () => {
    const response = await get('projektit');
    const processedProjects = lowerCaseFirstLetters(Object.values(response));

    setProjects(processedProjects);
  };

  const fetchCustomers = async () => {
    const response = await get('api/asiakkaat');
    setCustomers(response);
  };

  const fetchPersons = async () => {
    const response = await get('api/henkilot');
    setPersons(response);
  };
  /* ======= FETCH ENDS ======= */

  /* ======= EFFECTS ======= */
  useEffect(() => {
    if (Object.keys(covnote).length > 0) {
      setInputValues(covnote);
    }
  }, [covnote]);

  useEffect(() => {
    fetchCovnoteStates();
    fetchProjects();
    fetchCustomers();
    fetchPersons();
  }, []);

  useEffect(() => {
    if (inputs.Project && (!prevProject || inputs.Project.id !== prevProject.id)) {
      const fetchSubProjects = async () => {
        const response = await get(`projektit/${inputs.Project.id}/alaprojektit`);
        const processedSubProjects = lowerCaseFirstLetters(Object.values(response.subprojects));

        setSubProjects(processedSubProjects);
      };

      fetchSubProjects();
    } else {
      /**
       * If Project is cleared, we need to clear the SubProject too.
       */

      const payload = {
        target: {
          value: null,
        },
      };

      handleInputChange('SubProject')(payload);
      handleInputChange('subProjectId')(payload);
    }
  }, [inputs.Project]); // eslint-disable-line
  /* ======= EFFECTS END ======= */

  const getSelectedProjectFormatted = (key) => {
    if (!inputs[key]) return null;

    const {
      id,
      name,
    } = inputs[key];

    if (!id) return null;

    const r = {
      id,
      value: id,
      label: `${id} - ${name}`,
    };

    return r;
  };

  const getSelectedPersonFormatted = (key) => {
    if (!inputs[key]) return null;
    const { id, name } = inputs[key];
    if (!id) return null;
    const r = {
      id,
      value: id,
      label: name,
    };
    return r;
  };

  let subProjectSelectionComponent = null;

  if (inputs.Project) {
    const handleSubProjectChange = (selectedOption, { action }) => {
      const payload = {
        target: {
          value: null,
        },
      };

      if (action !== 'clear') {
        const {
          value: subProjectId,
        } = selectedOption;

        const subProject = subProjects.find((p) => p.id === subProjectId);
        payload.target.value = subProject;
      }

      handleInputChange('SubProject')(payload);
      handleInputChange('subProjectId')({ target: { value: payload.target.value.id } });
    };

    subProjectSelectionComponent = (
      <Box mt={2} ml={3}>
        <TextFieldAutoComplete
          options={subProjects.reduce((acc, p) => {
            acc.push({
              value: p.id,
              label: `${p.id} - ${p.name}`,
            });

            return acc;
          }, [])}
          label={translate('Alaprojekti')}
          placeholder={subProjects.length > 0 ? translate('Hae alaprojektia') : translate('Alaprojekteja ei löytynyt')}
          noOptionsMessage={translate('Ei alaprojekteja')}
          isDisabled={subProjects.length <= 0}
          onChange={handleSubProjectChange}
          value={getSelectedProjectFormatted('SubProject')}
        />
      </Box>
    );
  }

  let customerComponent = null;

  if (inputs.Customer) {
    const {
      name,
      street1,
      zip,
      city,
      phone1,
    } = inputs.Customer;

    customerComponent = (
      <Box ml={3}>
        <Paper elevation={2}>
          <Box p={2} mt={2}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h6">
                  {name}
                </Typography>

                <Typography variant="subtitle2">
                  {street1}
                </Typography>

                <Typography variant="subtitle2">
                  {`${zip} ${city}`}
                </Typography>

                <Typography variant="subtitle2">
                  {phone1}
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography align="right">
                  <IconButton
                    disabled={!phone1}
                    href={`tel:${phone1}`}
                  >
                    <CallIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    disabled={!street1}
                    href={`http://maps.google.com/?q=${street1}+${zip}+${city}`}
                  >
                    <MapIcon fontSize="small" />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    );
  }

  const saveButtonComponent = (
    <Box>
      <ActionButton
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        fullWidth
        loading={saving}
        disabled={inputs.ecomId && !hasUnsavedChanges}
      >
        {translate('Tallenna')}
      </ActionButton>
    </Box>
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    setSaving(true);
    await onSave(inputs);
    setSaving(false);
  };

  // eslint-disable-next-line no-unused-vars
  const confirmNavigationAway = (_location, _action) => {
    // todo: replace with something better than confirm()
    if (window.location && (window.location.pathname === _location.pathname)) {
      return true;
    }
    if (!window.confirm(translate('Lähetteellä on tallentamattomia muutoksia. Haluatko varmasti poistua?')) && (onLeavingCancelled)) {
      onLeavingCancelled();
      return false;
    }
    return true;
  };

  return (
    <Box
      p={2}
    >
      <Prompt
        when={hasUnsavedChanges}
        message={confirmNavigationAway}
      />
      <form
        onSubmit={onSubmit}
      >
        <Box display="flex" flexDirection="row" flexWrap="nowrap" mb={1}>
          <Box flexGrow={1}>
            <Typography
              component="h2"
              variant="caption"
              style={{
                color: '#757575',
                fontweight: 'bold',
              }}
            >
              {translate('Lähetenumero')}
            </Typography>

            <Typography component="p" variant="body1">
              {inputs.ecomId || 'Numeroimaton'}
              {inputs.externalOriginSystem && ` [${inputs.externalOriginSystem.toUpperCase()} ${inputs.externalOriginId}]`}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row" flexWrap="nowrap">
            { saveButtonComponent }
          </Box>
        </Box>

        <TextField
          label={translate('Kohde')}
          value={inputs.location || ''}
          onChange={handleInputChange('location')}
          margin="normal"
          variant="outlined"
          fullWidth
          inputProps={{
            maxLength: 40,
          }}
          multiline
        />

        <TextField
          label={translate('Merkki')}
          value={inputs.mark || ''}
          onChange={handleInputChange('mark')}
          margin="normal"
          variant="outlined"
          fullWidth
          inputProps={{
            maxLength: 40,
          }}
          multiline
        />

        <TextField
          select
          label={translate('Tila')}
          value={inputs.statusId || ''}
          onChange={handleInputChange('statusId')}
          margin="normal"
          variant="outlined"
          fullWidth
        >
          {covnoteStates.map((s) => (
            <MenuItem key={s.id} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </TextField>

        <Box mt={2}>
          <TextFieldAutoComplete
            options={persons.map((p) => ({ id: p.id, value: p.id, label: p.name }))}
            label={translate('Työntekijä')}
            placeholder={translate('Hae työntekijää')}
            noOptionsMessage={translate('Ei henkilöitä')}
            isClearable
            onChange={handleOwnerPersonChange}
            value={getSelectedPersonFormatted('OwnerPerson')}
          />
        </Box>

        <Box mt={2} mb={1}>
          <TextFieldAutoComplete
            options={persons.filter((p) => (p.isSupervisor)).map((p) => ({ id: p.id, value: p.id, label: p.name }))}
            label={translate('Työnjohtaja')}
            placeholder={translate('Hae työnjohtajaa')}
            noOptionsMessage={translate('Ei henkilöitä')}
            isClearable
            onChange={handleSupervisorPersonChange}
            value={getSelectedPersonFormatted('SupervisorPerson')}
          />
        </Box>

        <Box mt={2} mb={1}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label={translate('Toimituspäivä')}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputs.wantedDate || ''}
                onChange={handleInputChange('wantedDate')}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label={translate('Toimitusaika')}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputs.wantedTime || ''}
                onChange={handleInputChange('wantedTime')}
              />
            </Grid>

            <Grid item xs={4}>
              <NumTextField
                fullWidth
                variant="outlined"
                label={translate('Kesto (h)')}
                inputProps={{ step: '0.1' }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={inputs.estimatedDuration || ''}
                onChange={handleInputChange('estimatedDuration')}
              />
            </Grid>
          </Grid>
        </Box>

        <TextField
          label={translate('Vapaa teksti (asiakkaalle)')}
          value={inputs.description || ''}
          onChange={handleInputChange('description')}
          margin="normal"
          variant="outlined"
          fullWidth
          multiline
          disabled={!state.user.permissions.cr_covnote_description}
        />

        <TextField
          label={translate('Vapaa teksti (omaan käyttöön)')}
          value={inputs.privateMemo || ''}
          onChange={handleInputChange('privateMemo')}
          margin="normal"
          variant="outlined"
          fullWidth
          multiline
        />

        <Box mt={3}>
          <TextFieldAutoComplete
            options={projects.reduce((acc, p) => {
              acc.push({
                id: p.id,
                value: p.id,
                label: `${p.id} - ${p.name}`,
              });

              return acc;
            }, [])}
            label={translate('Projekti')}
            placeholder={translate('Hae projektia')}
            noOptionsMessage={translate('Ei projekteja')}
            isClearable
            onChange={handleProjectChange}
            value={getSelectedProjectFormatted('Project')}
          />
        </Box>

        {subProjectSelectionComponent}

        <Box mt={4}>
          <TextFieldAutoComplete
            options={customers.reduce((acc, c) => {
              acc.push({
                id: c.id,
                value: c.id,
                label: `${c.id} - ${c.name}`,
              });

              return acc;
            }, [])}
            label={translate('Asiakas')}
            placeholder={translate('Hae asiakas')}
            noOptionsMessage={translate('Ei asiakkaita')}
            isClearable
            creatable
            createLabel={translate('+ Luo uusi asiakas')}
            onChange={handleCustomerChange}
            value={getSelectedProjectFormatted('Customer')}
            getNewOptionData={(inputValue, optionLabel) => ({
              value: inputValue,
              label: optionLabel,
              __isNew__: true,
              isEqual: () => false,
            })}
          />
        </Box>

        {customerComponent}

        <Box mt={4}>
          {saveButtonComponent}
        </Box>
      </form>
    </Box>
  );
}
