import React, { useContext } from 'react';
import {
  Fab,
} from '@material-ui/core';
import { AccessTime as TimesheetIcon } from '@material-ui/icons';
import TimesheetEntryDialog from '../Dialogs/TimesheetEntryDialog';
import DialogContext from '../../context/DialogContext';

const TimesheetEntryButton = ({ validator = (callback) => callback(), timerProps, ...rest }) => {
  const {
    showDialog,
  } = useContext(DialogContext);

  const handleClick = () => {
    showDialog({
      component: <TimesheetEntryDialog timerProps={timerProps} />,
    });
  };

  return (
    <Fab
      color="primary"
      size="medium"
      onClick={() => validator(handleClick)}
      {...rest}
    >
      <TimesheetIcon />
    </Fab>
  );
};

export default TimesheetEntryButton;
