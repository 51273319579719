import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import {
  Dialog, Slide,
} from '@material-ui/core';
import DialogContext from '../../context/DialogContext';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function BaseDialog() {
  const {
    dialog,
  } = useContext(DialogContext);

  return ReactDOM.createPortal(
    <Dialog
      open={dialog.show}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          overflowX: 'hidden',
        },
      }}
    >
      { dialog.component }
    </Dialog>,
    document.body,
  );
}
