import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  makeStyles, MenuItem, Paper, TextField, Typography, useTheme,
} from '@material-ui/core';

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          style: {
            padding: '10px 6px',
          },
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Menu({ selectProps, innerProps, children }) {
  return (
    <Paper
      square
      className={selectProps.classes.paper}
      {...innerProps}
      style={{
        zIndex: 999,
      }}
    >
      { children }
    </Paper>
  );
}

function NoOptionsMessage({ selectProps, innerProps, children }) {
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      { children }
    </Typography>
  );
}

function Option({
  innerRef, isFocused, isSelected, innerProps, children,
}) {
  const theme = useTheme();
  const styles = {};

  if (isSelected) {
    styles.background = theme.palette.primary.main;
    styles.color = 'white';
  }

  return (
    <MenuItem
      ref={innerRef}
      selected={isFocused}
      component="div"
      style={styles}
      {...innerProps}
    >
      { children }
    </MenuItem>
  );
}

function SingleValue({ selectProps, innerProps, children }) {
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      { children }
    </Typography>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  SingleValue,
};

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

const getCreateLabel = (createLabel) => (
  <Typography
    display="block"
    style={{
      borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
      width: '100%',
      padding: '18px 0 18px 12px',
      marginBottom: -4,
      fontStyle: 'italic',
      textTransform: 'uppercase',
    }}
    variant="caption"
  >
    { createLabel }
  </Typography>
);

export default function TextFieldAutoComplete({
  label, noOptionsMessage, creatable, createLabel, ...rest
}) {
  const classes = useStyles();

  let props = {
    classes,
    TextFieldProps: {
      label,
      InputLabelProps: {
        shrink: true,
      },
    },
    components,
    noOptionsMessage: () => noOptionsMessage,
    ...rest,
  };

  if (creatable) {
    props = {
      ...props,
      formatCreateLabel: () => getCreateLabel(createLabel),
      isValidNewOption: () => true,
      createOptionPosition: 'first',
    };

    return (
      <CreatableSelect
        {...props}
      />
    );
  }

  return (
    <Select
      {...props}
    />
  );
}
