import React, { useEffect, useState } from 'react';

import {
  MenuItem,
  Divider,
  makeStyles, Typography,
} from '@material-ui/core';
import { I18nContext } from '../Translations';

const useStyles = makeStyles(() => ({
  select: {
    width: 45,
    height: 25,
    fontSize: 15,
  },
}));

// eslint-disable-next-line no-unused-vars
const CameraSelectMenuItems = (props) => {
  const {
    onClose,
    onSetDevice,
    mediaDevicesReady,
  } = props;

  const classes = useStyles();
  const { translate } = React.useContext(I18nContext);
  const [videoDevices, setVideoDevices] = useState([]);

  const getDevices = async () => {
    // At least on iOS and Android it seems that mediaDevices cannot be succesfully enumerated,
    // until at least one media device has been succesfully initialized/opened with getUserMedia(),
    // and the user has given permissions etc. If we try to enumerateDevices() too early, the
    // returned device array can be empty, or some of the information may be missing.
    if (!mediaDevicesReady) {
      setVideoDevices([{ label: translate('(Videolaitteita avataan/vaihdetaan...)'), deviceId: null }]);
    } else if (navigator.mediaDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const vidDevs = devices.filter((device) => device.kind.startsWith('videoinput'));
      const labeledVidDevs = [];
      if (vidDevs.length > 0) {
        vidDevs.forEach((dev, index) => {
          labeledVidDevs.push({
            deviceId: dev.deviceId,
            label: (dev.label && dev.label.length) ? dev.label : `${translate('Kamera')} ${(index + 1)}`,
          });
        });
        setVideoDevices(labeledVidDevs);
      } else {
        setVideoDevices([{ label: translate('Videolaitteita ei löytynyt'), deviceId: null }]);
      }
    } else {
      setVideoDevices([{ label: translate('mediaDevices-rajapintaa ei ole saatavilla'), deviceId: null }]);
    }
  };

  useEffect(() => {
    getDevices();
  }, [mediaDevicesReady]);

  return (
    <>
      <MenuItem
        onClick={() => { onClose(); }}
      >
        <Typography>
          { translate('Valitse kamera') }
        </Typography>
      </MenuItem>
      <Divider />
      {videoDevices.map((device) => (
        <MenuItem
          onClick={() => {
            if ((device.deviceId) && (onSetDevice)) {
              onSetDevice(device);
            }
            onClose();
          }}
        >
          {device.label}
        </MenuItem>
      ))}
    </>
  );
};

export default CameraSelectMenuItems;
