import React, { useReducer } from 'react';

import FI from './fi.json';
import SV from './sv.json';

const translations = {
  FI,
  SV,
};

const getTranslate = (langCode) => (key) => translations[langCode][key] || key;

const langCode = localStorage.getItem('lang') || 'FI';

const initialState = {
  langCode,
  translate: getTranslate(langCode),
};

export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setLanguage':
        localStorage.setItem('lang', action.payload);
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        };
      default:
        return { ...initialState };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};
