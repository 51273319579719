import React, { useEffect, useContext, useState } from 'react';
import {
  Box, ListItem, ListItemText, Grid, makeStyles, Typography, Button, CircularProgress,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import {
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import { I18nContext } from '../../../Translations';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchInput: {
    flex: 1,
  },
  destroyContainer: {
    background: theme.palette.error.main,
    height: 80,
  },
  destroyButton: {
    color: theme.palette.common.white,
  },
}));

export default function CovnoteRow({
  rowId, covnoteRow, destroying, onDestroy, onClick,
}) {
  const { translate } = useContext(I18nContext);
  const classes = useStyles();

  const getPrice = ({ unitPrice, amount }) => {
    if (unitPrice > 0) {
      const total = unitPrice * amount;
      return (
        <div style={{ textAlign: 'right' }}>
          {unitPrice.toFixed(2)} &euro;/á
          <hr style={{ margin: 0 }} />
          {total.toFixed(2)} &euro;
        </div>
      );
    }
    return null;
  };

  return (
    <div
      key={rowId}
      style={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      }}
    >
      <SwipeableViews>
        <ListItem
          button
          key={rowId}
          onClick={() => onClick(covnoteRow)}
          style={{
            height: '100%',
          }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={9}>
              <ListItemText
                primary={covnoteRow.prdId}
                secondary={(
                  <>
                    {covnoteRow.name}
                  </>
                )}
                secondaryTypographyProps={{
                  variant: 'caption',
                  display: 'block',
                  style: {
                    fontSize: '11px',
                  },
                }}
                style={{
                  margin: 0,
                }}
              />
            </Grid>

            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
              }}
            >
              { destroying.id === covnoteRow.id && (
                <CircularProgress size={24} />
              )}

              { destroying.id !== covnoteRow.id && (
                <>
                  <Typography align="right">
                    { `${covnoteRow.amount || '0'} ${covnoteRow.unit || 'yks'}` }
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      style: {
                        fontSize: '11px',
                      },
                    }}
                    variant="body2"
                  >
                    {getPrice(covnoteRow)}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </ListItem>

        <Box
          px={2}
          className={classes.destroyContainer}
        >
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{
              height: '100%',
            }}
          >
            { destroying.id === covnoteRow.id && (
              <><CircularProgress size={24} /><Box px={2} /></>
            )}

            <Button
              variant="outlined"
              color="inherit"
              className={classes.destroyButton}
              onClick={() => onDestroy(covnoteRow)}
            >
              {translate('Poista')}
              <DeleteOutlineIcon
                color="inherit"
                fontSize="small"
                style={{
                  marginLeft: 6,
                }}
              />
            </Button>
          </Grid>
        </Box>
      </SwipeableViews>
    </div>
  );
}
