import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl, FormControlLabel, Switch, Paper, InputBase, IconButton, Divider, Collapse, Box, Button,
} from '@material-ui/core';
import { FilterList as FilterListIcon, Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import { I18nContext } from '../../Translations';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function ProjectSearch({ searchState }) {
  const classes = useStyles();
  const [openFilters, setOpenFilters] = useState(false);
  const [searchValues, setSearchValues] = searchState;
  const [text, setText] = useState('');
  const textRef = React.useRef();
  const { translate } = React.useContext(I18nContext);

  const placeholder = searchValues.isSubProject ? translate('Hae alaprojekteja') : translate('Hae projekteja');

  return (
    <Box px={1} pt={1} pb={1}>
      <Paper>
        <div className={classes.filterContainer}>
          <form
            style={{ width: '100%' }}
            onSubmit={(ev) => {
              ev.preventDefault();
              setSearchValues({
                ...searchValues,
                text,
                currentPage: 0,
              });
            }}
          >
            <InputBase
              inputRef={textRef}
              value={text}
              className={classes.searchInput}
              onChange={(ev) => setText(ev.target.value)}
              placeholder={placeholder}
            />
          </form>
          <IconButton onClick={() => {
            setText('');
            textRef.current.focus();
          }}
          >
            {text.length ? (<CloseIcon />) : (<SearchIcon />)}
          </IconButton>
          <Divider
            className={classes.divider}
            orientation="vertical"
          />
          <IconButton color="primary" onClick={() => setOpenFilters(!openFilters)}>
            <FilterListIcon />
          </IconButton>
        </div>

        <Collapse
          in={openFilters}
          timeout="auto"
          unmountOnExit
        >
          <Divider />

          <Box
            p={2}
          >
            <FormControl
              fullWidth
              margin="dense"
            >
              <FormControlLabel
                control={(
                  <Switch
                    checked={searchValues.isSubProject}
                    onChange={(ev) => setSearchValues({
                      ...searchValues,
                      isSubProject: ev.target.checked,
                    })}
                  />
                )}
                label={translate('Hae alaprojekteja')}
              />

            </FormControl>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setText('');
                textRef.current.focus();
                setSearchValues({
                  text: '',
                  currentPage: 0,
                  isSubProject: false,
                });
              }}
            >
              {translate('Palauta')}
            </Button>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
}
