/* eslint-disable no-use-before-define */
import React from 'react';
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  TextField,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import moment from 'moment';
import { useSalaryGridFiltersContext } from '../reducers/SalaryGridFilters';
import Main from './Main';
import { I18nContext } from '../Translations';

const useStyles = makeStyles((theme) => ({
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  saveButton: {
    position: 'sticky',
    left: 0,
    bottom: 0,
  },
}));

const SalaryGridInit = ({
  setOpenGrid,
  setWantedDate,
}) => {
  const classes = useStyles();
  const [, dispatchSalaryGridFilters] = useSalaryGridFiltersContext();
  const { translate } = React.useContext(I18nContext);

  const filterByDate = () => {
    dispatchSalaryGridFilters({
      type: 'SET_SALARYGRID_EDIT_PARAMS',
      payload: {
        'filter[wanteddate][min]': date,
        'filter[wanteddate][max]': moment(date).add(1, 'days').format('YYYY-MM-DD'),
      },
    });
  };

  const today = moment().format('YYYY-MM-DD');
  const [date, setDate] = React.useState(today);
  const [checked, setChecked] = React.useState(true);


  return (
    <Main title={translate('Uusi tuntikortti')}>
      <Box
        p={2}
      >
        <TextField
          fullWidth
          label={translate('Palkkatapahtumien päivämäärä')}
          margin="dense"
          type="date"
          size="medium"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              input: classes.dateInput,
            },
          }}
          onChange={({ target }) => setDate(target.value)}
          defaultValue={today}
        />

        <FormControlLabel
          control={(
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              name="check"
              color="primary"
            />
          )}
          label={(
            <Typography
              component="p"
              variant="subtitle1"
              color="inherit"
            >
              {translate('Alusta tuntikortti omilla lähetteillä, joissa on sama toimituspäivä')}
            </Typography>
          )}
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            setOpenGrid(false);
            setWantedDate(date);
            if (checked) {
              filterByDate();
            }
          }}
        >
          {translate('Aloita')}
        </Button>
      </Box>
    </Main>
  );
};

/* export default function SalaryGridProvider() {
  return (
    <SalaryGridFiltersContextProvider>
      <SalaryGridInit />
    </SalaryGridFiltersContextProvider>
  );
} */
export default SalaryGridInit;
