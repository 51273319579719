import React, {
  useEffect, useContext,
} from 'react';
import {
  ListItem, Fab, Grid, Typography, Chip, Box,
} from '@material-ui/core';
import LocationIcon from '@material-ui/icons/House';
import TimerIcon from '@material-ui/icons/Timer';
import FlareIcon from '@material-ui/icons/Flare';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';
import { useHistory } from 'react-router-dom';
import useTimer from '../../actions/timer';
import { Store } from '../../App';
import TimesheetEntryButton from '../Buttons/TimesheetEntryButton';
import { I18nContext } from '../../Translations';

// api/asetukset/whoami
export default function CovnoteItem({ covnote, onClick, disableSwipe = false }) {
  const history = useHistory();
  const covnoteId = covnote.ecomId ? covnote.ecomId : `;${covnote.id}`;
  const { state, dispatch } = useContext(Store);
  const timer = useTimer(dispatch);
  const { translate } = useContext(I18nContext);

  const [index, setIndex] = React.useState(0);
  const startTimer = () => {
    timer.start({
      covId: covnote.id,
      covEcomId: covnote.ecomId,
      name: covnote.location,
    });
  };

  const getAssigneeStyle = () => ({
    fontSize: 10,
    height: 20,
  });

  const getPriorityStyle = () => {
    const color = (covnote.CovPriority
      && covnote.CovPriority.PrioritySettings
      && covnote.CovPriority.PrioritySettings.color)
      || 'black';
    return {
      backgroundColor: color,
      color: 'white',
      fontSize: 10,
      height: 20,
      marginLeft: 10,
    };
  };

  useEffect(() => {
    if (state.timer.active) {
      setIndex(0);
    }
  }, [state.timer.active]);

  const FormattedDateTime = () => {
    const wantedDate = covnote.wantedDate ? moment(covnote.wantedDate).format('D.M.YYYY') : '';
    const time = covnote.wantedTime ? moment(covnote.wantedTime, 'HH:mm').format('H:mm') : '';

    if (wantedDate) {
      return (<div><TimerIcon style={{ height: 10 }} /><span>{wantedDate} {time}</span></div>);
    }
    const covDate = covnote.covDate ? moment(covnote.covDate).format('D.M.YYYY') : '';
    if (covDate) {
      return (<div><FlareIcon style={{ height: 10 }} /><span>{covDate}</span></div>);
    }
    return '';
  };

  const defaultOnClick = () => {
    if (covnote.isWebiLocked) {
      history.push({
        pathname: `/covnotes/${covnoteId}/tiedot`,
      });
    }
  };

  const addCovRowsClick = () => {
    if (covnote.isWebiLocked) {
      history.push({
        pathname: `/covnotes/${covnoteId}/rivit/uusi`,
      });
    }
  };

  return (
    <SwipeableViews
      index={index}
      disabled={disableSwipe}
      onChangeIndex={(idx) => setIndex(idx)}
      containerStyle={{ justify: 'center', alignItems: 'center' }}
      style={{ background: '#CEE9F2' }}
    >
      <ListItem
        divider
        key={covnote.id}
        style={{ minHeight: 100, background: !covnote.isWebiLocked ? 'rgb(206, 233, 242)' : 'white' }}
        button
        onClick={() => (onClick ? onClick(covnote) : defaultOnClick())}
      >
        <Grid
          container
          direction="row"
        >
          <Grid item xs={6}>
            <div style={{ marginRight: 10 }}>
              {covnote.ecomId ? `${covnote.ecomId} ` : translate('Numeroimaton')}
              {covnote.externalOriginSystem && `[${covnote.externalOriginSystem.toUpperCase()}${'\u00A0'}${covnote.externalOriginId}]`}
            </div>
            {covnote.location && (
              <div style={{ marginRight: 10, fontWeight: 'bold' }}>
                <LocationIcon style={{ fontSize: 10 }} /> {covnote.location}
              </div>
            )}
          </Grid>

          <Grid item xs={6}>
            <Grid container justify="flex-end">
              {covnote.CovStatus && (
                <Chip color="primary" size="small" label={covnote.CovStatus.name} style={{ fontSize: 10, height: 20 }} />
              )}
              {covnote.CovPriority && (
                <Chip style={getPriorityStyle()} size="small" label={covnote.CovPriority.name} />
              )}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption" color="textSecondary">
              {covnote.mark}
            </Typography>
          </Grid>

          <Grid item xs={6} style={{ marginTop: 6 }}>
            <Typography align="right" variant="caption" display="block" color="textSecondary">
              <FormattedDateTime />
            </Typography>
          </Grid>

          <Grid item xs={6} style={{ marginTop: 6 }}>
            <Typography style={{ fontWeight: 'bold', fontSize: 14 }} color="textSecondary">
              {covnote.Customer && covnote.Customer.name}
            </Typography>
          </Grid>

          <Grid item xs={6} style={{ marginTop: 6, display: 'flex', justifyContent: 'flex-end' }}>
            {!!covnote.ownerPersonId
              && !!covnote.OwnerPerson
              && (
                <Chip
                  style={getAssigneeStyle()}
                  size="small"
                  color={(covnote.ownerPersonId === state.user.sysuser_person_id) ? 'secondary' : 'info'}
                  label={covnote.OwnerPerson.name}
                  mr={{ mr: 21 }}
                />
              )}

            {!!covnote.supervisorPersonId
              && !!covnote.SupervisorPerson
              && (
                <Chip
                  style={getAssigneeStyle()}
                  size="small"
                  color={(covnote.supervisorPersonId === state.user.sysuser_person_id) ? 'secondary' : 'info'}
                  label={covnote.SupervisorPerson.name}
                />
              )}

            {!covnote.supervisorPersonId && (<Box sx={{ mr: 51 }} />)}

          </Grid>
        </Grid>
      </ListItem>
      <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '12px 16px 12px 16px' }}>
        <div style={{ marginRight: 24, textAlign: 'center' }}>
          <TimesheetEntryButton
            timerProps={{
              covId: covnote.id,
              covEcomId: covnote.ecomId,
              name: covnote.location,
            }}
          />
          <br />
          <div style={{ color: '#333', fontSize: 12, marginTop: 10 }}>{translate('Palkkatapahtuma')}</div>
        </div>

        <div style={{ marginRight: 24, textAlign: 'center' }}>
          <Fab
            disabled={state.timer.active}
            color="primary"
            onClick={startTimer}
            size="medium"
          >
            <TimerIcon />
          </Fab>
          <br />
          <div style={{ color: '#333', fontSize: 12, marginTop: 10 }}>{translate('Työlaskuri')}</div>
        </div>

        <div style={{ textAlign: 'center' }}>
          <Fab
            color="primary"
            size="medium"
            onClick={addCovRowsClick}
          >
            <AddIcon />
          </Fab>
          <br />
          <div style={{ color: '#333', fontSize: 12, marginTop: 10 }}>{translate('Tuoterivi')}</div>
        </div>

      </div>
    </SwipeableViews>
  );
}
