import { useState } from 'react';

const initialState = () => ({
  show: false,
  onConfirm: () => undefined,
  onCancel: () => undefined,
  onClose: () => undefined,
  confirmLabel: '',
});

const useBottomDrawer = () => {
  const [bottomDrawer, setBottomDrawer] = useState(initialState());

  function showBottomDrawer(props) {
    setBottomDrawer({
      ...bottomDrawer,
      ...props,
      show: true,
    });
  }

  function hideBottomDrawer() {
    bottomDrawer.onClose();
    setBottomDrawer(initialState());
  }

  return {
    bottomDrawer,
    showBottomDrawer,
    hideBottomDrawer,
  };
};

export default useBottomDrawer;
