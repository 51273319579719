import React, { useContext } from 'react';

import { makeStyles, NativeSelect } from '@material-ui/core';

import { I18nContext } from './index';

const useStyles = makeStyles(() => ({
  select: {
    width: 45,
    height: 25,
    fontSize: 15,
  },
}));

// eslint-disable-next-line no-unused-vars
const LanguageSelect = (props) => {
  const classes = useStyles();
  const { langCode, dispatch } = useContext(I18nContext);

  const onLanguageSelect = (e) => dispatch({ type: 'setLanguage', payload: e.target.value });

  const renderOption = (code) => (
    <option value={code} selected={code === langCode}>
      {code}
    </option>
  );

  return (
    <NativeSelect onChange={onLanguageSelect}>
      {['FI', 'SV'].map(renderOption)}
    </NativeSelect>
  );
};

export default LanguageSelect;
