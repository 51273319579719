import React, { useState, useContext } from 'react';
import {
  Box, Button, makeStyles, Card, Switch, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, List, ListSubheader, Paper, CircularProgress,
} from '@material-ui/core';
import { Keyboard as KeyboardIcon } from '@material-ui/icons';
import TranslateIcon from '@material-ui/icons/Translate';
import Main from './Main';
import { Store } from '../App';
import LocationTracker from './LocationTracker';
import Aths from './Aths';
import LanguageSelect from '../Translations/LanguageSelect';
import { I18nContext } from '../Translations';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Settings = ({ history }) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(Store);
  const logout = () => dispatch({ type: 'LOGOUT' });
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [reloading, setReloading] = useState(false);

  const handleLogout = () => {
    if (!showLogoutConfirm) {
      setShowLogoutConfirm(true);
      return;
    }

    history.replace('/');
    logout();
  };

  const { translate } = useContext(I18nContext);

  const [numberType, setNumberType] = React.useState('number');
  const handleKeyboardChange = ({ target: { checked } }) => {
    const type = checked ? 'text' : 'number';
    localStorage.setItem('numberType', type);
    setNumberType(type);
  };
  React.useEffect(() => {
    const type = localStorage.getItem('numberType') || 'number';
    setNumberType(type);
  }, []);

  return (
    <Main
      title={translate('Asetukset')}
    >
      <Box
        p={2}
      >
        <Paper>
          <List
            subheader={<ListSubheader>{translate('Asetukset')}</ListSubheader>}
          >
            <LocationTracker />

            <ListItem>
              <ListItemIcon>
                <KeyboardIcon />
              </ListItemIcon>
              <ListItemText
                primary={translate('Näppäimistö')}
                secondary={translate('Käytä numerokentissä tekstinäppäimistöä')}
                secondaryTypographyProps={{
                  style: {
                    marginRight: 30,
                    fontSize: 12,
                  },
                }}
              />
              <ListItemSecondaryAction>
                <Switch
                  checked={numberType === 'text'}
                  edge="end"
                  color="primary"
                  onChange={handleKeyboardChange}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>
              <ListItemText
                primary={translate('Kieli')}
                secondary={translate('Valitse haluamasi kieli')}
                secondaryTypographyProps={{
                  style: {
                    marginRight: 30,
                    fontSize: 12,
                  },
                }}
              />
              <ListItemSecondaryAction>
                <LanguageSelect />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>

        <Aths />

        <Card className={classes.card}>
          <Box>
            <Button
              component="a"
              fullWidth
              // eslint-disable-next-line max-len
              href={`mailto:asiakaspalvelu@ecom.fi?subject=Ecom Webi (mobiili) ongelma&body=%0D%0A%0D%0AAsiakasnumero:${state.user.syscustomer_id}%0D%0AKäyttäjä:${state.user.sysuser}%0D%0AUser agent:${navigator.userAgent}%0D%0A%0D%0A`}
              color="primary"
              style={{
                marginTop: 6,
                marginBottom: 6,
              }}
            >
              {translate('Raportoi ongelmasta')}
            </Button>
            {!reloading ? (
              <Button
                color="primary"
                fullWidth
                onClick={() => {
                  setReloading(true);
                  document.location.reload(true);
                }}
                style={{
                  marginTop: 6,
                  marginBottom: 6,
                }}
              >
                {translate('Uudelleenkäynnistä')}
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Card>

        <Card className={classes.card}>
          <Button
            onClick={handleLogout}
            color="primary"
            style={{
              marginTop: 6,
              marginBottom: 6,
            }}
          >
            {showLogoutConfirm ? translate('Oletko varma') : translate('Kirjaudu ulos')}
          </Button>
        </Card>

        <small>
          {state.user.syscustomer_id}, {state.user.sysuser}, {state.user.sysuser_name}
        </small>

      </Box>
    </Main>
  );
};

export default Settings;
