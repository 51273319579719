import React, { useState } from 'react';

const initialState = () => ({
  show: false,
  component: <></>,
  data: null,
  onHide: null,
});

const useDialog = () => {
  const [dialog, setDialog] = useState(initialState());

  function showDialog(props, onHide) {
    setDialog({
      ...props,
      show: true,
      onHide: onHide,
    });
  }

  function hideDialog() {
    if (dialog && dialog.onHide) {
      dialog.onHide();
    }
    setDialog(initialState());
  }

  return {
    dialog,
    showDialog,
    hideDialog,
  };
};

export default useDialog;
