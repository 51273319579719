import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper, IconButton, Divider, Box, Typography,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { useTimesheetFiltersContext } from '../../reducers/timesheetFilters';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const df = (m, f) => moment(m).format(f || 'YYYY-MM-DD');

const dateForDisplay = (m, f) => {
  if (!(moment(m).isValid())) {
    return '(päivämäärä puuttuu)';
  }
  return moment(m).format(f || 'YYYY-MM-DD');
};

export default function TimesheetRangeControl() {
  const [state, dispatch] = useTimesheetFiltersContext();
  const { values, options } = state;
  const { range } = options;
  const { startdate, enddate } = values;
  const classes = useStyles();

  const setDates = (startDate) => dispatch({
    type: 'SET_TIMESHEET_VALUES',
    payload: {
      startdate: df(startDate),
      enddate: df(startDate.add(...range.split(',')).subtract(1, 'days')),
    },
  });

  const goPrev = () => {
    const d = moment(startdate).subtract(...range.split(','));
    setDates(d);
  };
  const goNext = () => {
    const d = moment(startdate).add(...range.split(','));
    setDates(d);
  };

  return (
    <Box px={1} pt={1} pb={1}>
      <Paper>
        <div className={classes.filterContainer}>
          <IconButton color="primary" onClick={goPrev}>
            <ArrowBackIcon />
          </IconButton>
          <Divider
            className={classes.divider}
            orientation="vertical"
          />
          <Typography
            variant="caption"
            color="textPrimary"
            style={{ width: '100%', textAlign: 'center' }}
          >
            <span>{dateForDisplay(startdate, 'dd D.M.YYYY')}</span>
            {startdate !== enddate && (
              <span> &mdash; {dateForDisplay(enddate, 'dd D.M.YYYY')}</span>
            )}
          </Typography>
          <Divider
            className={classes.divider}
            orientation="vertical"
          />
          <IconButton color="primary" onClick={goNext}>
            <ArrowForwardIcon />
          </IconButton>
        </div>

      </Paper>
    </Box>
  );
}
