import React, { useState } from 'react';
import {
  Select, MenuItem, FormControl, InputLabel, Divider, Button, ListSubheader,
} from '@material-ui/core';
import { Store } from '../App';
import Container from './Container';
import { I18nContext } from '../Translations';

const SalaryTypesList = ({ handleChange, salaryTypeId }) => {
  const { state } = React.useContext(Store);
  const { salaryTypes } = state;

  const [showSalaryTypes, setShowSalaryTypes] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedSalaryTypeId, setSelectedSalaryTypeId] = useState('');
  const { translate } = React.useContext(I18nContext);

  const onChange = (ev) => {
    const { value } = ev.target;

    if (value === undefined) return;

    setOpenMenu(false);
    setSelectedSalaryTypeId(value);

    handleChange(ev);
  };

  React.useEffect(() => {
    if (salaryTypes && salaryTypeId) {
      if (salaryTypes.normal.includes(salaryTypeId)) {
        setShowSalaryTypes(true);
      }
      setTimeout(() => {
        setSelectedSalaryTypeId(salaryTypeId);
      }, 0);
    }
  }, [salaryTypes]);

  return (
    <Container
      spinnerProps={{
        size: 24,
        label: translate('Haetaan palkkalajeja'),
        containerProps: {
          style: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
          },
        },
        labelProps: {
          style: {
            margin: '24px 0 24px 16px',
          },
        },
      }}
    >
      <FormControl
        variant="outlined"
        fullWidth
        style={{
          marginTop: 16,
          marginBottom: 8,
        }}
      >
        <InputLabel
          htmlFor="salary-types"
        >
          {translate('Palkkalaji')}
        </InputLabel>
        <Select
          value={selectedSalaryTypeId}
          open={openMenu}
          onOpen={() => setOpenMenu(true)}
          labelWidth={72}
          onChange={onChange}
          inputProps={{
            id: 'salary-types',
          }}
        >
          {salaryTypes.default.map(({ id, name }) => (
            <MenuItem
              key={id}
              value={id}
            >
              {id}: {name}
            </MenuItem>
          ))}

          <Divider />

          {salaryTypes.normal.length && !showSalaryTypes && (
            <MenuItem>
              <Button
                fullWidth
                onClick={() => setShowSalaryTypes(true)}
              >
                {translate('Näytä kaikki palkkalajit')}
              </Button>
            </MenuItem>
          )}

          {salaryTypes.normal.length && showSalaryTypes && (
            <ListSubheader
              disableSticky
            >
              {translate('Kaikki palkkalajit')}
            </ListSubheader>
          )}

          {showSalaryTypes && (
            salaryTypes.normal.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
              >
                {id}: {name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Container>
  );
};

export default SalaryTypesList;
