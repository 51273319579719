import React from 'react';
import { Chip } from '@material-ui/core';

export const getStatusColor = (bystate) => {
  const [status] = bystate.length !== 1 ? ['a'] : bystate;
  const colors = {
    k: '#f3e1e7', // pun, kesken
    h: '#dfeae5', // vihr, hyväksytty
    v: '#fcf7db', // kelt, valmis
    s: '#f4f4f4', // har, siirretty,
    a: '#cfe9f2', // blue, kaikki
  };
  return colors[status];
};

export const getTimesheetEntryStatusDescription = (id, isReady, isApproved) => {
  if (!id) {
    return ('(uusi)');
  }
  if (!isReady) {
    return <Chip size="small" style={{ color: '#333', background: getStatusColor(['k']) }} label="Kesken" />;
  }
  if ((isReady === 1) && (!isApproved)) {
    return <Chip size="small" style={{ color: '#333', background: getStatusColor(['v']) }} label="Valmis" />;
  }
  if ((isReady === 1) && (isApproved)) {
    return <Chip size="small" style={{ color: '#333', background: getStatusColor(['h']) }} label="Hyväksytty" />;
  }
  if (isReady === 2) {
    return <Chip size="small" style={{ color: '#333', background: getStatusColor(['s']) }} label="Siirretty" />;
  }
  return '(tuntematon)';
};
