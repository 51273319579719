import React from 'react';
import clsx from 'clsx';
import {
  Fab,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import SalaryGridAddEventSalaryType from './SalaryGridAddEventSalaryType';
import SalaryGridAddEventCovNote from './SalaryGridAddEventCovNote';
import NumTextField from './NumTextField';
import SalaryGridAddEventProject from './SalaryGridAddEventProject';
import { Store } from '../App';
import { I18nContext } from '../Translations';

const Cell = ({
  onChange, classes, salaryTypeId, entry,
}) => {
  const updateAmount = (ev) => {
    const { target: { value } } = ev;
    if (!/^-?[\d]+[,.]?\d{0,2}$/.test(value)) {
      ev.preventDefault();
    }
    onChange(value.replace(',', '.'));
  };
  return (
    <td
      key={salaryTypeId}
    >
      <div
        className={classes.cell}
      >
        <NumTextField
          margin="dense"
          variant="outlined"
          style={{ width: 70 }}
          onFocus={(ev) => ev.currentTarget.select()}
          onBlur={updateAmount}
          defaultValue={entry.salaryEvents[salaryTypeId] ? entry.salaryEvents[salaryTypeId] : '-'}
          inputProps={{
            step: 'any',
          }}
        />
      </div>
    </td>
  );
};
export default
({
  classes,
  covNoteEntries,
  salaryTypes,
  salaryTypesNormal,
  wantedDate,
  setCovNoteEntries,
  setRowIdsToBeSaved,
  rowIdsToBeSaved,
  setAdditionalSalaryTypes,
  projectEntries = [],
  setProjectEntries,

}) => {
  const { state } = React.useContext(Store);
  const { translate } = React.useContext(I18nContext);
  const [open, setOpen] = React.useState({
    covNote: false,
    salaryType: false,
    project: false,
  });

  const entriesSum = ([...covNoteEntries, ...projectEntries].length && salaryTypes.map((item) => {
    let sum = 0;
    covNoteEntries.forEach((element) => {
      if (element.salaryEvents[item.salaryTypeId]) {
        sum += parseFloat(element.salaryEvents[item.salaryTypeId]);
      }
    });
    projectEntries.forEach((element) => {
      if (element.salaryEvents[item.salaryTypeId]) {
        sum += parseFloat(element.salaryEvents[item.salaryTypeId]);
      }
    });
    return sum.toFixed(2);
  })) || [];

  const handleCovNoteUpdate = (_ev, {
    covNoteEntry, salaryTypeId, newAmount,
  }) => {
    const newCovNoteEntries = covNoteEntries.map((entry) => {
      const newEntry = { ...entry };
      if (entry.key === covNoteEntry.key) {
        newEntry.salaryEvents[salaryTypeId] = newAmount;
        setRowIdsToBeSaved([...new Set([...rowIdsToBeSaved, entry.key])]);
      }
      return newEntry;
    });

    setCovNoteEntries(newCovNoteEntries);
  };

  const handleProjectUpdate = (_ev, {
    projectEntry, salaryTypeId, newAmount,
  }) => {
    const newProjectEntries = projectEntries.map((entry) => {
      const newEntry = { ...entry };
      if (entry.key === projectEntry.key) {
        newEntry.salaryEvents[salaryTypeId] = newAmount;
        setRowIdsToBeSaved([...new Set([...rowIdsToBeSaved, entry.key])]);
      }
      return newEntry;
    });

    setProjectEntries(newProjectEntries);
  };
  const entries = [...covNoteEntries, ...projectEntries];
  return (
    <div>
      <SalaryGridAddEventSalaryType
        open={open.salaryType}
        {...{ salaryTypes, salaryTypesNormal, setAdditionalSalaryTypes }}
        handleCloseDialog={() => setOpen({ ...open, salaryType: false })}
      />
      {open.covNote && (
        <SalaryGridAddEventCovNote
          {...{
            covNoteEntries, setCovNoteEntries, salaryTypes, salaryTypesNormal, setAdditionalSalaryTypes,
          }}
          handleCloseDialog={() => setOpen({ ...open, covNote: false })}
        />
      )}
      {open.project && (
        <SalaryGridAddEventProject
          {...{
            projectEntries, setProjectEntries, salaryTypes, salaryTypesNormal, setAdditionalSalaryTypes,
          }}
          handleCloseDialog={() => setOpen({ ...open, project: false })}
        />
      )}
      <table className={classes.stickyTable}>
        <thead>
          <tr>
            <th
              className={clsx(classes.tHeadTh, classes.theadThFirst)}
            >
              <h1>{moment(wantedDate).format('D.M')}</h1>
              {/* <TextField
                margin="dense"
                type="date"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    input: classes.dateInput,
                  },
                }}
                value={salaryGridEditParams['filter[wanteddate][min]']}
                onChange={filterByDate}
              /> */}
            </th>

            {
              salaryTypes.map((item) => (
                <th
                  key={item.salaryTypeId}
                  className={classes.tHeadTh}
                >
                  <div
                    className={classes.tHeadDiv}
                  >
                    {`${item.salaryTypeId} : ${item.salaryTypeName}`}
                  </div>
                </th>
              ))
            }

            <th
              className={classes.tHeadLastTh}
            >
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                onClick={() => setOpen({ ...open, salaryType: true })}
              >
                <AddIcon />
              </Fab>
              {/*
              <Button
                variant="outlined"
                size="small"
                onClick={() => setOpen({ ...open, salaryType: true })}
              >
                +
              </Button>
            */}
            </th>
          </tr>
        </thead>

        <tbody>
          {!entries.length && (<tr><td colSpan={10}>{translate('Aloita lisäämällä lähete tai projekti vasemman alakulman L tai P napista')}</td></tr>)}
          <>
            {covNoteEntries.map((covNoteEntry, index) => (
              <tr
                key={covNoteEntry.key}
                className={index % 2 === 1 ? classes.even : classes.odd}
              >
                <th
                  className={clsx(classes.tBodyTh, index % 2 === 1 ? classes.even : classes.odd)}
                >
                  <div
                    className={classes.tBodyDiv}
                  >
                    {translate('Lähete lyhenne')} {`${covNoteEntry.ecomId || '-'} 
                    ${covNoteEntry.location || covNoteEntry.mark || (covNoteEntry.Customer && covNoteEntry.Customer.name) || ''}`}
                  </div>
                </th>
                {salaryTypes.map(({ salaryTypeId, salaryTypeName }) => (
                  <Cell
                    onChange={(newAmount) => handleCovNoteUpdate(null, {
                      covNoteEntry, salaryTypeId, salaryTypeName, newAmount,
                    })}
                    classes={classes}
                    entry={covNoteEntry}
                    salaryTypeId={salaryTypeId}
                  />
                ))}
              </tr>
            ))}
            {projectEntries.map((projectEntry, index) => (
              <tr
                key={projectEntry.key}
                className={index % 2 === 1 ? classes.even : classes.odd}
              >
                <th
                  className={clsx(classes.tBodyTh, index % 2 === 1 ? classes.even : classes.odd)}
                >
                  <div
                    className={classes.tBodyDiv}
                  >
                    {!projectEntry.subPrjId ? (
                      <>
                        {translate('Projekti lyhenne')}
                      </>
                    ) : (
                      <>
                        {translate('Alaprojekti lyhenne')}
                      </>
                    )}
                    {projectEntry.name}
                  </div>
                </th>
                {salaryTypes.map(({ salaryTypeId, salaryTypeName }) => (
                  <Cell
                    onChange={(newAmount) => handleProjectUpdate(null, {
                      projectEntry, salaryTypeId, salaryTypeName, newAmount,
                    })}
                    classes={classes}
                    entry={projectEntry}
                    salaryTypeId={salaryTypeId}
                  />
                ))}
              </tr>
            ))}
          </>
          <tr>
            <th
              className={classes.tBodyLastTrTh}
            >
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                onClick={() => setOpen({ ...open, covNote: true })}
              >
                L
              </Fab>
              {state.user.permissions.cr_se_projectinput
                && (
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    onClick={() => setOpen({ ...open, project: true })}
                  >
                P
                  </Fab>
                )}
            </th>
            {entriesSum.map((sum, index) => (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classes.tBodyLastTrTd}
              >
                <div
                  className={classes.tBodyLastTrTdDiv}
                >
                  {sum || '-'}
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
