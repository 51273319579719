import React, { useContext, useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import {
  Grid, ListItem, ListItemText, Typography, Fab, makeStyles, Box, Button, CircularProgress,
} from '@material-ui/core';
import {
  Add as AddIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import filesize from 'filesize';
import SwipeableViews from 'react-swipeable-views';
import { useSnackbar } from 'material-ui-snackbar-provider';
import {
  deletee, get, post, uploadFile,
} from '../../../helpers/service';
import DialogContext from '../../../context/DialogContext';
import ImagePreview from '../../ImagePreview';
import Container from '../../Container';
import { I18nContext } from '../../../Translations';

const useStyles = makeStyles((theme) => ({
  attachFile: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  destroyContainer: {
    background: theme.palette.error.main,
    height: 64,
  },
  destroyButton: {
    color: theme.palette.common.white,
  },
}));

export default function CovnoteFiles({ covnote }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [destroying, setDestroying] = useState({});
  const classes = useStyles();
  const snackbar = useSnackbar();
  const { translate } = useContext(I18nContext);

  const fetchFiles = async () => {
    setLoading(true);

    const res = await get('api/tiedostot', {
      linkType: 'COV',
      linkId: covnote.ecomId,
    });

    setFiles(res);

    setLoading(false);
  };

  useEffect(() => {
    fetchFiles();
  }, [covnote]);

  const getSignedUrl = async (file, callback, opts = {}) => {
    const payload = {
      ...file,
      name: `Lahete-${covnote.ecomId}/${file.name}`,
      contentType: file.type,
      overwrite: false,
      ...opts,
    };

    try {
      const res = await post('api/tiedostot', payload);

      setFiles((prevFiles) => [...prevFiles, res]);

      await callback({
        signedUrl: res.uploadDetails.uploadUrl,
      });

      snackbar.showMessage(translate('Tiedoston lähettäminen onnistui'));
    } catch (e) {
      if (e.status === 409) {
        const confirmed = confirm(e.data.errors[0].message); // eslint-disable-line

        if (!confirmed) return;

        await getSignedUrl(file, callback, { ...opts, overwrite: true });
      } else {
        snackbar.showMessage(translate('Jotakin meni pieleen'));
      }
    }
  };

  const uploadImage = async (file) => {
    const callback = ({ signedUrl }) => uploadFile(signedUrl, file.blob);

    const links = [
      { linkType: 'COV', linkId: covnote.ecomId },
    ];

    if (covnote.Customer) {
      links.push({ linkType: 'CUS', linkId: covnote.Customer.id });
    }

    if (covnote.Project) {
      links.push({ linkType: 'PRJ', linkId: covnote.Project.id });
    }

    const data = {
      ...file,
      links,
    };

    await getSignedUrl(data, callback);
  };

  /**
   * By default, Safari blocks pop-ups which are made after async function calls.
   * Fix is to call window.open() before the API call, and then set the wanted URL.
   *
   * Ref: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari#answer-39387533
   */
  const openFile = async (file) => {
    if (window.cordova && window.cordova.InAppBrowser && window.cordova.InAppBrowser.open) { // Cordova
      const res = await get(`api/tiedostot/${file.id}`);
      const location = res.url;

      window.cordova.InAppBrowser.open(location, '_system');
    } else { // Regular browser
      const windowReference = window.open();
      const res = await get(`api/tiedostot/${file.id}`);

      windowReference.location = res.url;
    }
  };

  const destroyFile = async (file) => {
    setDestroying(file);

    try {
      await deletee(`api/tiedostot/${file.id}`);

      const i = files.findIndex((f) => f.id === file.id);

      if (i < 0) return; // Not found

      const newFiles = [...files];
      newFiles.splice(i, 1);

      setFiles(newFiles);

      snackbar.showMessage(translate('Tiedosto poistettu'));
    } catch (e) {
      snackbar.showMessage(translate('Tiedoston poisto epäonnistui'));
    }

    setDestroying({});
  };

  const {
    showDialog,
  } = useContext(DialogContext);

  const openImagePreviewDialog = (imageUrl = null) => {
    showDialog({
      component: (
        <ImagePreview
          uploadImage={uploadImage}
          imageUrl={imageUrl}
        />
      ),
    });
  };

  const onFileInputChange = (e) => {
    const { files: uploadedFiles } = e.target;

    if (uploadedFiles.length <= 0) return;
    if (uploadedFiles.length > 1) alert(translate('Valitse yksi tiedosto kerrallaan')); // eslint-disable-line

    // Currently only one file at a time
    const file = uploadedFiles[0];
    const imageUrl = URL.createObjectURL(file);

    openImagePreviewDialog(imageUrl);
  };

  return (
    <Container
      spinnerProps={{
        loading,
        label: translate('Haetaan tiedostoja'),
        style: {
          marginTop: 48,
        },
      }}
    >
      <Box
        px={2}
        pt={2}
        pb={1}
      >
        <Typography
          variant="caption"
          color="textSecondary"
        >
          {translate('Poista pyyhkäisemällä riviä vasemmalle ja vahvista')}
        </Typography>
      </Box>

      <Box
        style={{
          height: 'calc(100% - 45px)',
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemSize={64}
              itemCount={files.length}
            >
              {
                ({ index, style }) => (
                  <div
                    key={index}
                    style={{
                      ...style,
                      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <SwipeableViews>
                      <ListItem
                        button
                        px={2}
                        style={{ height: '100%' }}
                        onClick={() => openFile(files[index])}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={9}>
                            <ListItemText
                              primary={files[index].name.split('/')[files[index].name.split('/').length - 1]}
                              primaryTypographyProps={{
                                variant: 'body2',
                              }}
                              secondary={files[index].description}
                              secondaryTypographyProps={{
                                variant: 'caption',
                                noWrap: true,
                                display: 'block',
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            { destroying.id === files[index].id && (
                              <CircularProgress size={24} />
                            )}

                            { destroying.id !== files[index].id && (
                              <Typography
                                align="right"
                                variant="body2"
                                color="textSecondary"
                              >
                                { filesize(files[index].size) }
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </ListItem>

                      <Box
                        px={2}
                        className={classes.destroyContainer}
                      >
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          style={{
                            height: '100%',
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography align="right">
                              <Button
                                variant="outlined"
                                color="inherit"
                                className={classes.destroyButton}
                                onClick={() => destroyFile(files[index])}
                              >
                                {translate('Poista')}
                                <DeleteOutlineIcon
                                  color="inherit"
                                  fontSize="small"
                                  style={{
                                    marginLeft: 6,
                                  }}
                                />
                              </Button>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </SwipeableViews>
                  </div>
                )
              }
            </FixedSizeList>
          )}
        </AutoSizer>

        <input
          style={{
            display: 'none',
          }}
          id="file-to-upload"
          type="file"
          onChange={onFileInputChange}
        />

        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="file-to-upload">
          <Fab
            color="primary"
            component="span"
            className={classes.attachFile}
          >
            <AddIcon />
          </Fab>
        </label>
      </Box>
    </Container>
  );
}
