import React from 'react';
import moment from 'moment';
import { usePersistedContext, usePersistedReducer } from '../usePersist';

const salaryGridFiltersReducer = (state = {}, { type, payload }) => {
  let newState;
  if (type === 'SET_SALARYGRID_PARAMS') {
    newState = {
      ...state,
      salaryGridAddParams: {
        ...state.salaryGridAddParams,
        [payload.key]: payload.value,
      },
    };
  }
  if (type === 'SET_SALARYGRID_EDIT_PARAMS') {
    newState = {
      ...state,
      salaryGridEditParams: {
        ...state.salaryGridEditParams,
        ...payload,
      },
    };
  }
  if (type === 'SET_SALARYGRID_OPTIONS') {
    newState = {
      ...state,
      options: {
        ...state.options,
        ...payload,
      },
    };
  }
  if (type === 'INIT_SALARYGRID') {
    newState = {
      ...state,
      ...payload,
    };
  }
  return newState;
};

export const initialState = {
  salaryGridAddParams: {
    'filter[bystate]': ['k', 'v', 'h', 's'],
    'filter[ownonly]': true,
    'filter[startdate]': moment().format('YYYY-MM-DD'),
  },
  salaryGridEditParams: {
    'filter[wanteddate][max]': moment().add(1, 'days').format('YYYY-MM-DD'),
    'filter[wanteddate][min]': moment().format('YYYY-MM-DD'),
    'filter[ownonly]': true,
  },
  wantedDate: moment().format('YYYY-MM-DD'),
};

const SalaryGridFiltersContext = React.createContext(initialState);
export const useSalaryGridFiltersContext = () => React.useContext(SalaryGridFiltersContext);

export const SalaryGridFiltersContextProvider = ({ children }) => {
  const store = usePersistedContext(React.useContext(SalaryGridFiltersContext), 'ssf');
  const [state, dispatch] = usePersistedReducer(React.useReducer(salaryGridFiltersReducer, store), 'ssf');
  return (
    <SalaryGridFiltersContext.Provider value={[state, dispatch]}>
      {children}
    </SalaryGridFiltersContext.Provider>
  );
};
