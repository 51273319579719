/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { I18nContext } from '../Translations';


export default ({
  classes,
  salaryEntries,
  setSalaryEntries,
  salaryTypes,
  salaryGridAddParams,
  rowIdsToBeSaved,
  setRowIdsToBeSaved,
  dispatchSalaryGridFilters,
}) => {
  const { translate } = React.useContext(I18nContext);
  const sortedSalaryEntries = salaryEntries && salaryEntries.reduce((acc, row) => {
    const entryIndex = acc.find((entry, index) => {
      if (entry.covEcomId === row.id) { return index; }
      return null;
    });

    if (entryIndex) {
      acc[entryIndex] = { ...acc[entryIndex], salaryEvents: { ...acc[entryIndex].salaryEvents, [row.SysUserSalaryType.id]: row.hours } };
      return acc;
    }
    return [...acc, {
      id: row.id, covEcomId: row.covEcomId, name: `${row.covEcomId}:${row.CoveringNote.location}`, salaryEvents: { [row.SysUserSalaryType.id]: row.hours },
    }];
  }, []);

  const sortedSalaryEntriesSum = (sortedSalaryEntries.length && salaryTypes.map((item) => {
    let sum = 0;
    sortedSalaryEntries.forEach((element) => {
      if (element.salaryEvents[item.salaryTypeId]) {
        sum += parseFloat(element.salaryEvents[item.salaryTypeId]);
      }
    });
    return sum.toFixed(2);
  })) || [];

  const handleSalaryFieldUpdate = (ev, { id, amount }) => {
    const newAmount = prompt('Määrä', amount);
    if (!isNaN(parseFloat(newAmount))) {
      const newSalaryEntries = salaryEntries.map((entry) => {
        if (entry.id === id) {
          entry.hours = newAmount;
          setRowIdsToBeSaved([...new Set([...rowIdsToBeSaved, entry.id])]);
        }
        return entry;
      });

      setSalaryEntries(newSalaryEntries);
    }
  };

  const filterByDate = ({ target }) => {
    const key = 'filter[startdate]';
    dispatchSalaryGridFilters({
      type: 'SET_SALARYGRID_PARAMS',
      payload: {
        key,
        value: target.value,
      },
    });
  };

  return (
    <div>
      <table className={classes.stickyTable}>
        <thead>
          <tr>
            <th
              className={clsx(classes.tHeadTh, classes.theadThFirst)}
            >

              <TextField
                margin="dense"
                type="date"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  classes: {
                    input: classes.dateInput,
                  },
                }}
                value={salaryGridAddParams['filter[startdate]'] && salaryGridAddParams['filter[startdate]']}
                onChange={filterByDate}
              />
            </th>

            {
              salaryTypes.map((item) => (
                <th
                  key={item.salaryTypeId}
                  className={classes.tHeadTh}
                >
                  <div
                    className={classes.tHeadDiv}
                  >
                    {`${item.salaryTypeId} : ${item.salaryTypeName}`}
                  </div>
                </th>
              ))
            }

            <th
              className={classes.tHeadLastTh}
            >
              <Button
                variant="outlined"
                size="small"
              >
                +
              </Button>
            </th>
          </tr>
        </thead>

        <tbody>
          {
            sortedSalaryEntries.map((sortedEntry, index) => (
              <tr
                key={sortedEntry.id}
                className={index % 2 === 0 ? classes.even : classes.odd}
              >
                <th
                  className={clsx(classes.tBodyTh, index % 2 === 0 ? classes.even : classes.odd)}
                >
                  <div
                    className={classes.tBodyDiv}
                  >
                    {sortedEntry.name}
                  </div>
                </th>
                {salaryTypes.map(({ salaryTypeId }) => (
                  <td
                    key={salaryTypeId}
                  >
                    <div
                      className={classes.cell}
                    >
                      {sortedEntry.salaryEvents[salaryTypeId] ? (
                        <Button
                          size="small"
                          onClick={(ev) => handleSalaryFieldUpdate(ev, { id: sortedEntry.id, amount: sortedEntry.salaryEvents[salaryTypeId] || '' })}
                        >
                          {sortedEntry.salaryEvents[salaryTypeId]}
                        </Button>
                      ) : '-'}
                    </div>
                  </td>
                ))}

              </tr>
            ))
          }

          <tr className={sortedSalaryEntries.length % 2 === 0 ? classes.even : classes.odd}>
            <th
              className={classes.tBodyLastTrTh}
              style={{
                bottom: '30px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
              >
                +
              </Button>
            </th>
            {salaryTypes.map((item, index) => <td key={index} />)}
          </tr>

          <tr>
            <th
              className={classes.tBodyLastTrTh}
            >
              {translate('Yhteensä lyhenne')}
            </th>

            {sortedSalaryEntriesSum.map((sum, index) => (
              <td
                key={index}
                className={classes.tBodyLastTrTd}
              >
                <div
                  className={classes.tBodyLastTrTdDiv}
                >
                  {sum || '-'}
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
