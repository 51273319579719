/* eslint-disable no-restricted-globals */
import React from 'react';
// pick a date util library
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {
  Badge,
  withStyles,
  Switch,
  FormControlLabel,
  CircularProgress,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Main from '../Main';
import { get } from '../../helpers/service';
import CalendarItem from './CalendarItem';
import CovnoteItem from './CovnoteItem';
import { Store } from '../../App';
import { I18nContext } from '../../Translations';

const defaultBadgeStyles = {
  top: 'unset',
  right: 'unset',
  left: '50%',
  bottom: '14%',
  transform: 'scale(1) translate(-50%, 50%)',
  transformOrigin: '0% 100%',
};

const MiddleBadge = withStyles({
  badge: defaultBadgeStyles,
})(Badge);

const useStyles = makeStyles(() => ({
  eventList: {
    padding: 0,
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'auto',
    fontSize: 16,
    lineHeight: 'normal',
  },
}));

const Calendar = ({ history, covnoteStates }) => {
  const classes = useStyles();
  const { state: { user } } = React.useContext(Store);

  const [events, setEvents] = React.useState([]);
  const [month, setMonth] = React.useState(moment().format('YYYY-MM'));
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [resCal, setResCal] = React.useState(false);
  const [ownOnly, setOwnOnly] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const scrollTo = (id) => {
    const elmnt = document.getElementById(id);
    if (elmnt) {
      elmnt.scrollIntoView();
      window.scrollBy(0, -65);
    }
  };

  const { translate } = React.useContext(I18nContext);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const id = date.format('YYYY-MM-DD');
    scrollTo(id);
  };

  const fetchResources = async (minDf, maxDf) => {
    setLoading(true);
    const result = await get(`api/kalenteri/lahetteet?filter%5Bdate%5D%5Bmin%5D=${minDf}&filter%5Bdate%5D%5Bmax%5D=${maxDf}`);
    const items = result
      .sort((a, b) => (a.startDateTime > b.startDateTime ? 1 : -1))
      .filter((item) => !ownOnly || item.personId === user.sysuser_person_id)
      .map((item) => {
        const startDateTime = moment(item.startDateTime);
        const endDateTime = moment(item.endDateTime);
        const colors = ['green', 'orange', 'red']; // valmis, orange = käynnissä, green = avoin
        const { statusId } = item.Coveringnote;
        const colorIdx = covnoteStates.findIndex((id) => id === statusId);
        const color = colors[colorIdx];
        return {
          ...item.Coveringnote,
          resId: item.id,
          wantedDate: startDateTime.format('YYYY-MM-DD'),
          startTime: startDateTime.format('HH:mm'),
          endTime: endDateTime.format('HH:mm'),
          color,
          assigned: item.personId === user.sysuser_person_id,
        };
      });
    setEvents(items);
    setLoading(false);
  };

  const fetchCovnotes = async (minDf, maxDf) => {
    setLoading(true);
    const result = await get(`api/lahetteet?filter%5Bownonly%5D=${ownOnly ? 'true'
      : 'false'}&filter%5Bwanteddate%5D%5Bmin%5D=${minDf}&filter%5Bwanteddate%5D%5Bmax%5D=${maxDf}`);
    const items = result
      .map((item) => {
        const start = moment(item.wantedDate)
          .add(moment.duration(item.wantedTime));
        const end = moment(start).add(item.estimatedDuration || 0, 'hours');
        const colors = ['green', 'orange', 'red']; // valmis, orange = käynnissä, green = avoin
        const { statusId } = item;
        const colorIdx = covnoteStates.findIndex((id) => id === statusId);
        const color = colors[colorIdx];
        return {
          ...item,
          startDaTi: start,
          startTime: start ? start.format('HH:mm') : null,
          endTime: item.estimatedDuration ? end.format('HH:mm') : null,
          color,
          assigned: [item.ownerPersonId, item.supervisorPersonId, ...item.assignedPersonIds].includes(user.sysuser_person_id),
        };
      }).sort((a, b) => (a.startDaTi > b.startDaTi ? 1 : -1));
    setEvents(items);
    setLoading(false);
  };

  const handleMonthChange = (min) => setMonth(min.format('YYYY-MM'));

  React.useEffect(() => {
    const minDf = `${month}-01`;
    const maxDf = moment(minDf)
      .add(1, 'months')
      .format('YYYY-MM-DD');
    if (resCal) {
      fetchResources(minDf, maxDf);
    } else {
      fetchCovnotes(minDf, maxDf);
    }
  }, [month, resCal, ownOnly]);

  let prev;
  return (
    <Main
      title={resCal ? translate('Resurssikalenteri') : translate('Lähetekalenteri')}
      titleButton={(
        <>
          <IconButton
            edge="end"
            color="inherit"
            size="small"
            style={{ color: 'white' }}
            onClick={() => history.push('/covnotes')}
          >
            <Close />
          </IconButton>
        </>
      )}
    >
      <FormControlLabel
        control={(
          <Switch
            checked={ownOnly}
            onChange={({ target: { checked } }) => setOwnOnly(checked)}
          />
        )}
        label={translate('Vain omat')}
      />
      <FormControlLabel
        control={(
          <Switch
            checked={resCal}
            onChange={({ target: { checked } }) => setResCal(checked)}
          />
        )}
        label={translate('Resurssikalenteri')}
      />

      <DatePicker
        disableToolbar
        autoOk
        renderDay={(day, _selectedDate, isInCurrentMonth, dayComponent) => {
          // You can also use our internal <Day /> component
          const event = events.find((item) => item.wantedDate === day.format('YYYY-MM-DD'));
          const hasEventsProps = event ? {
            overlap: 'circle',
            badgeContent: ' ',
            variant: 'dot',
            color: 'primary',
          } : {};
          if (!event) {
            return (<>{dayComponent}</>);
          }
          return <MiddleBadge {...hasEventsProps}>{dayComponent}</MiddleBadge>;
        }}
        variant="static"
        value={selectedDate}
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
      />
      {loading ? <CircularProgress /> : (
        <>
          {!events.length
            ? (
              <Typography
                variant="body2"
                style={{
                  fontStyle: 'italic',
                  padding: 10,
                }}
              >
                {translate('Ei')} {resCal ? translate('resursiointeja') : translate('lähetteitä')} {translate('tässä kuussa')}
              </Typography>
            )
            : (
              <div
                className={classes.eventList}
              >

                {events.map((covnote, i) => {
                  const hasChanged = covnote.wantedDate !== prev;
                  prev = covnote.wantedDate;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={i}>
                      {hasChanged && (
                        <Typography component="h4" style={{ padding: 10 }}>
                          <span id={covnote.wantedDate}>
                            {moment(covnote.wantedDate).format('D.M.YYYY')}
                          </span>
                        </Typography>
                      )}
                      <CovnoteItem
                        covnote={covnote}
                      >
                        <CalendarItem
                          {...covnote}
                        />
                      </CovnoteItem>
                    </React.Fragment>
                  );
                })}
              </div>
            )}
        </>
      )}
    </Main>
  );
};

export default function (props) {
  const [covnoteStates, setCovnoteStates] = React.useState([]);
  React.useEffect(() => {
    const fetchCovnoteStates = async () => {
      const result = await get('api/lahetteet/tilat');
      const states = result
        .sort((a, b) => a.sortId - b.sortId)
        .map((item) => item.id);
      setCovnoteStates(states);
    };
    fetchCovnoteStates();
  }, []);

  return (
    covnoteStates.length ? (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Calendar {...props} covnoteStates={covnoteStates} />
      </MuiPickersUtilsProvider>
    ) : <CircularProgress />
  );
}
