import React, { useContext } from 'react';
import {
  Box,
  Card, CardContent, makeStyles, Paper, Typography,
} from '@material-ui/core';
import AthsContext from '../context/AthsContext';
import ActionButton from './Buttons/ActionButton';
import { I18nContext } from '../Translations';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    padding: '16px !important',
  },
  appIconContainer: {
    width: 64,
    height: 64,
    padding: 6,
    margin: '22px 8px 0 22px',
    flex: '0 0 auto',
  },
  cover: {
    width: '100%',
    height: '100%',
  },
}));

const Aths = () => {
  const classes = useStyles();
  const {
    installApp,
    installPrompt,
    appInstalled,
    installing,
  } = useContext(AthsContext);
  const { translate } = useContext(I18nContext);

  if (appInstalled) return null;
  if (!installPrompt) return null;

  return (
    <Card
      className={classes.card}
    >
      <Paper
        className={classes.appIconContainer}
        elevation={4}
      >
        <img
          alt="Ecom Webi App Logo"
          className={classes.cover}
          src="/android-chrome-512x512.png"
        />
      </Paper>

      <CardContent className={classes.content}>
        <Typography
          component="h6"
          variant="h6"
        >
          {translate('Asenna pikakuvake')}
        </Typography>

        <Typography
          variant="caption"
          color="textSecondary"
        >
          {translate('Pikakuvakkeen avulla saat Ecom Webin heti auki')}
        </Typography>

        <Box
          mt={2}
        >
          <ActionButton
            variant="outlined"
            fullWidth
            color="primary"
            onClick={installApp}
            loading={installing}
          >
            {translate('Asenna')}
          </ActionButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Aths;
