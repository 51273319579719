import {
  ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Switch,
} from '@material-ui/core';
import { MyLocation as MyLocationIcon } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { post } from '../helpers/service';
import { Store } from '../App';
import { I18nContext } from '../Translations';


const LocationTracker = () => {
  const { translate } = useContext(I18nContext);
  const { state, dispatch } = useContext(Store);
  const [positionWatcher, setPositionWatcher] = useState(null);
  const [denied, setDenied] = useState(false);
  const {
    tracking = false,
  } = state.location || {};

  const supportsTracking = () => !!navigator.geolocation;

  const clearWatcher = () => {
    navigator.geolocation.clearWatch(positionWatcher);
    dispatch({ type: 'STOP_LOCATION_TRACKING' });
  };

  const handleTracking = (e = null) => {
    if (!supportsTracking) return;

    let checked;

    if (e) {
      ({ checked } = e.target);
    } else {
      checked = tracking;
    }

    if (checked) {
      const options = {
        enableHighAccuracy: false,
        maximumAge: 0,
      };

      const success = ({ coords }) => {
        const {
          latitude,
          longitude,
        } = coords;

        const payload = {
          latitude,
          longitude,
        };

        post('api/paikkatieto', payload);
      };

      const error = ({ code }) => {
        clearWatcher();

        if (code === 1) { // User denied
          setDenied(true);
        }
      };

      const watcher = navigator.geolocation.watchPosition(success, error, options);
      setPositionWatcher(watcher);

      dispatch({ type: 'TRACK_LOCATION' });
    } else {
      clearWatcher();
    }
  };

  useEffect(() => {
    handleTracking();
  }, []);

  if (!supportsTracking()) return null;

  let component;

  if (denied) {
    component = (
      <>
        <ListItemText
          primary={translate('Paikannuksen käyttö estetty')}
          secondary={translate('Voit sallia paikannuksen käytön laitteesi tai selaimesi asetuksista')}
          secondaryTypographyProps={{
            style: {
              fontSize: 12,
            },
          }}
        />
      </>
    );
  } else {
    component = (
      <>
        <ListItemText
          primary={translate('Paikannus')}
          // eslint-disable-next-line max-len
          secondary={translate('Paikannus tallentaa tasaisin väliajoin nykyisen sijaintisi. Paikannus on päällä vain sovelluksen ollessa aktiivinen. Paikkatietoja ei välitetä kun sovellus siirretään taustalle.')}
          secondaryTypographyProps={{
            style: {
              marginRight: 30,
              fontSize: 12,
            },
          }}
        />
        <ListItemSecondaryAction>
          <Switch
            checked={tracking}
            edge="end"
            color="primary"
            onChange={handleTracking}
          />
        </ListItemSecondaryAction>
      </>
    );
  }

  return (
    <ListItem>
      <ListItemIcon>
        <MyLocationIcon />
      </ListItemIcon>

      { component }
    </ListItem>
  );
};

export default LocationTracker;
