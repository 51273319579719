import React from 'react';
import { Box, List } from '@material-ui/core';
import Container from './Container';
import ActionButton from './Buttons/ActionButton';
import { I18nContext } from '../Translations';

const ListContainer = ({
  children,
  loading,
  pagination,
  spinnerLabel,
  dense = false,
}) => {
  const {
    currentPage,
    setCurrentPage,
    hasNextPage,
  } = pagination;
  const { translate } = React.useContext(I18nContext);

  return (
    <Box>
      <List
        dense={dense}
      >
        {children}
      </List>

      <Box
        mt={1}
        px={1}
        style={{
          textAlign: 'center',
          paddingBottom: 200,
        }}
      >
        {loading
          ? (
            <Container
              spinnerProps={{
                loading,
                label: spinnerLabel,
                containerProps: {
                  style: {
                    marginTop: 24,
                  },
                },
              }}
            />
          ) : (
            <ActionButton
              disabled={!hasNextPage}
              variant="outlined"
              size="large"
              color="primary"
              fullWidth
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              {hasNextPage ? translate('Hae lisää rivejä') : translate('Kaikki rivit haettu')}
            </ActionButton>
          )}
      </Box>
    </Box>
  );
};

export default ListContainer;
